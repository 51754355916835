import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./LandingPage.scss";
import newlogo from "../../assets/Image/NEW_LOGO.png";
import Endpoint from "../../utils/Endpoint";
import CircularProgress from "@mui/material/CircularProgress";

const LandingPage = () => {
  const navigate = useNavigate();
  const { userInformation } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const handleRedirectHomeAndUpdate = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${Endpoint.updateLilypadsAccess}`, {
        userId: userInformation._id,
        LilypadsAI_Access: false,
        type: ["home"],
      });

      if (response.data.success) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate(`/${userInformation._id}/portfolio`);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSubscribeAndRedirect = async () => {
    setLoading(true);
    try {
      const response = await axios.post(Endpoint.subscribeUser, {
        userId: userInformation._id,
        type: ["AI"],
      });

      const { success, redirectUrl } = response.data;


      if (success) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate(redirectUrl);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="welcome-container">
      {loading ? (
        <div className="loader-container-LandingPage">
          <CircularProgress size={60} />
        </div>
      ) : (
        <div className="welcome-card-container">
          {/* Home Page Card */}
          <div className="welcome-card">
            <img src={newlogo} alt="Lilypads Logo" />
            <h1>Lilypads</h1>
            <h2>Welcome to Lilypads Investor Portal</h2>
            <p>
              Lilypads is an AI-powered platform that empowers owners, investors, asset managers, and operators to maximize revenue by leveraging existing data to increase transactional efficiency
              and optimize property performance.
            </p>
            <button className="welcome-button" onClick={handleRedirectHomeAndUpdate}>
              Go to Home Page
            </button>
          </div>

          {/* Lilypads AI Page Card */}
          <div className="welcome-card">
            <img src={newlogo} alt="Lilypads AI Logo" />
            <h1>Lilypads AI</h1>
            <h2>Welcome to Lilypads AI</h2>
            <p>
              Lilypads is an AI-powered platform that empowers owners, investors, asset managers, and operators to maximize revenue by leveraging existing data to increase transactional efficiency
              and optimize property performance.
            </p>
            <button className="welcome-button" onClick={handleSubscribeAndRedirect}>
              Go to Lilypads AI Page
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
