import React, {useEffect, useRef, useState} from "react";
import {IOSSwitch, InputBox} from "../../../components/reusableComponents/FormFields/FormFields";
import searchWhite from "../../../assets/svg/searchWhite.svg";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";
import SFICOn from "../../../assets/svg/SF.svg";
import {FormControlLabel, Input, Modal, Slider} from "@mui/material";
import {PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import AdvancedFilters from "../AdvancedFilters";
import {Link, useNavigate} from "react-router-dom";
import AiSearch, {AiSearch1, AiSearch2} from "../AiSearch";
import {SliderIcon} from "../Svgimages/svgallimnages";
import {handleSearch} from "../search/SeacrhFunction";
import axios from "axios";
import Endpoint from "../../../utils/Endpoint";
import useAuthConfig from "../../../utils/Config";
import SearchResults from "./SearchResults";
import {Accectdata} from "./Accectdata";
import CrowdfundingSearchResults from "./Crowdfunding";

export function AssetDiscoverySearch({
  acquisitionActive,
  setAcquisitionActive,
  isAdvFilters,
  setIsAdvFilters,
  isAiSearch,
  setIsAiSearch,
  handleAiSearch,
  variant,
  setSearchParam,
  userInformation,
  handleSearchClick,
  searchTerm,
  setSearchTerm,
  syndicateData,
  list,
  userInformationrole,
}) {
  const [isCard, setIsCard] = useState(false);
  const [isAiState, setIsAiState] = useState(0);
  // const location = useLocation();
  const navigate = useNavigate();
  const [assetType, setAssetType] = useState("");
  const [location, setLocation] = useState("");
  const [priceRange, setPriceRange] = useState([0, 1000000]);
  const [areaRange, setAreaRange] = useState([0, 100000]);
  const [performance, setPerformance] = useState({capRate: null, cashOnCash: null});
  const config = useAuthConfig();
  const [List, setList] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [isResultsVisible, setIsResultsVisible] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [IRR, setIRR] = useState("");
  const [equityMultiple, setEquityMultiple] = useState("");
  const [cashYieldAvg, setCashYieldAvg] = useState("");
  const [structure, setStructure] = useState("");
  const [crowdfundingResultsVisible, setCrowdfundingResultsVisible] = useState(false); 
  const [crowdfundingResults, setCrowdfundingResults] = useState([]); 

  const onSearch = async () => {
    const params = new URLSearchParams();
    if (assetType) params.append("assetType", assetType);
    if (location) params.append("location", location);
    if (priceRange[0] > 0 || priceRange[1] < 1000000) {
      params.append("priceMin", priceRange[0]);
      params.append("priceMax", priceRange[1]);
    }
    if (areaRange[0] > 0 || areaRange[1] < 100000) {
      params.append("areaMin", areaRange[0]);
      params.append("areaMax", areaRange[1]);
    }
    if (performance.capRate) params.append("capRate", performance.capRate);
    if (performance.cashOnCash) params.append("cashOnCash", performance.cashOnCash);

    const searchParams = params.toString();
    const searchURL = `${Endpoint.search_assets}?${searchParams}`;

    setIsLoading(true);
    try {
      const response = await axios.get(searchURL, config);
      if (response.data) {
        setSearchResults(response.data);
        setIsResultsVisible(true);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSearch_CrowdfundingSearchResults = async () => {
    const params = new URLSearchParams();
  
    if (assetType) params.append("assetType", assetType);
    if (IRR) params.append("IRR", IRR);
    if (equityMultiple) params.append("equityMultiple", equityMultiple);
    if (cashYieldAvg) params.append("cashYieldAvg", cashYieldAvg);
    if (structure) params.append("structure", structure);
  
    const searchParams = params.toString();
    const searchURL = `${Endpoint.CrowdfundingSearchResults}?${searchParams}`;
  
    setIsLoading(true);
    try {
      const response = await axios.get(searchURL, config);
  
      if (response?.data?.data?.length) {
        setCrowdfundingResults(response.data.data);
        setCrowdfundingResultsVisible(true);
      } else {
        setCrowdfundingResults([]);
        setCrowdfundingResultsVisible(true);
      }
    } catch (error) {
      console.error("Error fetching crowdfunding search results:", error);
    } finally {
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.has("cf")) {
      setAcquisitionActive(false);
    } else {
      setAcquisitionActive(true);
    }
  }, [location.search]);

  let timeoutId;
  // const handleSearchChange = (e) => {
  //   clearTimeout(timeoutId);
  //   const value = e.target.value;
  //   setSearchTerm(value);
  //   timeoutId = setTimeout(() => {
  //     setSearchParam(value);
  //   }, 100);
  // };

  const inputRef = useRef(null);

  const [isPriceRangeOpen, setIsPriceRangeOpen] = useState(false);
  const [isAreaRangeOpen, setIsAreaRangeOpen] = useState(false);
  const [priceValue, setPriceValue] = useState();

  const handlePriceSliderChange = (e, newValue) => {
    setPriceValue(newValue);
  };

  const [areaValue, setAreaValue] = useState();

  const handleAreaInputChange = (e) => {
    setAreaValue(e.target.value === "" ? null : Number(e.target.value));
  };

  const handleAreaSelectorClick = () => {
    setIsAreaRangeOpen(!isAreaRangeOpen);
  };

  const handlePriceSelectorClick = () => {
    setIsPriceRangeOpen(!isPriceRangeOpen);
  };

  const handleButtonClick = () => {
    handleSearchClick(searchTerm);
  };
  const handlePriceInputChange = (e) => {
    setPriceValue(e.target.value === "" ? null : Number(e.target.value));
  };
  const handleAreaSliderChange = (e, newValue) => {
    setAreaValue(newValue);
  };

  let data = ["Residential", "commercial"];

  return (
    <div className={`AssetDiscoverySearch ${variant}`}>
      <div className="AssetDiscoverySearch-T">
        <div style={{display: "flex"}}>
          {userInformation?.roleTypes === 2 && (
            <div
              onClick={() => {
                setAcquisitionActive(true);
                navigate({search: "?aq=true"});
              }}
              className={"AssetDiscoverySearch-tabItem " + (acquisitionActive ? "active" : "")}
            >
              <p>Acquisition</p>
            </div>
          )}

          <div
            onClick={() => {
              setAcquisitionActive(false);
              navigate({search: "?cf=true"});
            }}
            className={"AssetDiscoverySearch-tabItem " + (!acquisitionActive && " active ")}
          >
            <p>Crowdfunding</p>
          </div>
        </div>
        <div className="AssetDiscoverySearch-T-R">
          <button
            className="white_buttons"
            onClick={handleAiSearch}
          >
            {/* <BulbSvg /> */}
            <span>AI Search</span>
          </button>
          <button
            className="white_buttons"
            onClick={() => {
              setIsAdvFilters(!isAdvFilters);
              setIsAiSearch(false);
            }}
          >
            <SliderIcon />
            <span>Advanced Filters</span>
          </button>
        </div>
      </div>
      {acquisitionActive && (
        <div>
          <div className="AssetDiscoverySearch-B">
            <div className="left">
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">Asset Type</div>
                <div
                  className="assetTypeItem-B"
                  style={{display: "flex", alignItems: "center"}}
                >
                  <div
                    className="search_bar"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                  >
                    <InputBox
                      type="text"
                      className="search_input"
                      placeholder="Search..."
                      ref={inputRef}
                      onChange={(e) => setAssetType(e.target.value)}
                      value={assetType}
                    />
                  </div>
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">Location</div>
                <div className="assetTypeItem-B">
                  {/* <InputBox
                  label="name"
                  type="text"
                  // svgPath={searchIcon}
                  width={"250px "}
                  placeholder={"state"}
                  ref={inputRef}
                  onChange={handleSearch}
                  value={searchTerm}
                /> */}
                  <InputBox
                    width={"250px "}
                    type="text"
                    placeholder="State"
                    onChange={(e) => setLocation(e.target.value)}
                    value={location}
                  />
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">PRICE</div>
                <div className="assetTypeItem-B">
                  {/* <InputBox
                    label="name"
                    type="text"
                    placeholder={"$ 0 – 0"}
                    width={"150px"}
                  /> */}
                  <div
                    className="priceRange range_selector_wrapper"
                    onClick={handlePriceSelectorClick}
                  >
                    <p className="rangeBoxControl ">
                      $ <span>{priceValue}</span>
                    </p>
                    {isPriceRangeOpen === true ? (
                      <div
                        className="priceRangeSelector range_selector_box"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="slider_box">
                          <Slider
                            value={typeof priceValue === "number" ? priceValue : 0}
                            onChange={handlePriceSliderChange}
                            aria-labelledby="input-slider"
                            step={10}
                            min={0}
                            max={1000000}
                          />
                          <Input
                            // onBlur={handleBlur}
                            value={priceValue}
                            size="small"
                            onChange={handlePriceInputChange}
                            inputProps={{
                              step: 1,
                              // min: 0,
                              max: 1000000,
                              type: "number",
                              "aria-labelledby": "input-slider",
                            }}
                          />{" "}
                        </div>
                        <button
                          onClick={() => {
                            setIsPriceRangeOpen(false);
                          }}
                        >
                          Close
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="assetTypeItem">
                <div className="assetTypeItem-T">Area</div>
                <div className="assetTypeItem-B">
                  {/* <InputBox
                    label="name"
                    type="text"
                    placeholder={"10K – 20K"}
                    width={"150px"}
                    svgPath={SFICOn}
                  /> */}
                  <div
                    className="areaRange range_selector_wrapper"
                    onClick={handleAreaSelectorClick}
                  >
                    <p className="rangeBoxControl ">
                      <span>{areaValue}</span> sq.f
                    </p>
                    {isAreaRangeOpen === true ? (
                      <div
                        className="areaRangeSelector range_selector_box"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="slider_box">
                          <Slider
                            value={typeof areaValue === "number" ? areaValue : 0}
                            onChange={handleAreaSliderChange}
                            aria-labelledby="input-slider"
                            step={10}
                            min={0}
                            max={1000000}
                          />
                          <Input
                            // onBlur={handleBlur}
                            value={areaValue}
                            size="small"
                            onChange={handleAreaInputChange}
                            inputProps={{
                              step: 1,
                              // min: 0,
                              max: 1000000,
                              type: "number",
                              "aria-labelledby": "input-slider",
                            }}
                          />
                        </div>
                        <button
                          onClick={() => {
                            setIsAreaRangeOpen(false);
                          }}
                        >
                          Close
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              {variant === "large" ? (
                <div className="assetTypeItem">
                  <div className="assetTypeItem-T performance">
                    Performance
                    <div style={{marginLeft: "105px"}}></div>
                  </div>
                  <div className="assetTypeItem-B">
                    <div
                      className="performance_inputs"
                      style={{display: "flex", width: "250px"}}
                    >
                      <InputBox
                        label="name"
                        type="text"
                        placeholder={"Cap Rate 10%"}
                      />
                      <InputBox
                        label="name"
                        type="text"
                        placeholder={"CoC 10%"}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="assetTypeItem">
                  <div className="buttons_wrapper">
                    <div style={{position: "relative"}}>
                      <button
                        className={isCard === 1 && "activeButton"}
                        onClick={() => {
                          setIsCard((prevState) => (prevState !== 1 ? 1 : false));
                        }}
                      ></button>
                      {isCard === 1 && (
                        <div className="options_card">
                          <div className="dropDownOuterCon ">
                            <p style={{marginBottom: "0px"}}> Cap Rate </p>
                            <div className="slider_container">
                              <div className="top_div inputFlexLine">
                                <span>9</span>
                                <p>%</p>
                              </div>
                              <Slider
                                defaultValue={30}
                                aria-label="Default"
                                valueLabelDisplay="auto"
                                onChange={(event) => {
                                  // setPercentOwnership(event.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="dropDownOuterCon ">
                            <p style={{marginBottom: "0px"}}> Cash-on-cash Return</p>
                            <div className="slider_container">
                              <div className="top_div inputFlexLine">
                                <span>9</span>
                                <p>%</p>
                              </div>
                              <Slider
                                defaultValue={30}
                                aria-label="Default"
                                valueLabelDisplay="auto"
                                onChange={(event) => {
                                  // setPercentOwnership(event.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div style={{position: "relative"}}>
                      <button
                        className={isCard === 2 && "activeButton"}
                        onClick={() => {
                          setIsCard((prevState) => (prevState !== 2 ? 2 : false));
                        }}
                      ></button>
                      {isCard === 2 && (
                        <div className="options_card">
                          <div className="switch_wrapper">
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  sx={{m: 1}}
                                  defaultChecked={true}
                                  // onChange={() =>
                                  //   setPrice((prev) => ({
                                  //     ...prev,
                                  //     any: !prev.any,
                                  //   }))
                                  // }
                                />
                              }
                              label="Show Off-Market"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  .
                </div>
              )}
            </div>
            <div className="right">
              <div className="top">
                <p>
                  <span> {list?.length} </span>Assets found
                </p>
                {variant === "medium" ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.0751 1.58516L6.3251 6.33416L1.5711 11.1772C1.20292 11.5539 0.997773 12.0604 1.00002 12.5871C1.00226 13.1139 1.21172 13.6186 1.5831 13.9922L5.8821 18.2912C5.97459 18.3846 6.08472 18.4587 6.20609 18.5091C6.32746 18.5596 6.45765 18.5854 6.5891 18.5852H18.5901V16.5852H11.6321L18.8541 9.36316C19.6341 8.58316 19.6341 7.31516 18.8541 6.53516L13.9031 1.58516C13.5279 1.21046 13.0193 1 12.4891 1C11.9589 1 11.4503 1.21046 11.0751 1.58516ZM7.0031 16.5852L2.9981 12.5792L7.7461 7.74216L8.4891 6.99916L13.4391 11.9492L8.8821 16.5062C8.85749 16.531 8.83445 16.5574 8.8131 16.5852H7.0031Z"
                      fill="#9EA0A5"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
              <div className="bottom">
                {variant === "large" ? (
                  <div className="switch_wrapper">
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{m: 1}}
                          defaultChecked={true}
                          // color=""
                          // onChange={() =>
                          //   setPrice((prev) => ({
                          //     ...prev,
                          //     any: !prev.any,
                          //   }))
                          // }
                        />
                      }
                      label="Off-market"
                    />
                  </div>
                ) : (
                  ""
                )}
                {variant === "large" ? (
                  <div className="searchButton">
                    <PrimaryButtonSvgFirst
                      svgPath={searchWhite}
                      svgWidth={"20px"}
                      svgHeight={"20px"}
                      name={"Search"}
                      classNames={"search_button"}
                      onclick={onSearch}
                    />
                  </div>
                ) : (
                  <div className="searchButton">
                    <PrimaryButtonSvgFirst
                      svgPath={searchWhite}
                      svgWidth={"20px"}
                      svgHeight={"20px"}
                      classNames={"search_button"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <SearchResults
            isVisible={isResultsVisible}
            isLoading={isLoading}
            results={searchResults}
            onClose={() => setIsResultsVisible(false)}
          />
        </div>
      )}
      {!acquisitionActive &&
        (variant === "large" ? (
          <div className="AssetDiscoverySearch-B crowdfunding">
            <div className="left">
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">Asset Type</div>
                <div className="assetTypeItem-B">
                  <DropDown
                    selectedData={["Residential"]}
                    margin={"10px 0px 0px 0px"}
                    width={"170px"}
                    label="name"
                    data={data}
                    type="text"
                  />
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">IRR</div>
                <div className="assetTypeItem-B">
                  <InputBox
                    label="name"
                    type="text"
                    width={"100px"}
                    placeholder={"10 – 15   %"}
                    onChange={(e) => setIRR(e.target.value)}
                  />
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">Equity Multiple</div>
                <div className="assetTypeItem-B">
                  <InputBox
                    label="name"
                    type="text"
                    width={"100px"}
                    placeholder={"2.0x – 3.0x"}
                    onChange={(e) => setEquityMultiple(e.target.value)}
                  />
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">Cash Yield, AVG</div>
                <div className="assetTypeItem-B">
                  <InputBox
                    label="name"
                    type="text"
                    width={"100px"}
                    placeholder={"7 – 10   %"}
                    onChange={(e) => setCashYieldAvg(e.target.value)}
                  />
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">STRUCTURE</div>
                <div className="assetTypeItem-B">
                  <DropDown
                    selectedData={structure}
                    margin={"10px 0px 0px 0px"}
                    width={"170px"}
                    label="name"
                    type="text"
                    setSelectedData={setStructure}
                  />
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">Minimum Investment</div>
                <div className="assetTypeItem-B">
                  <InputBox
                    label="name"
                    type="text"
                    placeholder={"up to  $25,000"}
                    width={"130px"}
                  />
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">Investment period</div>
                <div className="assetTypeItem-B">
                  <InputBox
                    label="name"
                    type="text"
                    placeholder={"3 – 5    "}
                    width={"130px"}
                  />
                </div>
              </div>
            </div>
            <div className="right">
              <div className="top">
                <p>
                  <span> {syndicateData?.length} </span>Offerings found
                </p>
              </div>
              <div className="bottom">
                <div className="switch_wrapper">
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{m: 1}}
                        defaultChecked={true}
                        // onChange={() =>
                        //   setPrice((prev) => ({
                        //     ...prev,
                        //     any: !prev.any,
                        //   }))
                        // }
                      />
                    }
                    label="Accredited only"
                  />
                </div>
                <div className="searchButton">
                  {/* <Link to="/asset-search"> */}
                  <PrimaryButtonSvgFirst
                    svgPath={searchWhite}
                    svgWidth={"20px"}
                    svgHeight={"20px"}
                    name={"Search"}
                    classNames={"search_button"}
                    onclick={onSearch_CrowdfundingSearchResults}
                  />
                  {/* </Link> */}
                </div>
              </div>
            </div>
            <CrowdfundingSearchResults
              isVisible={crowdfundingResultsVisible}
              isLoading={isLoading}
              results={crowdfundingResults}
              onClose={() => setCrowdfundingResultsVisible(false)}
            />
          </div>
        ) : (
          <div className="AssetDiscoverySearch-B">
            <div className="left">
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">Asset Type</div>
                <div className="assetTypeItem-B">
                  <DropDown
                    selectedData={["commercial"]}
                    margin={"10px 0px 0px 0px"}
                    width={"170px"}
                    label="name"
                    type="text"
                  />
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">Location</div>
                <div className="assetTypeItem-B">
                  <InputBox
                    label="name"
                    type="text"
                    // svgPath={searchIcon}
                    width={"250px "}
                    placeholder={"City, Address, Zip, "}
                  />
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">PRICE</div>
                <div className="assetTypeItem-B">
                  <InputBox
                    label="name"
                    type="text"
                    placeholder={"$ 0 – 0"}
                    width={"150px"}
                  />
                </div>
              </div>
              <div className="assetTypeItem">
                <div className="assetTypeItem-T">Area</div>
                <div className="assetTypeItem-B">
                  <InputBox
                    label="name"
                    type="text"
                    placeholder={"10K – 20K"}
                    width={"150px"}
                    svgPath={SFICOn}
                  />
                </div>
              </div>
              {variant === "large" ? (
                <div className="assetTypeItem">
                  <div className="assetTypeItem-T">
                    Performance
                    <div style={{marginLeft: "105px"}}></div>
                  </div>
                  <div className="assetTypeItem-B">
                    <div
                      className="performance_inputs"
                      style={{display: "flex", width: "250px"}}
                    >
                      <InputBox
                        label="name"
                        type="text"
                        placeholder={"Cap Rate 10%"}
                      />
                      <InputBox
                        label="name"
                        type="text"
                        placeholder={"CoC 10%"}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="assetTypeItem">
                  <div className="buttons_wrapper">
                    <div style={{position: "relative"}}>
                      <button
                        className={isCard === 1 && "activeButton"}
                        onClick={() => {
                          setIsCard((prevState) => (prevState !== 1 ? 1 : false));
                        }}
                      ></button>
                      {isCard === 1 && (
                        <div className="options_card">
                          <div className="dropDownOuterCon ">
                            <p style={{marginBottom: "0px"}}> Cap Rate </p>
                            <div className="slider_container">
                              <div className="top_div inputFlexLine">
                                <span>9</span>
                                <p>%</p>
                              </div>
                              <Slider
                                defaultValue={30}
                                aria-label="Default"
                                valueLabelDisplay="auto"
                                onChange={(event) => {
                                  // setPercentOwnership(event.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="dropDownOuterCon ">
                            <p style={{marginBottom: "0px"}}> Cash-on-cash Return</p>
                            <div className="slider_container">
                              <div className="top_div inputFlexLine">
                                <span>9</span>
                                <p>%</p>
                              </div>
                              <Slider
                                defaultValue={30}
                                aria-label="Default"
                                valueLabelDisplay="auto"
                                onChange={(event) => {
                                  // setPercentOwnership(event.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div style={{position: "relative"}}>
                      <button
                        className={isCard === 2 && "activeButton"}
                        onClick={() => {
                          setIsCard((prevState) => (prevState !== 2 ? 2 : false));
                        }}
                      ></button>
                      {isCard === 2 && (
                        <div className="options_card">
                          <div className="switch_wrapper">
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  sx={{m: 1}}
                                  defaultChecked={true}
                                  // onChange={() =>
                                  //   setPrice((prev) => ({
                                  //     ...prev,
                                  //     any: !prev.any,
                                  //   }))
                                  // }
                                />
                              }
                              label="Show Off-Market"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  .
                </div>
              )}
            </div>
            <div className="right">
              <div className="top">
                <p>
                  <span> 421,458 </span>Assets found
                </p>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.0751 1.58516L6.3251 6.33416L1.5711 11.1772C1.20292 11.5539 0.997773 12.0604 1.00002 12.5871C1.00226 13.1139 1.21172 13.6186 1.5831 13.9922L5.8821 18.2912C5.97459 18.3846 6.08472 18.4587 6.20609 18.5091C6.32746 18.5596 6.45765 18.5854 6.5891 18.5852H18.5901V16.5852H11.6321L18.8541 9.36316C19.6341 8.58316 19.6341 7.31516 18.8541 6.53516L13.9031 1.58516C13.5279 1.21046 13.0193 1 12.4891 1C11.9589 1 11.4503 1.21046 11.0751 1.58516ZM7.0031 16.5852L2.9981 12.5792L7.7461 7.74216L8.4891 6.99916L13.4391 11.9492L8.8821 16.5062C8.85749 16.531 8.83445 16.5574 8.8131 16.5852H7.0031Z"
                    fill="#9EA0A5"
                  />
                </svg>
              </div>
              <div className="bottom">
                {/* <div className="switch_wrapper">
                   <FormControlLabel
                     control={
                       <IOSSwitch
                         sx={{m: 1}}
                         defaultChecked={true}
                         // onChange={() =>
                         //   setPrice((prev) => ({
                         //     ...prev,
                         //     any: !prev.any,
                         //   }))
                         // }
                       />
                     }
                     label="Off-market"
                   />
                 </div> */}
                {variant === "large" ? (
                  <div className="searchButton">
                    <Link to="/asset-search">
                      <PrimaryButtonSvgFirst
                        svgPath={searchWhite}
                        svgWidth={"20px"}
                        svgHeight={"20px"}
                        name={"Search"}
                        classNames={"search_button"}
                        onclick={onSearch_CrowdfundingSearchResults}
                      />
                    </Link>
                  </div>
                ) : (
                  <div className="searchButton">
                    <PrimaryButtonSvgFirst
                      svgPath={searchWhite}
                      svgWidth={"20px"}
                      svgHeight={"20px"}
                      classNames={"search_button"}
                      onclick={handleButtonClick}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}

      {isAdvFilters && (
        <div
          className={`advanced-filters-container ${isAdvFilters ? "open" : ""}`}
          onClick={() => setIsAdvFilters(false)}
        >
          <div
            className="advanced-filters-inner"
            onClick={(e) => e.stopPropagation()}
          >
            <AdvancedFilters />
          </div>
        </div>
      )}
      {isAiSearch && (
        <AiSearch
          setIsAiState={setIsAiState}
          isAiSearch={isAiSearch}
          handleAiSearch={handleAiSearch}
        />
      )}
      {isAiSearch && (
        <AiSearch
          setIsAiState={setIsAiState}
          isAiSearch={isAiSearch}
          handleAiSearch={handleAiSearch}
        />
      )}
      {isAiSearch && isAiState === 1 && (
        <AiSearch1
          setIsAiState={setIsAiState}
          isAiState={isAiState}
          handleAiSearch={handleAiSearch}
        />
      )}
      {isAiSearch && isAiState === 2 && (
        <AiSearch2
          setIsAiState={setIsAiState}
          isAiState={isAiState}
          handleAiSearch={handleAiSearch}
        />
      )}
    </div>
  );
}
