import React from "react";
import person from "../../../../assets/svg/profileAvatar.png";
import Checkbox from "../../settings/Checkbox";

export const Row2 = ({ pending, handlePersonClick, person }) => {
  return (
    <div className="table_row table_row2">
      <div className="left">
        <div
          className="item first"
          onClick={() => handlePersonClick(person._id)} // Pass the person's id for handling the click
        >
          <div style={{ marginTop: "10px" }}>
            <Checkbox />
          </div>
          <div className="user_img_name">
            <img className="person" src={person.profileImage || person} alt="" />
            <div className="text_wrapper">
              <p>{person.name}</p>
              <span>{person.email}</span>
            </div>
          </div>
        </div>
        <div className="item second">
          <div className="text_wrapper">
            <p>2 Assets</p> {/* You can update the "Assets" if needed */}
          </div>
        </div>
        <div className="item">
          <div className="assets_box">3</div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p>{person.phone_number || 'N/A'}</p>
          </div>{" "}
        </div>
        <div className="item">
          <div className="text_wrapper">
            <button className="btn_status">
              <span
                style={{ background: pending ? "#ED962D" : "green" }}
                className="active"
              ></span>
              {pending ? "Pending" : "Active"}
            </button>
          </div>
        </div>
        <div className="item">
          <div className="text_wrapper">
            <p>12</p>
          </div>{" "}
        </div>

        <div className="item">
          <div className="text_wrapper">
            <p>12</p>
          </div>{" "}
        </div>

        <div className="item">
          <div className="text_wrapper">
            <p>12</p>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};
