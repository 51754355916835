import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Endpoint from "../../utils/Endpoint";


export const fetchAnnualFinancialData = createAsyncThunk(
  "financialData/fetchAnnualFinancialData",
  async ({ property_code, year }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const idToken = auth.idToken; 
      const response = await axios.get(Endpoint.OperatingIncomeExpences, {
        headers: {
          "auth-token": idToken,
        },
        params: { property_code, year },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data ? error.response.data : error.message
      );
    }
  }
);

const financialDataSlice = createSlice({
  name: "financialData",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnnualFinancialData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAnnualFinancialData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchAnnualFinancialData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch data";
      });
  },
});

export default financialDataSlice.reducer;
