import React from "react";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";

const GOOGLE_MAPS_API_KEY = "";

const mapStyles = [
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [{color: "#444444"}],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [{color: "#f2f2f2"}],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [{visibility: "off"}],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [{saturation: -100}, {lightness: 45}],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [{visibility: "simplified"}],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{visibility: "on"}],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [{visibility: "on"}, {color: "#a69aba"}],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{visibility: "on"}],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [{visibility: "on"}, {color: "#d4d1d5"}],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [{visibility: "off"}],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [{visibility: "off"}],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [{color: "#d4cae3"}, {visibility: "on"}],
  },
];

const MiniMap = ({lat, long, style = {width: "100%", height: "100%"}, className, mapOptions = {}, markerOptions = {}}) => {
  if (!lat || !long) {
    return null;
  }

  const mapContainerStyle = {
    ...style,
  };

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={{lat, lng: long}}
        zoom={16}
        options={{...mapOptions, styles: mapStyles}}
      >
        <Marker
          position={{lat, lng: long}}
          {...markerOptions}
        />
      </GoogleMap>
    </LoadScript>
  );
};

export default MiniMap;
