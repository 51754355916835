import React, {useState} from "react";
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Input} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "../../assets/portfolio/svgs/delete.svg";
import {InputWithIcon, InputWithAddFieldFunc, UploadFile, TextInputWithLabel, DropdownWithCheckbox} from "../portfolio/Inputs";
import info from "../../assets/svg/info.svg";
import EyeIcon from "../../assets/dealsroom/svg/eye.svg";
import SettingsIcon from "../../assets/svg/settings.svg";
import DownloadIcon from "../../assets/svg/download.svg";
import DocumentImg from "../../assets/Image/document-img.png";
import CreateFolder from "../../assets/svg/create-folder.svg";
import FolderDoc from "../../assets/svg/folder-document.svg";
import "./Disposition.scss";
import axios from "axios";
import {useSelector} from "react-redux";
import Endpoint from "../../utils/Endpoint";

const FileUploadPopup = ({open, handleClose}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  let {idToken} = useSelector((state) => state.auth);
  let {selectedProperty} = useSelector((state) => state.portfolio);

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${idToken}`,
        },
      };

      const response = await axios.post(`${Endpoint.uploadPdfcard}?userId=${selectedProperty?._id}`, formData, config);
    }
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        Upload File
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Input
          type="file"
          name="pdffiles"
          onChange={handleFileChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleUpload}
          variant="contained"
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const PropertyDocuments = () => {
  const [openPopup, setOpenPopup] = useState(false);

  const handleOpenPopup = () => setOpenPopup(true);
  const handleClosePopup = () => setOpenPopup(false);

  return (
    <div className="property-documents">
      <div className="property-folders">
        <div className="folder create-folder">
          <img
            src={CreateFolder}
            alt=""
          />
          <p style={{color: "#6B6C6F"}}>Create Folder</p>
          <img
            style={{height: "24px", width: "24px"}}
            src={info}
            alt=""
          />
        </div>

        <div className="folder">
          <img
            src={FolderDoc}
            alt=""
          />
          <p>Land Documents</p>
          <span>(2)</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="436"
            height="1"
            viewBox="0 0 436 1"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 0H436V1H0V0Z"
              fill="#EAEDF3"
            />
          </svg>
          <img
            src={SettingsIcon}
            alt=""
          />
        </div>
      </div>

      <div className="documents-wrapper">
        <div className="documents-box">
          <img
            src={DocumentImg}
            alt=""
          />
          <p>Council Decision No. 135/1969. On the registration of ...</p>
          <div className="documents-icons">
            <img
              src={EyeIcon}
              alt=""
            />
            <div>
              <img
                src={DownloadIcon}
                alt=""
              />
              <img
                src={DeleteIcon}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="documents-box">
          <img
            src={DocumentImg}
            alt=""
          />
          <p>Council Decision No. 135/1969. On the registration of ...</p>
          <div className="documents-icons">
            <img
              src={EyeIcon}
              alt=""
            />
            <div>
              <img
                src={DownloadIcon}
                alt=""
              />
              <img
                src={DeleteIcon}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="documents-box">
          <img
            src={DocumentImg}
            alt=""
          />
          <p>Council Decision No. 135/1969. On the registration of ...</p>
          <div className="documents-icons">
            <img
              src={EyeIcon}
              alt=""
            />
            <div>
              <img
                src={DownloadIcon}
                alt=""
              />
              <img
                src={DeleteIcon}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{width: "570px"}}>
        <Button
          variant="contained"
          onClick={handleOpenPopup}
        >
          Upload New Document
        </Button>
      </div>

      <div className="folder">
        <img
          src={FolderDoc}
          alt=""
        />
        <p>Advanced Techniques</p>
        <span>(2)</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="436"
          height="1"
          viewBox="0 0 436 1"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0H436V1H0V0Z"
            fill="#EAEDF3"
          />
        </svg>
        <img
          src={SettingsIcon}
          alt=""
        />
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="670"
        height="1"
        viewBox="0 0 670 1"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H670V1H0V0Z"
          fill="#EAEDF3"
        />
      </svg>

      <div style={{width: "670px"}}>
        <Button
          variant="contained"
          onClick={handleOpenPopup}
        >
          Upload New Document
        </Button>
      </div>

      <FileUploadPopup
        open={openPopup}
        handleClose={handleClosePopup}
      />
    </div>
  );
};
