import React, {useEffect, useState, useCallback, useId} from "react";
import {Link} from "react-router-dom";
import "./Portfolio.scss";
import sideHeaderLogo from "../../assets/portfolio/images/portfolio-sideheader-logo.png";
import SettingsIcon from "../../assets/portfolio/svgs/settings.svg";
import AiSupport from "./AiSupport";
import {useSelector, useDispatch} from "react-redux";
import {fetchUserDetails, setAssetId, setAssetName} from "../../store/reducers/userDetailsSlice";
import {fetchPortfolio, selectProperty} from "../../store/reducers/portfolioSlice";
import {AssetHeader, InviteBox, OverviewHeader, PortfolioDashboard} from "./portfolioData/portfolioDatanew";
import {PropertyDetails} from "./portfolioData/portfolioData";
import {items} from "./portfolioData/itemsData";
import SearchDarkIcon from "../../assets/portfolio/svgs/search-dark.svg";
import noimage from "../../assets/portfolio/images/add_3342713.png";
import Timeout from "../../components/Autologout/Timeout";
import {FaHeart} from "react-icons/fa";
import {addFavorites} from "../../utils/Axios";

const Portfolio = () => {
  const [showAi, setShowAi] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const dispatch = useDispatch();
  const {userInformation} = useSelector((state) => state.auth);
  const {AssectDataOwnerOperator, loading, error, selectedProperty} = useSelector((state) => state.portfolio);
  const {idToken} = useSelector((state) => state.auth);
  const [navIndex, setNavIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  Timeout(idToken);
  const handleAiSupport = useCallback(() => {
    setShowAi((prevShowAi) => !prevShowAi);
  }, []);

  const toggleSidebar = useCallback(() => {
    setSidebarOpen((prevSidebarOpen) => !prevSidebarOpen);
  }, []);


  useEffect(() => {
    if (idToken) {
      dispatch(fetchUserDetails(idToken));
    }
  }, [dispatch, idToken]);

  useEffect(() => {
    if (userInformation?.email) {
      dispatch(fetchPortfolio(userInformation.email))
        .unwrap()
        .catch((error) => {});
    }
  }, [dispatch, userInformation?.email]);


  const handlePropertyClick = useCallback(
    (item) => {
      dispatch(selectProperty(item));
      setShowDashboard(false);

      dispatch(setAssetId(item?._id));
      dispatch(setAssetName(item?.assetName));
    },
    [dispatch],
  );

  // Search functionality
  const filteredAssets = AssectDataOwnerOperator?.filter((item) => item?.assetName?.toLowerCase().includes(searchQuery.toLowerCase()));
  useEffect(() => {
    if (AssectDataOwnerOperator?.length > 0 && !selectedProperty) {
      const firstProperty = AssectDataOwnerOperator[0];
      dispatch(selectProperty(firstProperty));
      dispatch(setAssetId(firstProperty?._id));
      dispatch(setAssetName(firstProperty?.assetName));
    }
  }, [AssectDataOwnerOperator, selectedProperty, dispatch]);

  const addtofavorite = async (assetId) => {
    const userId = userInformation._id; 
    const assetIds = [assetId]; 
    await addFavorites(userId, assetIds,idToken); 
  };
  

  return (
    <div className="portfolio-container">
      <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
        <Link
          // to="/portfolio"
          className="sidebar-header"
          onClick={() => setShowDashboard(true)}
        >
          <img
            src={selectedProperty?.assetsImages && selectedProperty?.assetsImages.length > 0 ? selectedProperty?.assetsImages[0] : noimage}
            alt="sideHeaderLogo"
          />
          <div className="sideheader-textWrapper">
            <h2>Portfolio Overview</h2>
            <h4>
              <span>{AssectDataOwnerOperator?.length}</span> Properties
            </h4>
          </div>
        </Link>
        <div className="search-input-wrapper">
          <input
            type="text"
            placeholder="Type to search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />

          <div className="search-icons">
            <img
              src={SearchDarkIcon}
              alt="img"
            />
            {/* <img
              src={SettingsIcon}
              alt="img"
            /> */}
          </div>
        </div>

        <div className="sidebar-content">
          {loading && (
            <div className="shimmer-effect">
              {[...Array(6)].map((_, index) => (
                <div
                  key={index}
                  className="shimmer-block"
                ></div>
              ))}
            </div>
          )}
          {error && error.includes("Access denied") ? (
            items.map((item, index) => (
              <Link
                // to="/portfolio"
                className={`nav-card ${index === navIndex && showDashboard === false ? "selected" : ""}`}
                onClick={() => {
                  setNavIndex(index);
                  setShowDashboard(false);
                }}
                key={item.id}
              >
                <img
                  src={item.img}
                  alt={item.name}
                />
                <div className="nav-card-info">
                  <h2>{item.name}</h2>
                  <p>
                    {item.addressLine1}
                    <br />
                    {item.addressLine2}
                  </p>
                  <h4>{item.owner}</h4>
                  <div className="price-wrapper">
                    <div className="status-wrapper">
                      <p style={{backgroundColor: `${item.status === "Actively managed" ? "#34AA44" : item.status === "For Sale" ? "#1665D8" : "#34AA44"}`}}>&nbsp;</p>
                      <h4>{item.status}</h4>
                    </div>
                    {(item.status === "For Sale" || item.status === "For Rent") && (
                      <h2>
                        {item.currency} {item.price}
                      </h2>
                    )}
                  </div>
                </div>
              </Link>
            ))
          ) : error ? (
            <div className="no_access">
              <p className="error-message-Portfolio">You don't have access to this portfolio</p>
            </div>
          ) : (
            <>
              {!loading && filteredAssets?.length === 0 && <p className="No-Portfolio">No matching assets found</p>}
              {!loading &&
                filteredAssets?.length > 0 &&
                filteredAssets?.map((item) => (
                  <Link
                    to="#"
                    className={`nav-card ${selectedProperty?._id === item._id ? "selected" : ""}`}
                    key={item._id}
                  >
                    <img
                      src={item?.assetsImages && item.assetsImages.length > 0 ? item.assetsImages[0] : noimage}
                      alt={item?.assetName || "Default Alt Text"}
                      loading="lazy"
                      onClick={() => handlePropertyClick(item)}
                    />
                    {/* Favorite Icon */}
                    <div className="favorite-icon">
                      <FaHeart
                        size={24}
                        color="#ff4d4d"
                        style={{cursor: "pointer"}} 
                        onClick={() => addtofavorite(item._id)} 
                      />
                    </div>

                    <div className="nav-card-info">
                      <h2>{item?.assetName}</h2>
                      <p>{item?.assetsAddress}</p>
                      <h4>{item?.addedBy}</h4>
                      <div className="price-wrapper">
                        <div className="status-wrapper">{/* <h4>${item?.assetValue?.toLocaleString()}</h4> */}</div>
                        {(item?.status === "For Sale" || item?.status === "For Rent") && (
                          <h2>
                            {item?.currency} {item?.pricePerUnit}
                          </h2>
                        )}
                      </div>
                    </div>
                  </Link>
                ))}
            </>
          )}
        </div>
      </div>
      <div
        className="hamburger-icon"
        onClick={toggleSidebar}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="main-container">
        <div className="main-header">{showDashboard ? <OverviewHeader /> : <AssetHeader selectedItem={selectedProperty} />}</div>
        <div
          style={{position: "relative"}}
          className="main-content"
        >
          {showDashboard ? <PortfolioDashboard /> : <PropertyDetails selectedItem={selectedProperty} />}
        </div>
        <div className={`ai_support_btn ${showAi ? "showAi" : ""}`}></div>
      </div>
      {showAi && (
        <AiSupport
          handleAiSupport={handleAiSupport}
          showAi={showAi}
        />
      )}
    </div>
  );
};

export default Portfolio;
