import React, {useEffect, useState} from "react";
import "./DealsRoom.scss";
import SvgColorChanger from "../DealsRoom/SvgColorChanger";
import back from "../../assets/dealsroom/svg/back.svg";
import call from "../../assets/dealsroom/svg/call.svg";
import mail from "../../assets/dealsroom/svg/mail.svg";
import message from "../../assets/dealsroom/svg/message.svg";
import rect from "../../assets/dealsroom/images/Rectangle.jpeg";
import {ReactComponent as Assetinformation} from "../../assets/dealsroom/svg/assetinformation.svg";
import {ReactComponent as Dealroom} from "../../assets/dealsroom/svg/dealroom.svg";
import {ReactComponent as Dealeconomics} from "../../assets/dealsroom/svg/dealeconomics.svg";
import {ReactComponent as Loanrequest} from "../../assets/dealsroom/svg/loanrequest.svg";
import {ReactComponent as Syndicate} from "../../assets/dealsroom/svg/syndicate.svg";
import {ReactComponent as Checklist} from "../../assets/dealsroom/svg/checklist.svg";
import {ReactComponent as Valuatio} from "../../assets/dealsroom/svg/valuation.svg";
import {ReactComponent as History} from "../../assets/dealsroom/svg/history.svg";
import {ReactComponent as Loansetup} from "../../assets/dealsroom/svg/loansetup.svg";
import {ReactComponent as Lenderresponse} from "../../assets/dealsroom/svg/lenderresponse.svg";
import {ReactComponent as Setupsyndicate} from "../../assets/dealsroom/svg/setupsyndicate.svg";
import {ReactComponent as Managesyndicate} from "../../assets/dealsroom/svg/managesyndicate.svg";
import {ReactComponent as Todo} from "../../assets/dealsroom/svg/todo.svg";
import {ReactComponent as Note} from "../../assets/dealsroom/svg/notes.svg";
import dropdown from "../../assets/dealsroom/svg/dropdown.svg";
import {Link, useLocation} from "react-router-dom";
import DealRoomComp from "./DealRoomComp";
import LoanRequstComp from "./LoanRequstComp";
import SyndicateComp from "./SyndicateComp";
import AssetInformation from "./AssetInformation";
import StartSyndicate from "./StartSyndicate";
import SetupLoanReq from "./SetupLoanReq";
import Version from "./Version";
import ToDo from "./ToDo";
import LenderResponses from "./LenderResponses";
import LenderResponsesDealRoom from "./LenderResponsesDealRoom";
import Notes from "../Notes/Notes";
import Valuation from "../Valuation/Valuation";
import ManageSyndicates from "./ManageSyndicates";
import BlockChain from "./BlockChain";
import BlockchainOnboard from "./BlockchainOnboard";
import {useSelector} from "react-redux";
import {useParams, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {fetchPortfolio} from "../../store/reducers/portfolioSlice";
import {MessageBox} from "../assetDiscovery/assets/Assect";

const DealsRoom = () => {
  const [isClicked, setIsClicked] = useState(1);
  const [syndicateState, setSyndicate] = useState(1);
  const [loanReqState, setLoanReq] = useState(1);
  const [lenderResponseTab, setLenderResponseTab] = useState(0);
  const valuationParams = useLocation();
  const [expandedMenus, setExpandedMenus] = useState([]);
  // const selectedData = useSelector((state) => state.portfolio.selectedProperty);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let userData = useSelector((state) => state.auth.userInformation);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  const {id} = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPortfolio(id));
  }, [dispatch, id]);

  const selectedData = useSelector((state) => {
    const dataFromPrimarySource = state.portfolio?.AssectDataOwnerOperator?.find((item) => item._id === id);

    if (!dataFromPrimarySource) {
      return state.DealsSyndicateSlice.assets.find((item) => item._id === id);
    }

    return dataFromPrimarySource;
  });

  useEffect(() => {
    setIsClicked(valuationParams?.state?.isClicked || 1);
  }, [valuationParams]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleMenuClicked = (data) => {
    setIsClicked(data);
    if (data === 4) {
      setLoanReq(1);
    } else if (data === 5) {
      setSyndicate(1);
    }
    setIsMenuOpen(false);
  };

  const toggleMenu = (id) => {
    if (expandedMenus.includes(id)) {
      setExpandedMenus(expandedMenus.filter((menuId) => menuId !== id));
    } else {
      setExpandedMenus([...expandedMenus, id]);
    }
  };

  const toggleMessageBox = () => {
    setIsMessageBoxVisible(!isMessageBoxVisible);
  };

  return (
    <div className="deals-room">
      <div
        className="hamburger-DealsRoom"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <div className="hamburger-DealsRoom-lines"></div>
        <div className="hamburger-DealsRoom-lines"></div>
        <div className="hamburger-DealsRoom-lines"></div>
      </div>
      {isClicked !== 20 && (
        <div className={`deals-room-left-container ${isMenuOpen ? "open" : ""}`}>
          <Link
            to={lenderResponseTab === 1 ? "/dealsroom" : "/deals"}
            onClick={() => setLenderResponseTab(0)}
          >
            <div className="acqu">
              <img
                src={back}
                alt="image"
              />
              <span>{lenderResponseTab === 1 && isClicked === 13 ? "Lender Responses" : "Acquisition Deals"}</span>
            </div>
          </Link>
          <div className="deal-title">
            <div className="deal-left-img">
              <img
                src={selectedData?.assetsImages[0]}
                style={{objectFit: "cover"}}
                alt="Rectangle deal img"
              />
            </div>
            <div className="deal-left-img-text">
              <span>{selectedData?.assetName}</span>
              <span>{selectedData?.assetsAddress}</span>
            </div>
          </div>
          <div className="deal-room-contact">
            <span>Contact person</span>
            <div className="deal-contact-detail">
              <div className="deal-contact-detail-left">
                <span>{selectedData?.addedBy}</span>
                <span>{userData?.accountType}</span>
              </div>
              <div className="deal-contact-detail-right">
                <div className="dealsroom_admin">
                  <a
                    href={`https://wa.me/${selectedData?.phoneNumber}?text=Hello%20${selectedData?.addedBy},%20I%20would%20like%20to%20discuss%20the%20deal%20regarding%20${selectedData?.assetName}.`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={call}
                      alt="WhatsApp"
                    />
                  </a>

                  <a
                    href={`https://mail.google.com/mail/?view=cm&fs=1&to=${selectedData?.email}&su=Subject%20Here&body=Hello%20${selectedData?.addedBy},`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={mail}
                      alt="Email"
                    />
                  </a>

                  <img
                    src={message}
                    alt="Message"
                    onClick={toggleMessageBox}
                    style={{cursor: "pointer"}}
                    className="DealsRoom_message_dealsroom"
                  />
                </div>
                <div></div>
              </div>
              {isMessageBoxVisible && (
                <div className="message-box-wrapper">
                  <MessageBox />
                </div>
              )}
            </div>
          </div>
          <div className="deal-room-left-menu">
            <span>main menu</span>
          </div>
          <LeftMenu
            icon={<Assetinformation />}
            name={"Asset Information"}
            id={1}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
          />
          {/* <LeftMenu
            icon={<Assetinformation />}
            name={"Block Chain"}
            id={20}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
          />
          <LeftMenu
            icon={<Assetinformation />}
            name={"Blockchain Onboard"}
            id={22}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
          /> */}
          <LeftMenuDrop
            icon={<Dealeconomics />}
            name={"Deal Economics"}
            id={3}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
            expanded={expandedMenus.includes(3)}
            toggleMenu={toggleMenu}
          />
          <LeftMenuSort
            icon={<Valuatio />}
            name={"Valuation"}
            id={10}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
            expanded={expandedMenus.includes(3)}
          />
          <LeftMenuSort
            icon={<History />}
            name={"Version"}
            id={11}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
            expanded={expandedMenus.includes(3)}
          />
          {/* <LeftMenuDrop
            icon={<Loanrequest />}
            name={"Loan Request"}
            id={4}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
            expanded={expandedMenus.includes(4)}
            toggleMenu={toggleMenu}
          /> */}
          <LeftMenuSort
            icon={<Loansetup />}
            name={"Loan Setup"}
            id={12}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
            expanded={expandedMenus.includes(4)}
          />
          <LeftMenuSort
            icon={<Lenderresponse />}
            name={"Lender Responses"}
            id={13}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
            expanded={expandedMenus.includes(4)}
          />
          <LeftMenuDrop
            icon={<Syndicate />}
            name={"Syndicate"}
            id={5}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
            expanded={expandedMenus.includes(5)}
            toggleMenu={toggleMenu}
          />
          <LeftMenuSort
            icon={<Setupsyndicate />}
            name={"Setup Syndicate "}
            id={14}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
            expanded={expandedMenus.includes(5)}
          />
          <LeftMenuSort
            icon={<Managesyndicate />}
            name={"Manage Syndicate"}
            id={15}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
            expanded={expandedMenus.includes(5)}
          />
          {/* <LeftMenuSort
            icon={<Dealroom />}
            name={"Deal Room"}
            id={2}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
            expanded={expandedMenus.includes(5)}
          /> */}
          <LeftMenuDrop
            icon={<Checklist />}
            name={"Checklists"}
            id={6}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
            expanded={expandedMenus.includes(6)}
            toggleMenu={toggleMenu}
          />
          <LeftMenuSort
            icon={<Todo />}
            name={"To Do"}
            id={16}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
            expanded={expandedMenus.includes(6)}
          />
          {/* <LeftMenu
            icon={<Note />}
            name={"Notes"}
            id={17}
            state={isClicked}
            handleMenuClicked={handleMenuClicked}
          /> */}
        </div>
      )}
      <div className="center-wrapper">
        {/* {isClicked} */}
        {isClicked === 1 && <AssetInformation selectedData={selectedData} />}
        {isClicked === 11 && <Version />}
        {isClicked === 6 && <ToDo />}
        {isClicked === 16 && <ToDo />}
        {(isClicked === 10 || isClicked === 3) && <Valuation />}
        {isClicked === 4 && loanReqState === 1 && <LoanRequstComp setLoanReq={setLoanReq} />}
        {loanReqState === 2 && isClicked === 4 && (
          <SetupLoanReq
            navdata={1}
            handleMenuClicked={handleMenuClicked}
          />
        )}
        {isClicked === 12 && (
          <SetupLoanReq
            handleMenuClicked={handleMenuClicked}
            navdata={1}
          />
        )}
        {isClicked === 13 && (lenderResponseTab === 0 ? <LenderResponses setLenderResponseTab={setLenderResponseTab} /> : <LenderResponsesDealRoom setLenderResponseTab={setLenderResponseTab} />)}
        {isClicked === 14 && (
          <StartSyndicate
            handleMenuClicked={handleMenuClicked}
            navdata={1}
          />
        )}
        {isClicked === 5 && syndicateState === 1 && <SyndicateComp setSyndicate={setSyndicate} />}
        {syndicateState === 2 && isClicked === 5 && <StartSyndicate handleMenuClicked={handleMenuClicked} />}
        {isClicked === 17 && <Notes />}
        {isClicked === 15 && <ManageSyndicates handleMenuClicked={handleMenuClicked} />}
        {isClicked === 2 && <DealRoomComp />}
        {isClicked === 20 && <BlockChain />}
        {isClicked === 22 && <BlockchainOnboard />}
      </div>
    </div>
  );
};

export default DealsRoom;

const LeftMenu = ({icon, name, id, state, handleMenuClicked}) => {
  return (
    <div
      className={`deals-left-menu ${id === state && "deals-menu-active"}`}
      onClick={() => {
        handleMenuClicked(id);
      }}
    >
      <SvgColorChanger svgContent={icon} />
      <span className="left-menu-title">{name}</span>
    </div>
  );
};

const LeftMenuDrop = ({icon, name, id, state, handleMenuClicked, expanded, toggleMenu}) => {
  return (
    <div
      className={`deals-left-menu drop ${id === state && "deals-menu-active"}`}
      onClick={() => {
        handleMenuClicked(id);
        toggleMenu(id);
      }}
    >
      <div className="deal_left_data_icon">
        <SvgColorChanger svgContent={icon} />
        <span className="left-menu-title">{name}</span>
        <div className="menu_icon">
          <img
            src={dropdown}
            alt="image"
          />
        </div>
      </div>
    </div>
  );
};

const LeftMenuSort = ({icon, name, id, state, handleMenuClicked, expanded}) => {
  return (
    expanded && (
      <div
        className={`deals-left-menu left-menu-sort ${id === state && "deals-menu-active-sort"}`}
        onClick={() => {
          handleMenuClicked(id);
        }}
      >
        <SvgColorChanger svgContent={icon} />
        <span className="left-menu-title">{name}</span>
      </div>
    )
  );
};
