import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import Endpoint from "../../utils/Endpoint";

export const fetchAllAssets = createAsyncThunk("assets/fetchAllAssets", async (_, {rejectWithValue, getState}) => {
  try {
    const idToken = getState().auth.idToken;
    const response = await axios.get(Endpoint.GetAllDealsSyndicate, {
      headers: {"auth-token": idToken},
    });
    return response?.data?.assets;
  } catch (error) {
    return rejectWithValue(error.response.data || error.message);
  }
});

const assetSlice = createSlice({
  name: "asset",
  initialState: {
    assets: [],
    selectedAsset: null, 
    loading: false,
    error: null,
  },
  reducers: {
    selectAsset: (state, action) => {
      state.selectedAsset = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAssets.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllAssets.fulfilled, (state, action) => {
        state.loading = false;
        state.assets = action.payload;
      })
      .addCase(fetchAllAssets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { selectAsset } = assetSlice.actions;
export default assetSlice.reducer;
