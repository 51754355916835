import React, {useEffect, useRef, useState} from "react";
import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import {IOSSwitch, InputBox} from "../../../components/reusableComponents/FormFields/FormFields";
import searchWhite from "../../../assets/svg/searchWhite.svg";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";
import SFICOn from "../../../assets/svg/SF.svg";
import {FormControlLabel, Input, Modal, Slider} from "@mui/material";
import {PrimaryButton, PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import Propcard1 from "../../../assets/Image/assetDiscovery/propcard1.png";
import ProgressBar from "../../../components/reusableComponents/ProgressBar/ProgressBar";
import AdvancedFilters from "../AdvancedFilters";
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import AiSearch, {AiSearch1, AiSearch2} from "../AiSearch";
import moment from "moment";
import {setAssetId, setAssetName, setChatId, setUserAssetData} from "../../../store/reducers/userDetailsSlice";
import {FormatUSD, daysAgo, formatUSD} from "../../../utils/helpers";
import {Label, NotebookPanel, MessageBox, ComparisionPanel, SharePanel, InvestorFeedPanel, BookmarkPanel} from "../assets/Assect";
import {PhoneSvg, BookmarkSvg, NotebookSvg, CompareSvg, ShareSvg, PiechartSvg, SliderIcon} from "../Svgimages/svgallimnages";
import {handleSearch} from "../search/SeacrhFunction";
import { ContactPanel } from "./ContactPanel";

export function PropertyInfoTab({tabNames, collapsed, card, items, assetIdList}) {
    return (
      <Tabs
        selectedTabClassName="active"
        defaultIndex={(collapsed && -1) || 0}
      >
        <TabList>
          <Tab>
            <PhoneSvg />
            {tabNames && <span>Contacts</span>}
          </Tab>
          <Tab>
            <BookmarkSvg />
            {tabNames && <span>Save</span>}
          </Tab>
          <Tab>
            <NotebookSvg />
            {tabNames && <span>Add Note</span>}
          </Tab>
          <Tab>
            <CompareSvg />
            {tabNames && <span>Compare</span>}
          </Tab>
          <Tab>
            <ShareSvg />
            {tabNames && <span>Share</span>}
          </Tab>
          <Tab>
            <PiechartSvg />
            {tabNames && <span>Investor</span>}
          </Tab>
        </TabList>
        <TabPanel>
          <ContactPanel
            card={card}
            assetIdList={assetIdList}
          />
        </TabPanel>
        <TabPanel>
          <BookmarkPanel />
        </TabPanel>
        <TabPanel>
          <NotebookPanel />
        </TabPanel>
        <TabPanel>
          <ComparisionPanel />
        </TabPanel>
        <TabPanel>
          <SharePanel />
        </TabPanel>
        <TabPanel>
          <InvestorFeedPanel />
        </TabPanel>
      </Tabs>
    );
  }