import "./PaymentMethod.scss";
import React, {useState, useEffect} from "react";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";
import {InputBox} from "../../../components/reusableComponents/FormFields/FormFields";
import secure from "../../../assets/Payments/NortonSeal_PoweredbyDigiCert 1.svg";
import secure1 from "../../../assets/Payments/bbb trust logo 1.svg";
import secure2 from "../../../assets/Payments/d0796dc3-a983-4972-8810-639dc1951393 1.svg";
import secure3 from "../../../assets/Payments/gartner 1.svg";
import {addCard, getCard, updateCard, deleteCard} from "../../../utils/Axios";
import {useSelector} from "react-redux";
import {returnErrors} from "../../../store/reducers/error";
import {returnMessages} from "../../../store/reducers/message";

const PaymentMethod = () => {
  const [savedCards, setSavedCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const {userInformation, idToken} = useSelector((state) => state.auth);
  let user_id = userInformation?._id;

  const [currentCard, setCurrentCard] = useState({
    cardNumber: "",
    cardHolder: "",
    expiryMonth: "",
    expiryYear: "",
    cvv: "",
  });

  const monthOptions = Array.from({length: 12}, (_, i) => ({
    label: `${i + 1}`.padStart(2, "0"),
    value: `${i + 1}`.padStart(2, "0"),
  }));

  const yearOptions = Array.from({length: 11}, (_, i) => ({
    label: `${2025 + i}`,
    value: `${2025 + i}`,
  }));

  const formatCardNumber = (number) => {
    if (!number) return "**** **** **** ****";
    return `**** **** **** ${number.slice(-4)}`;
  };

  const fetchCards = async () => {
    try {
      const response = await getCard(idToken, user_id);

      if (response.success) {
        setSavedCards(response.data);
      } else {
        console.error("Error fetching cards:", response.message);
      }
    } catch (error) {
      console.error("Error fetching cards:", error.message);
    }
  };

  const handleAddOrUpdateCard = async () => {
    try {
      if (selectedCard) {
        const response = await updateCard(idToken, userInformation.id, selectedCard, currentCard.cardNumber, currentCard.cardHolder, currentCard.expiryMonth, currentCard.expiryYear, currentCard.cvv);
        if (response.success) {
          fetchCards();
          resetForm();
        } else {
          console.error("Error updating card:", response.message);
        }
      } else {
        const response = await addCard(idToken, userInformation.id, currentCard.cardNumber, currentCard.cardHolder, currentCard.expiryMonth, currentCard.expiryYear, currentCard.cvv);
        if (response.success) {
          fetchCards();
          resetForm();
        } else {
          console.error("Error adding card:", response.message);
        }
      }
    } catch (error) {
      console.error("Error adding/updating card:", error.message);
    }
  };

  const handleDeleteCard = async (cardId) => {
    try {
      const response = await deleteCard(idToken, cardId);
      if (response.success) {
        fetchCards();
        if (selectedCard === cardId) resetForm();
      } else {
        console.error("Error deleting card:", response.message);
      }
    } catch (error) {
      console.error("Error deleting card:", error.message);
    }
  };

  const handleInputChange = (e) => {
    setCurrentCard({...currentCard, [e.target.name]: e.target.value});
  };

  const handleSelectCard = (card) => {
    setSelectedCard(card._id);
    setCurrentCard({
      cardNumber: card.cardNumber,
      cardHolder: card.cardHolder,
      expiryMonth: card.expiryMonth,
      expiryYear: card.expiryYear,
      cvv: "",
    });
  };

  const resetForm = () => {
    setSelectedCard(null);
    setCurrentCard({
      cardNumber: "",
      cardHolder: "",
      expiryMonth: "",
      expiryYear: "",
      cvv: "",
    });
  };

  useEffect(() => {
    fetchCards();
  }, []);

  return (
    <div className="payment-method">
      <div className="payment-container">
        <div className="header">
          <h2>Payment Method</h2>
        </div>

        {savedCards.length > 0 ? (
          <div className="saved-cards">
            {savedCards?.map((card) => (
              <div
                key={card._id}
                className={`saved-card ${selectedCard === card._id ? "active" : ""}`}
                onClick={() => handleSelectCard(card)}
              >
                <div
                  className="close-icon-PaymentMethod"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteCard(card._id);
                  }}
                >
                  &times;
                </div>
                <div className="card-design">
                  <div className="card-top">
                    <div className="chip"></div>
                    <div className="card-type">VISA</div>
                  </div>
                  <div className="card-number">{formatCardNumber(card.cardNumber)}</div>
                  <div className="card-details">
                    <p className="card-holder">{card.cardHolder}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="no-cards">No saved cards. Add a card to get started.</p>
        )}

        <div className="form">
          <InputBox
            label="Card Number"
            type="text"
            value={currentCard.cardNumber}
            name="cardNumber"
            onChange={handleInputChange}
          />
          <InputBox
            label="CardHolder Name"
            type="text"
            value={currentCard.cardHolder}
            name="cardHolder"
            onChange={handleInputChange}
          />
          <div className="expiry-cvv">
            <div className="expiry-cvv">
              <DropDown
                label="Expiry Month"
                selectedData={monthOptions.find((option) => option.value === currentCard.expiryMonth)?.label || "Select Month"}
                data={monthOptions.map((option) => option.label)} 
                width="95px"
                setSelectedData={(selectedLabel) => {
                  const selectedOption = monthOptions.find((option) => option.label === selectedLabel);
                  setCurrentCard({...currentCard, expiryMonth: selectedOption.value});
                }}
              />
              <DropDown
                label="Expiry Year"
                selectedData={yearOptions.find((option) => option.value === currentCard.expiryYear)?.label || "Select Year"}
                data={yearOptions.map((option) => option.label)}
                width="95px"
                setSelectedData={(selectedLabel) => {
                  const selectedOption = yearOptions.find((option) => option.label === selectedLabel);
                  setCurrentCard({...currentCard, expiryYear: selectedOption.value});
                }}
              />
            </div>

            <InputBox
              noeye
              label="CVV"
              type="password"
              value={currentCard.cvv}
              name="cvv"
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="buttons">
          <button
            className="update-btn"
            onClick={handleAddOrUpdateCard}
          >
            {selectedCard ? "Update Card" : "Add Card"}
          </button>
        </div>

        <p className="secure-text">
          Payment secured by{" "}
          <span>
            <img
              src={secure}
              alt=""
            />
            <img
              src={secure1}
              alt=""
            />
            <img
              src={secure2}
              alt=""
            />
            <img
              src={secure3}
              alt=""
            />
          </span>
          .
        </p>
      </div>
    </div>
  );
};

export default PaymentMethod;
