import React, {useEffect, useRef, useState} from "react";
import moment from "moment";

export function ContactPanel({assetIdList}) {
  // const uniqueAddedBy = [...new Set(assetIdList.map((asset) => asset.addedBy))];
  return (
    <div className="tab_panel_wrapper">
      <div className="contact_container">
        <div className="row">
          <div className="leftSection">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.5625 18H3.43817C2.73178 18 2.21015 17.303 2.47658 16.662C3.71277 13.698 6.61693 12 9.99985 12C13.3838 12 16.288 13.698 17.5241 16.662C17.7906 17.303 17.2689 18 16.5625 18ZM5.91666 5.99998C5.91666 3.79398 7.74899 1.99998 9.99985 1.99998C12.2517 1.99998 14.083 3.79398 14.083 5.99998C14.083 8.20598 12.2517 9.99998 9.99985 9.99998C7.74899 9.99998 5.91666 8.20598 5.91666 5.99998ZM19.9557 17.636C19.2136 14.277 16.8923 11.798 13.837 10.673C15.456 9.39598 16.4002 7.33098 16.0532 5.06998C15.651 2.44698 13.4236 0.347977 10.7348 0.041977C7.02321 -0.381023 3.87507 2.44898 3.87507 5.99998C3.87507 7.88998 4.76929 9.57398 6.1637 10.673C3.10743 11.798 0.787164 14.277 0.044024 17.636C-0.225466 18.857 0.778997 20 2.05397 20H17.9457C19.2217 20 20.2262 18.857 19.9557 17.636Z"
                fill="white"
                fill-opacity="0.01"
              />
              <mask
                id="mask0_13481_26903"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.5625 18H3.43817C2.73178 18 2.21015 17.303 2.47658 16.662C3.71277 13.698 6.61693 12 9.99985 12C13.3838 12 16.288 13.698 17.5241 16.662C17.7906 17.303 17.2689 18 16.5625 18ZM5.91666 5.99998C5.91666 3.79398 7.74899 1.99998 9.99985 1.99998C12.2517 1.99998 14.083 3.79398 14.083 5.99998C14.083 8.20598 12.2517 9.99998 9.99985 9.99998C7.74899 9.99998 5.91666 8.20598 5.91666 5.99998ZM19.9557 17.636C19.2136 14.277 16.8923 11.798 13.837 10.673C15.456 9.39598 16.4002 7.33098 16.0532 5.06998C15.651 2.44698 13.4236 0.347977 10.7348 0.041977C7.02321 -0.381023 3.87507 2.44898 3.87507 5.99998C3.87507 7.88998 4.76929 9.57398 6.1637 10.673C3.10743 11.798 0.787164 14.277 0.044024 17.636C-0.225466 18.857 0.778997 20 2.05397 20H17.9457C19.2217 20 20.2262 18.857 19.9557 17.636Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_13481_26903)">
                <rect
                  x="-1"
                  y="-1"
                  width="22"
                  height="22"
                  fill="#9EA0A5"
                />
              </g>
            </svg>
            <p>{assetIdList?.addedBy}</p>
          </div>
          {/* <span>{assetIdList?.addedBy}</span> */}
        </div>
        {assetIdList?.date && (
          <div className="row">
            <div className="leftSection">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.9712 9H2.99681V5.971C2.99681 5.435 3.43111 5 3.96625 5H4.99361V7H6.99042V5H12.9776V7H14.9744V5H15.9728C16.524 5 16.9712 5.448 16.9712 6V9ZM16.9712 16C16.9712 16.55 16.522 17 15.9728 17H3.99521C3.44409 17 2.99681 16.552 2.99681 16V11H16.9712V16ZM1.0639 16.761C1.0639 17.865 2.02137 19 3.1236 19H17.098C18.2013 19 19 17.979 19 16.761C19 16.372 18.9681 6.36 18.9681 5.708C18.9681 3.626 18.6875 3 14.9744 3V1H12.9776V3H6.99042V1H4.99361V3H2.99681C1.89856 3 1 3.9 1 5L1.0639 16.761Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_13481_26978"
                  maskUnits="userSpaceOnUse"
                  x="1"
                  y="1"
                  width="18"
                  height="18"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.9712 9H2.99681V5.971C2.99681 5.435 3.43111 5 3.96625 5H4.99361V7H6.99042V5H12.9776V7H14.9744V5H15.9728C16.524 5 16.9712 5.448 16.9712 6V9ZM16.9712 16C16.9712 16.55 16.522 17 15.9728 17H3.99521C3.44409 17 2.99681 16.552 2.99681 16V11H16.9712V16ZM1.0639 16.761C1.0639 17.865 2.02137 19 3.1236 19H17.098C18.2013 19 19 17.979 19 16.761C19 16.372 18.9681 6.36 18.9681 5.708C18.9681 3.626 18.6875 3 14.9744 3V1H12.9776V3H6.99042V1H4.99361V3H2.99681C1.89856 3 1 3.9 1 5L1.0639 16.761Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_13481_26978)">
                  <rect
                    x="-1"
                    y="-1"
                    width="22"
                    height="22"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
              <p>{moment(assetIdList?.date).format("MMMM Do YYYY, h:mm:ss a")}</p>
            </div>
          </div>
        )}
        {assetIdList?.contactPhone && (
          <div className="row">
            <div className="leftSection">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14 3C14 2.448 13.552 2 13 2H7C6.448 2 6 2.448 6 3V17C6 17.552 6.448 18 7 18H13C13.552 18 14 17.552 14 17V3ZM16 2V18C16 19.105 15.105 20 14 20H6C4.895 20 4 19.105 4 18V2C4 0.895 4.895 0 6 0H14C15.105 0 16 0.895 16 2ZM11.021 15C11.021 15.552 10.573 16 10.021 16C9.469 16 9.021 15.552 9.021 15C9.021 14.448 9.469 14 10.021 14C10.573 14 11.021 14.448 11.021 15Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_13481_26917"
                  maskUnits="userSpaceOnUse"
                  x="4"
                  y="0"
                  width="12"
                  height="20"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14 3C14 2.448 13.552 2 13 2H7C6.448 2 6 2.448 6 3V17C6 17.552 6.448 18 7 18H13C13.552 18 14 17.552 14 17V3ZM16 2V18C16 19.105 15.105 20 14 20H6C4.895 20 4 19.105 4 18V2C4 0.895 4.895 0 6 0H14C15.105 0 16 0.895 16 2ZM11.021 15C11.021 15.552 10.573 16 10.021 16C9.469 16 9.021 15.552 9.021 15C9.021 14.448 9.469 14 10.021 14C10.573 14 11.021 14.448 11.021 15Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_13481_26917)">
                  <rect
                    x="-1"
                    y="-1"
                    width="22"
                    height="22"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
              {/* <div style={{maxWidth: "200px"}}>
              {card?.contact_info?.phone_numbers?.map((i) => (
                <p>{i}</p>
              ))}
            </div> */}
              <p>{assetIdList?.contactPhone}</p>
            </div>
          </div>
        )}
        {assetIdList?.addedByEmail && (
          <div className="row">
            <div className="leftSection">
              <svg
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="path-1-outside-1_13481_26930"
                  maskUnits="userSpaceOnUse"
                  x="0.666626"
                  y="1.33203"
                  width="21"
                  height="18"
                  fill="black"
                >
                  <rect
                    fill="white"
                    x="0.666626"
                    y="1.33203"
                    width="21"
                    height="18"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.6666 3.33203H4.33329C3.41663 3.33203 2.67496 4.08203 2.67496 4.9987L2.66663 14.9987C2.66663 15.9154 3.41663 16.6654 4.33329 16.6654H17.6666C18.5833 16.6654 19.3333 15.9154 19.3333 14.9987V4.9987C19.3333 4.08203 18.5833 3.33203 17.6666 3.33203ZM17.6666 6.66536L11 10.832L4.33329 6.66536V4.9987L11 9.16536L17.6666 4.9987V6.66536Z"
                  />
                </mask>
                <path
                  d="M2.67496 4.9987L4.67496 5.00036V4.9987H2.67496ZM2.66663 14.9987L0.666626 14.997V14.9987H2.66663ZM11 10.832L9.93996 12.528L11 13.1905L12.06 12.528L11 10.832ZM4.33329 6.66536H2.33329V7.77386L3.27329 8.36136L4.33329 6.66536ZM4.33329 4.9987L5.39329 3.3027L2.33329 1.3902V4.9987H4.33329ZM11 9.16536L9.93996 10.8614L11 11.5239L12.06 10.8614L11 9.16536ZM17.6666 4.9987H19.6666V1.3902L16.6066 3.3027L17.6666 4.9987ZM17.6666 1.33203H4.33329V5.33203H17.6666V1.33203ZM4.33329 1.33203C2.29903 1.33203 0.674959 2.99053 0.674959 4.9987H4.67496C4.67496 5.17353 4.53422 5.33203 4.33329 5.33203V1.33203ZM0.67496 4.99703L0.666627 14.997L4.66663 15.0004L4.67496 5.00036L0.67496 4.99703ZM0.666626 14.9987C0.666626 17.0199 2.31206 18.6654 4.33329 18.6654V14.6654C4.5212 14.6654 4.66663 14.8108 4.66663 14.9987H0.666626ZM4.33329 18.6654H17.6666V14.6654H4.33329V18.6654ZM17.6666 18.6654C19.6879 18.6654 21.3333 17.0199 21.3333 14.9987H17.3333C17.3333 14.8108 17.4787 14.6654 17.6666 14.6654V18.6654ZM21.3333 14.9987V4.9987H17.3333V14.9987H21.3333ZM21.3333 4.9987C21.3333 2.97746 19.6879 1.33203 17.6666 1.33203V5.33203C17.4787 5.33203 17.3333 5.1866 17.3333 4.9987H21.3333ZM16.6066 4.96937L9.93996 9.13604L12.06 12.528L18.7266 8.36136L16.6066 4.96937ZM12.06 9.13604L5.39329 4.96937L3.27329 8.36136L9.93996 12.528L12.06 9.13604ZM6.33329 6.66536V4.9987H2.33329V6.66536H6.33329ZM3.27329 6.69469L9.93996 10.8614L12.06 7.46937L5.39329 3.3027L3.27329 6.69469ZM12.06 10.8614L18.7266 6.69469L16.6066 3.3027L9.93996 7.46937L12.06 10.8614ZM15.6666 4.9987V6.66536H19.6666V4.9987H15.6666Z"
                  fill="#9EA0A5"
                  mask="url(#path-1-outside-1_13481_26930)"
                />
              </svg>
              <p>{assetIdList?.addedByEmail}</p>
            </div>
          </div>
        )}
        {assetIdList?.contactWebsite && (
          <div className="row">
            <div className="leftSection">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.2387 1.76128C15.8904 -0.587093 12.0827 -0.587093 9.7344 1.76128L7.16555 4.33016L8.58278 5.7474L11.1516 3.17852C12.7182 1.61194 15.254 1.61194 16.8215 3.17852C18.3841 4.7421 18.3841 7.28491 16.8215 8.84849L14.2527 11.4174L15.6699 12.8346L18.2387 10.2657C20.5871 7.91736 20.5871 4.10965 18.2387 1.76128ZM8.84838 16.8207C8.09166 17.5785 7.08437 17.9954 6.01393 17.9954C4.9435 17.9954 3.9362 17.5785 3.17948 16.8207C1.61593 15.2582 1.61593 12.7143 3.17948 11.1518L5.74833 8.58288L4.3301 7.16464L1.76126 9.73352C-0.587086 12.0819 -0.587086 15.8896 1.76126 18.239C2.93593 19.4127 4.47443 20 6.01393 20C7.55343 20 9.09193 19.4127 10.2666 18.239L12.8355 15.6701L11.4172 14.2519L8.84838 16.8207ZM12.2631 6.31971L13.6804 7.73695L7.72182 13.6956L6.30459 12.2783L12.2631 6.31971Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_13481_26959"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.2387 1.76128C15.8904 -0.587093 12.0827 -0.587093 9.7344 1.76128L7.16555 4.33016L8.58278 5.7474L11.1516 3.17852C12.7182 1.61194 15.254 1.61194 16.8215 3.17852C18.3841 4.7421 18.3841 7.28491 16.8215 8.84849L14.2527 11.4174L15.6699 12.8346L18.2387 10.2657C20.5871 7.91736 20.5871 4.10965 18.2387 1.76128ZM8.84838 16.8207C8.09166 17.5785 7.08437 17.9954 6.01393 17.9954C4.9435 17.9954 3.9362 17.5785 3.17948 16.8207C1.61593 15.2582 1.61593 12.7143 3.17948 11.1518L5.74833 8.58288L4.3301 7.16464L1.76126 9.73352C-0.587086 12.0819 -0.587086 15.8896 1.76126 18.239C2.93593 19.4127 4.47443 20 6.01393 20C7.55343 20 9.09193 19.4127 10.2666 18.239L12.8355 15.6701L11.4172 14.2519L8.84838 16.8207ZM12.2631 6.31971L13.6804 7.73695L7.72182 13.6956L6.30459 12.2783L12.2631 6.31971Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_13481_26959)">
                  <rect
                    x="-1"
                    y="-1"
                    width="22"
                    height="22"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
              <p>{assetIdList?.contactWebsite}</p>
            </div>
            <a
              href="https://www.google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.8333 15.8333H4.16667V4.16667H10V2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10H15.8333V15.8333ZM11.6667 2.5V4.16667H14.6583L6.46667 12.3583L7.64166 13.5333L15.8333 5.34167V8.33333H17.5V2.5H11.6667Z"
                  fill="#9EA0A5"
                />
              </svg>
            </a>
          </div>
        )}
        {assetIdList?.contactPhone && (
          <div className="row">
            <div className="leftSection">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16 15C16 15.552 15.552 16 15 16H5C4.448 16 4 15.552 4 15C4 14.448 4.448 14 5 14H15C15.552 14 16 14.448 16 15ZM5 10H15C15.552 10 16 10.448 16 11C16 11.552 15.552 12 15 12H5C4.448 12 4 11.552 4 11C4 10.448 4.448 10 5 10ZM18 17C18 17.552 17.552 18 17 18H3C2.448 18 2 17.552 2 17V3C2 2.448 2.448 2 3 2H12V6C12 7.104 12.895 8 14 8H18V17ZM19.707 5.707L14.293 0.293C14.105 0.105 13.851 0 13.586 0H2C0.895 0 0 0.895 0 2V18C0 19.104 0.895 20 2 20H18C19.105 20 20 19.104 20 18V6.414C20 6.149 19.895 5.895 19.707 5.707Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_13481_26938"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16 15C16 15.552 15.552 16 15 16H5C4.448 16 4 15.552 4 15C4 14.448 4.448 14 5 14H15C15.552 14 16 14.448 16 15ZM5 10H15C15.552 10 16 10.448 16 11C16 11.552 15.552 12 15 12H5C4.448 12 4 11.552 4 11C4 10.448 4.448 10 5 10ZM18 17C18 17.552 17.552 18 17 18H3C2.448 18 2 17.552 2 17V3C2 2.448 2.448 2 3 2H12V6C12 7.104 12.895 8 14 8H18V17ZM19.707 5.707L14.293 0.293C14.105 0.105 13.851 0 13.586 0H2C0.895 0 0 0.895 0 2V18C0 19.104 0.895 20 2 20H18C19.105 20 20 19.104 20 18V6.414C20 6.149 19.895 5.895 19.707 5.707Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_13481_26938)">
                  <rect
                    x="-1"
                    y="-1"
                    width="22"
                    height="22"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
              {/* <p>{card?.contact_info?.brochure}</p> */}files
            </div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.8333 15.8333H4.16667V4.16667H10V2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10H15.8333V15.8333ZM11.6667 2.5V4.16667H14.6583L6.46667 12.3583L7.64166 13.5333L15.8333 5.34167V8.33333H17.5V2.5H11.6667Z"
                fill="#9EA0A5"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}
