import React, {useState, useEffect} from "react";
import "./InviteColleague.scss";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Box,
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Chip,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useSelector} from "react-redux";
import {dropdownOptions} from "./reasons";
import defaultimage from "../../../assets/portfolio/images/profile.png";
import {CircularProgress} from "@mui/material";
import {useDispatch} from "react-redux";
import {returnErrors} from "../../../store/reducers/error";
import {returnWarningMessages} from "../../../store/reducers/Warning";
import axios from "axios";
import {Fade} from "@mui/material";
import {returnMessages} from "../../../store/reducers/message";
import Endpoint from "../../../utils/Endpoint";
import useAuthConfig from "../../../utils/Config";

const InviteacolleaguetoAsset = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [open, setOpen] = useState(true);
  const [updatedAssetsData, setUpdatedAssetsData] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);
  const selectedProperty = useSelector((state) => state?.portfolio?.selectedProperty);
  const assets = useSelector((state) => state?.AdminGivenAccess?.assets) || [];
  const dispatch = useDispatch();
  const config = useAuthConfig();
  const handleClose = () => setOpen(false);
  const [selectedPermissions, setSelectedPermissions] = useState({
    "Property Data Overview": [],
    chatAccess: false,
  });

  const handlePermissionChange = (section, value) => {
    setSelectedPermissions((prev) => {
      const updatedSection = prev[section] || [];
      if (updatedSection.includes(value)) {
        return prev;
      }

      return {
        ...prev,
        [section]: [...updatedSection, value],
      };
    });
  };

  useEffect(() => {
    if (!selectedProperty || assets.length === 0) return;
    const filteredAssets = assets.filter((asset) => asset.assetIds.some((a) => a._id === selectedProperty?._id));
    setUpdatedAssetsData(filteredAssets);
  }, [assets, selectedProperty]);

  const handleSendAccess = async () => {
    if (!email || !name || !surname || Object.keys(selectedPermissions["Property Data Overview"]).length === 0) {
      dispatch(returnErrors("First Name, Last Name, Email, and Permissions are required!", 400));
      return;
    }

    setIsLoading(true);
    try {
      const requestBody = {
        userEmail: email,
        FirstName: name,
        LastName: surname,
        assetIds: [selectedProperty?._id],
        selectedPermissions,
      };

      const response = await axios.post(Endpoint.InviteaEmployeetoAsset, requestBody, config);

      if (response?.data?.success && response?.data?.message === "User already exists") {
        const userId = response?.data?.data?.userId;
        dispatch(returnWarningMessages(`User already exists. User ID: ${userId}`, 300, "Warning"));
      } else if (response?.data?.success) {
        dispatch(returnMessages(`Invitation mail sent successfully to ${email}`, 200));
      }
      handleClose();
    } catch (error) {
      if (error.response) {
        dispatch(returnErrors(error?.response?.data?.message || "Error assigning assets", 400));
      } else {
        dispatch(returnErrors("Server error", 500));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeselectPermission = (section, value) => {
    setSelectedPermissions((prev) => {
      const updatedSection = prev[section]?.filter((item) => item !== value) || [];
      return {
        ...prev,
        [section]: updatedSection,
      };
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{"& .MuiDialog-paper": {width: "540px", maxWidth: "540px"}}}
      className="colleagueInvitationContainer"
    >
      <DialogTitle className="invitationHeader">
        Invite a colleague to Asset
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{position: "absolute", right: 8, top: 8}}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        className="invitationContent"
        dividers
      >
        <Box
          mb={3}
          className="invitationSelectorBox"
        >
          <Typography variant="subtitle1">Target Asset</Typography>
          <Box
            display="flex"
            alignItems="center"
            className="invitationSelector"
          >
            <Avatar
              src={selectedProperty?.assetsImages[0] || "https://via.placeholder.com/40"}
              alt="Asset"
              className="avatarPic"
            />
            <Typography
              ml={2}
              flexGrow={1}
            >
              {selectedProperty?.assetName || "Unnamed Asset"}
            </Typography>
          </Box>
        </Box>

        {/* take name for user */}

        <Box
          mb={3}
          className="addPeopleSection"
        >
          <Typography variant="subtitle1">Add People</Typography>
          <Box
            display="flex"
            alignItems="center"
            className="addPeopleSelector"
          >
            <div className="selectorBox">
              <TextField
                label="First Name"
                variant="outlined"
                size="small"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                sx={{mr: 2}}
                className="addPeopleInput"
              />
              <TextField
                label="Last Name"
                variant="outlined"
                size="small"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                fullWidth
                sx={{mr: 2}}
                className="addPeopleInput"
              />
            </div>
          </Box>
        </Box>

        {/* take name for user */}
        <Box
          mb={3}
          className="addPeopleSection"
        >
          <Typography variant="subtitle1">Add Email</Typography>
          <Box
            display="flex"
            alignItems="center"
            className="addPeopleSelector"
          >
            <div className="selectorBox">
              <TextField
                label="Name or Email Address"
                variant="outlined"
                size="small"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                sx={{mr: 2}}
                className="addPeopleInput"
              />

              <Select
                value=""
                onChange={(e) => handlePermissionChange("Property Data Overview", e.target.value)}
                displayEmpty
                variant="outlined"
                size="small"
                sx={{mr: 2}}
                className="permissionSelector"
              >
                <MenuItem
                  disabled
                  value=""
                >
                  <em>Permissions</em>
                </MenuItem>
                {dropdownOptions["Property Data Overview"]?.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <Button
              variant="contained"
              color="primary"
              onClick={handleSendAccess}
              disabled={IsLoading}
              startIcon={
                IsLoading && (
                  <CircularProgress
                    size={20}
                    color="inherit"
                  />
                )
              }
            >
              {IsLoading ? "" : "Invite"}
            </Button>
          </Box>
        </Box>

        <Typography variant="subtitle1">Selected Permissions</Typography>

        {selectedPermissions["Property Data Overview"]?.length > 0 && (
          <Fade
            in={true}
            timeout={500}
          >
            <Box mb={3}>
              <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                {selectedPermissions["Property Data Overview"].map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    onDelete={() => handleDeselectPermission("Property Data Overview", value)}
                  />
                ))}
              </Box>
            </Box>
          </Fade>
        )}

        <p className="inviteCaption">After people are notified by email, they will need to confirm the invitation.</p>
        <Box className="inviteeSection">
          <List>
            {updatedAssetsData?.map((asset, index) => (
              <React.Fragment key={index}>
                <ListItem
                  alignItems="flex-start"
                  className="employeeDetailBox"
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={asset?.adminId?.name}
                      src={asset?.adminId?.profile_image || defaultimage}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    className="employeeDetail"
                    primary={
                      <Box
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Typography variant="subtitle2">
                          {asset?.userId?.name} {asset?.userId?.surname}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        >
                          {asset?.userId?.status || "Permissions"}
                          {/* <DropdownIcon style={{marginLeft: "10px"}} /> */}
                        </Typography>
                      </Box>
                    }
                    secondary={
                      <Box mt={1}>
                        {asset?.assetPermissions?.map((perm, permIndex) => (
                          <Box
                            key={permIndex}
                            mt={1}
                          >
                            <p>Section: {perm?.section}</p>
                            <div className="employeeTags">
                              {Object?.keys(perm?.permissions).map((key, keyIndex) => (
                                <Chip
                                  key={keyIndex}
                                  label={key}
                                  size="small"
                                  sx={{mr: 1, mt: 0.5}}
                                />
                              ))}
                            </div>
                          </Box>
                        ))}
                      </Box>
                    }
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          color="secondary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteacolleaguetoAsset;
