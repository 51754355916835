import React from "react";
import "./company.scss";
import Profile from "../../../assets/Image/profile-pic.png";
import {ReactComponent as Assetinformation} from "../../../assets/dealsroom/svg/assetinformation.svg";
import {useState} from "react";
import SvgColorChanger from "../../DealsRoom/SvgColorChanger";
import housebig from "../../../assets/Image/real-estate-big.png";
import camera from "../../../assets/svg/camera.svg";
import Delete from "../../../assets/svg/delete.svg";
import BasicProfile from "./BasicProfile";
import Organisation from "./Organisation";

function Company() {
  const [isClicked, setIsClicked] = useState(1);

  const handleMenuClicked = (data) => {
    setIsClicked(data);

    // if (data === 12) {
    //   setLoanReq(2);
    // }
  };
  return (
    <div className="admin_settings">
      <div className="settings_left">
        <div className="settings_left_upper">
          <div className="settings_left_upper_img_wrapper">
            <div>
              <img
                src={housebig}
                alt=""
              />
            </div>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <div>
                <img
                  src={camera}
                  alt="image"
                />
              </div>
              <img
                src={Delete}
                alt="image"
              />
            </div>
          </div>
          <div style={{padding: "20px"}}>
            <p>REAL Invest Group</p>
            <span>Baltimore, MD, United States</span>
            <br></br>
            <span>+1-000-000-0000</span>
            <br></br>
            <span>info@real-i.com</span>
          </div>
        </div>
        <div className="settings_left_lower">
          <span>Company Details</span>
          <div>
            <LeftMenu
              icon={<Assetinformation />}
              name={"Basic Profile"}
              id={1}
              state={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
            <LeftMenu
              icon={<Assetinformation />}
              name={"Organization Structure"}
              id={2}
              state={isClicked}
              handleMenuClicked={handleMenuClicked}
            />
          </div>
        </div>
      </div>
      <div className="settings_right">
        {isClicked === 1 && <BasicProfile />}
        {isClicked === 2 && <Organisation />}
      </div>
    </div>
  );
}

export default Company;

const LeftMenu = ({icon, name, id, state, handleMenuClicked}) => {
  return (
    <div
      className={`deals-left-menu ${id === state && name != "Integrations" && "admin_menu_active"}`}
      onClick={() => {
        handleMenuClicked(id);
      }}
    >
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline", width: "90%"}}>
        <span
          style={{display: "flex", gap: "10px"}}
          className={`title_menu ${id === state && "title_menu_active"}`}
        >
          {name}
        </span>
      </div>
    </div>
  );
};
