import React, {useState} from "react";
import DealCountChart from "./DealCountChart";
import BudgetingBarChart from "./BudgetingBarChart";
import {Checkbox, Progress, Table} from "antd";
import crossIcon from "../../../src/assets/svg/cross.svg";
import { useSelector } from "react-redux";

const AnalyticsDetails = ({setAnalyticsModal, deals}) => {
  const [navSlider, setNavSlider] = useState(1);
  

  const checklistData = useSelector((state) => state.checklist.checklist || []);

  const onChange = (checkedValues) => {
  
  };

  const getProgressData = (checklistName) => {
    const foundChecklist = checklistData.find((item) => item.name === checklistName);

    if (!foundChecklist) {
      return {completed: 0, total: 0, color: "#000"};
    }
    const totalTasks = foundChecklist.tasks.length;
    const completedTasks = foundChecklist.tasks.filter((task) => new Date(task.updatedDate) <= new Date()).length;

    return {completed: completedTasks, total: totalTasks, color: "#4285F4"};
  };

  const plainOptions = ["Discovery", "Due Diligence", "Pre Closing", "Post Closing"];

  const columns = [
    {
      title: "Deal Asset",
      dataIndex: "dealAsset",
      key: "dealAsset",
      render: (dealAsset) => (
        <div className="asset_info_container">
          <img
            src={dealAsset.image}
            alt={dealAsset.name}
            style={{width: 50, height: 50,borderRadius:5}}
          />
          <div className="asset_info">
            <div className="asset_name">{dealAsset.name}</div>
            <div
              className="asset_addrs"
              style={{color: "#888"}}
            >
              {dealAsset.address}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Asset Type",
      dataIndex: "assetType",
      key: "assetType",
    },
    {
      title: "Deal Progress",
      dataIndex: "dealProgress",
      key: "dealProgress",
      render: (progress) => (
        <div className="deal_progress_box">
          {Object.keys(progress).map((key) => (
            <div
              key={key}
              style={{color: progress[key].color}}
            >
              {`${progress[key].completed}/${progress[key].total}`}
              <Progress
                showInfo={false}
                percent={(progress[key].completed / progress[key].total) * 100}
                strokeColor={progress[key].color}
              />
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Deal Type",
      dataIndex: "dealType",
      key: "dealType",
    },
    {
      title: "Purchase Price",
      dataIndex: "purchasePrice",
      key: "purchasePrice",
    },
    {
      title: "Cap Rate",
      dataIndex: "capRate",
      key: "capRate",
    },
    {
      title: "Closing Date",
      dataIndex: "closingDate",
      key: "closingDate",
    },
  ];

 
  const data = deals?.map((deal) => ({
    dealAsset: {
      name: deal?.assetName,
      address: deal?.assetsAddress,
      // image: deal?.assetsImages[0], 
    },
    assetType: deal?.property_type,
    dealProgress: {
      discovery: {
        completed: 21,
        total: 30,
        color: "#4285F4", 
      },
      dueDiligence: {
        completed: 14,
        total: 29,
        color: "#34A853", 
      },
      preClosing: {
        completed: 5,
        total: 44,
        color: "#FBBC05",
      },
      postClosing: {
        completed: 9,
        total: 49,
        color: "#EA4335",
      },
    },
    dealType: "Acquisition",
    purchasePrice: "$225,124,100",
    capRate: "4.56%",
    closingDate: "15 Nov. 2019",
  }));

  const handleCloseBtn = () => {
    setAnalyticsModal(false);
  };

  return (
    <div className="analyticsDetails_container">
      <div className="analyticsDetails_wrapper">
        <div className="analyticsDetails_header">
          <h2>Analytics details</h2>
          <button onClick={handleCloseBtn}>
            <img
              src={crossIcon}
              alt="crossIcon"
            />
          </button>
        </div>
        <div className="dealReport_wrapper">
          <div className="dealReport_box">
            <div className="dealReport_header">
              <h2>Deal Report</h2>
              <div className="nav_box">
                <div className="deals-c-upper-left">
                  <ul>
                    <li
                      onClick={() => {
                        setNavSlider(1);
                      }}
                      className={navSlider === 1 ? "active" : ""}
                    >
                      Acquisition
                    </li>
                    <li
                      className={navSlider === 2 ? "active" : ""}
                      onClick={() => {
                        setNavSlider(2);
                      }}
                    >
                      Disposition
                    </li>
                    <li
                      className={navSlider === 3 ? "active" : ""}
                      onClick={() => {
                        setNavSlider(3);
                      }}
                    >
                      All types
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="dealReport_graph_wrapper">
              <div className="deal_count_box">
                <DealCountChart deals={deals} />
              </div>
              <div className="budgetGraph_box">
                <BudgetingBarChart />
              </div>
              <div className="dealStatus_box">
                <h4>Deal Status</h4>
                <Checkbox.Group
                  options={plainOptions}
                  defaultValue={["Discovery"]}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="dealReport_table_box">
              <Table
                columns={columns}
                dataSource={data}
                size="small"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsDetails;
