export const keywords = [
  "Actionable Recommendations:",
  "### Analysis of Real Estate Data and Recommendations",
  "### Key Areas of Analysis:",
  "Insights:",
  "Summary:",
  "Analysis:",
  "Key Area Analysis:",
  "#### Key Areas of Analysis:",
  "Recommendations",
  "#### Recommendations:",
  "### Recommendations:",
  "# Recommendations:",
  "#### Key Area Analysis:",
];

