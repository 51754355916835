import React, {useState} from "react";
import "./SearchResults.scss";
import {useDispatch, useSelector} from "react-redux";
import {setUserAssetData} from "../../../store/reducers/userDetailsSlice";
import AssetInformation2 from "../../DealsRoom/AssetInformation2";
import {Modal} from "@mui/material";
import {saveHistory} from "../../../utils/Axios";

const SearchResults = ({isVisible, isLoading, results, onClose}) => {
  const dispatch = useDispatch();
  const {idToken} = useSelector((state) => state.auth);
  const {userInformation} = useSelector((state) => state.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedData = useSelector((state) => state.userInfo.userAssetData);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleAssetSelect = async (result) => {
    dispatch(setUserAssetData(result));
    setIsModalOpen(true);
    await saveHistory(userInformation?._id, result.assetId, idToken);
  };

  return (
    <div className={`search-results-container ${isVisible ? "open" : ""}`}>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
      >
        <AssetInformation2 selectedData={selectedData} />
      </Modal>
      <div className="search-results-header">
        <h2>Search Results</h2>
        <button
          className="SearchResults-close-button"
          onClick={onClose}
        >
          ×
        </button>
      </div>
      <div className="search-results-content">
        {isLoading ? (
          <p>Loading...</p>
        ) : results.length > 0 ? (
          <ul className="search-results-list">
            {results.map((result, index) => (
              <li
                key={index}
                className="search-result-item"
                onClick={() => handleAssetSelect(result)}
              >
                <div className="result-image">
                  {result.assetsImages?.[0] ? (
                    <img
                      src={result.assetsImages[0]}
                      alt={result.assetName}
                      loading="lazy"
                    />
                  ) : (
                    <div className="no-image">No Image Available</div>
                  )}
                </div>
                <div className="result-details">
                  <p>
                    <strong>Asset Name:</strong> {result.assetName}
                  </p>
                  <p>
                    <strong>Address:</strong> {result.assetsAddress}
                  </p>
                  <p>
                    <strong>Type:</strong> {result.assetType}
                  </p>
                  <p>
                    <strong>Value:</strong> ${result.assetValue.toLocaleString()}
                  </p>
                  <p>
                    <strong>Square Feet:</strong> {result.assetSquareFeet} sq. ft.
                  </p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No results found.</p>
        )}
      </div>
    </div>
  );
};

export default SearchResults;
