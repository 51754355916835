import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import Endpoint from "../../utils/Endpoint";

export const getDeals = createAsyncThunk("deals/fetchDealsData", async (idToken, thunkAPI) => {
  try {
    const config = {
      headers: {
        "auth-token": idToken,
      },
    };
    const response = await axios.get(Endpoint.getalldeals, config);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

const dealsSlice = createSlice({
  name: "deals",
  initialState: {
    dealsData: [],
    loading: false,
    error: null,
    selectedDeal: null,
  },
  reducers: {
    selectDeal: (state, action) => {
      state.selectedDeal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeals.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDeals.fulfilled, (state, action) => {
        state.loading = false;
        state.dealsData = action.payload;
      })
      .addCase(getDeals.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {selectDeal} = dealsSlice.actions;

export const selectDealsData = (state) => state.deals.dealsData;
export const selectLoading = (state) => state.deals.loading;
export const selectError = (state) => state.deals.error;
export const selectedDealsByUser = (state) => state.deals.selectedDeal;

export default dealsSlice.reducer;