import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./DealFilter.scss";
import syndicatebtn from "../../assets/svg/syndicatebtn.svg";
import reqloanbtn from "../../assets/svg/reqloanbtn.svg";
import importbtn from "../../assets/svg/import.svg";
import bulk from "../../assets/svg/bulk.svg";
import ImportSingleDealPopup from "./DealsUploadPopup/SingleDealsUpload";
import BulkDealsUploadPopup from "./DealsUploadPopup/BulkDealsUploadPopup";

const DealFilter = () => {
  const navigate = useNavigate();
  const [isSingleDealPopupOpen, setIsSingleDealPopupOpen] = useState(false);
  const [isBulkDealPopupOpen, setIsBulkDealPopupOpen] = useState(false);

  const { userInformation } = useSelector((state) => state.auth);

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className="add-deal-filter-wrapper-main">
      <div
        role="button"
        tabIndex={0}
        onClick={() => handleNavigate(`/${userInformation._id}/dealsroom`)}
        onKeyDown={(e) => e.key === "Enter" && handleNavigate(`/${userInformation._id}/dealsroom`)}
      >
        <img src={syndicatebtn} alt="Start a Syndicate" loading="lazy" />
        <span>Start a Syndicate</span>
      </div>
      <div
        role="button"
        tabIndex={0}
        onClick={() => handleNavigate("/newloanreq")}
        onKeyDown={(e) => e.key === "Enter" && handleNavigate("/newloanreq")}
      >
        <img src={reqloanbtn} alt="Request a Loan" loading="lazy" />
        <span>Request a Loan</span>
      </div>
      <div className="divider"></div>
      <div
        role="button"
        tabIndex={0}
        onClick={() => setIsSingleDealPopupOpen(true)}
        onKeyDown={(e) => e.key === "Enter" && setIsSingleDealPopupOpen(true)}
      >
        <img src={importbtn} alt="Import a Single Deal" loading="lazy" />
        <span>Import a Single Deal</span>
      </div>
      <div
        role="button"
        tabIndex={0}
        onClick={() => setIsBulkDealPopupOpen(true)}
        onKeyDown={(e) => e.key === "Enter" && setIsBulkDealPopupOpen(true)}
      >
        <img src={bulk} alt="Bulk Import (CSV, etc.)" loading="lazy" />
        <span>Bulk Import (CSV, etc.)</span>
      </div>

      <ImportSingleDealPopup
        open={isSingleDealPopupOpen}
        onClose={() => setIsSingleDealPopupOpen(false)}

      />
      <BulkDealsUploadPopup
        open={isBulkDealPopupOpen}
        onClose={() => setIsBulkDealPopupOpen(false)}
  
      />
    </div>
  );
};

export default DealFilter;
