import React, {useEffect, useRef, useState} from "react";
import ProgressBar from "../../../components/reusableComponents/ProgressBar/ProgressBar";
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import moment from "moment";
import {setAssetId, setAssetName, setChatId, setUserAssetData} from "../../../store/reducers/userDetailsSlice";
import {FormatUSD, daysAgo, formatUSD} from "../../../utils/helpers";
import {PhoneSvg, BookmarkSvg, NotebookSvg, CompareSvg, ShareSvg, PiechartSvg, SliderIcon} from "../Svgimages/svgallimnages";
import {PropertyCardMini} from "./PropertyCardMini";
import {Svgimage1, Svgimage2, Svgimage3, Svgimage4, Svgimage5, Svgimage6} from "./svgimages/Allsvgimages";
import {Progress} from "antd";

export function PropertyCard({acquisitionActive, setAcquisitionActive, syndicateData, card, dispatch, handleSelectCrowdfunding}) {
  const [isOpen, setIsOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = new Date(syndicateData?.endDate) - now;

    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, [syndicateData?.endDate]);

  const {days, hours, minutes, seconds} = timeLeft;

  return (
    <div className="property_card_wrapper">
      <div className="property_card">
        <div className="property_card-T">
          <div className="photo_wrapper">
            <div className="tag">
              <Svgimage1 />
              <p>{syndicateData?.asset_id?.assetType}</p>
            </div>
            {/* <ThumbsSlider className="crownfunding_slider" /> */}
            <img
              className="photo_card"
              style={{marginTop: "10px", objectFit: "cover"}}
              src={syndicateData?.assetDetails?.assetsImages[0]}
              alt="img"
            />
          </div>
          <div className="text_wrapper">
            <h3>{syndicateData?.assetDetails?.assetName}</h3>
            <div style={{display: "flex"}}>
              <Svgimage2 />
              <p>{syndicateData?.asset_id?.assetsAddress}</p>
            </div>
          </div>
        </div>

        {/* Timer */}
        <div className="property_card-M">
          <div className="timer">
            <div className="left">
              <p>Offer Available for:</p>
            </div>
            <div className="right">
              <div className="time">
                <p>{String(days).padStart(2, "0")}</p>
                <span>days</span>
              </div>
              <div className="time">
                <p>{String(hours).padStart(2, "0")}</p>
                <span>Hours</span>
              </div>
              <div className="time">
                <p>{String(minutes).padStart(2, "0")}</p>
                <span>Minutes</span>
              </div>
              <div className="time">
                <p>{String(seconds).padStart(2, "0")}</p>
                <span>Seconds</span>
              </div>
            </div>
          </div>

          {showMore && (
            <div className="boxes_wrapper">
              <div className="boxes1">
                <div className="box">
                  Yield <span>{syndicateData?.general?.Yield}</span>
                </div>
                <div className="box">
                  LTV <span>{syndicateData?.general?.LTV}</span>
                </div>
                <div className="box">
                  Hold Period <span>{syndicateData?.general?.Hold_Period}</span>
                </div>
                <div className="box">
                  Amount <span>{formatUSD(syndicateData?.general?.Amount)}</span>
                </div>
              </div>
              <div className="boxes2">
                <div className="box">
                  Minimum Investment <span>{formatUSD(syndicateData?.general?.Minimum_Investment)}</span>
                </div>
                <div className="box">
                  redemption period <span>{syndicateData?.general?.Redemption_Period}</span>
                </div>
                <div className="box">
                  Strategy <span>{syndicateData?.general?.Strategy}</span>
                </div>
              </div>
              <div className="boxes3">
                <div className="box">
                  Area, SF <span>{syndicateData?.general?.Area}</span> Duplex
                </div>
                <div className="box">
                  Appraisal Value <span>{formatUSD(syndicateData?.general?.Appraisal_Value)}</span> from {moment(card?.property?.appraisal_date).format("YYYY-MM-DD")}
                </div>
              </div>
            </div>
          )}

          <div className="progressbar_wrapper">
            <div className="header">
              <p>Raised</p> <span>{card?.raised}</span>
            </div>
            <Progress
              percent={Math.min(Math.max(parseFloat(card?.raised) || 0, 0), 100)}
              showInfo={false}
              strokeColor="#7d55c7"
              strokeWidth={8}
            />
          </div>
        </div>
        <div className="property_card-B">
          <div
            className="buttons"
            onClick={() => setIsOpen(true)}
          >
            <div className="button">
              <PhoneSvg /> <span>Contacts</span>
            </div>
            <div className="button">
              {" "}
              <BookmarkSvg /> <span>Save</span>
            </div>
            <div className="button">
              {" "}
              <NotebookSvg />
              <span>Add Note</span>
            </div>
            <div className="button">
              {" "}
              <CompareSvg /> <span>Compare</span>
            </div>
            <div className="button">
              {" "}
              <ShareSvg /> <span>Share</span>
            </div>
          </div>
          <div className="stats">
            <div className="date">
              <Svgimage3 />
              date:{" "}
              {new Date(syndicateData?.assetDetails?.date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </div>
            <div className="views">
              <Svgimage4 />
              {card?.property?.total_views?.total} (<span>{syndicateData?.assetDetails?.views}</span>)
            </div>
            <div className="active">
              <div className="dotgreen"></div>
              Active
            </div>
          </div>
        </div>
      </div>
      <div className="btn_options">
        {/* <button onClick={handleDetailsClick}>{showMore === true ? "Collapse" : "Expand"}</button> */}
        <Link
          to={{
            pathname: "/crowdfunding",
            state: {
              acquisitionActive: acquisitionActive,
              setAcquisitionActive: setAcquisitionActive,
            },
          }}
          onClick={() => {
            dispatch(setChatId(syndicateData?.assetDetails?.addedByEmail));
            dispatch(setAssetName(syndicateData?.assetDetails?.assetName));
            dispatch(setAssetId(syndicateData?.assetDetails?._id));
            handleSelectCrowdfunding(syndicateData);
          }}
        >
          <div className="view_details_button">
            <Svgimage5 />
            <p>View details</p>
          </div>
        </Link>
      </div>
      {isOpen && (
        <div className="floatCard_wrapper">
          <PropertyCardMini card={card} />
          <button onClick={() => setIsOpen(false)}>
            <Svgimage6 />
          </button>
        </div>
      )}
    </div>
  );
}
