export const dropdownOptions = {
  "Property Data Overview": [
    {label: "Financial KPI", value: "Financial KPI"},
    {label: "Operating Income & Expenses", value: "OperatingIncomeExpenses"},
    {label: "Market Analysis", value: "Market Analysis"},
    {label: "Utilities", value: "Utilities"},
    {label: "Maintenance", value: "Maintenance"},
    {label: "Asset Name", value: "assetName"},
    {label: "Asset Description", value: "assetDescription"},
  ],
};
