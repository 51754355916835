import React, {useState} from "react";


export const DeleteBox = () => {
  return (
    <div className="deletebox">
      <div className="basic_header">
        <p>Delete Caution!</p>
      </div>

      <div className="deletebox_middle">
        <p>This action cannot be undone. If you are not sure whether to delete or not, you can deactivate the account and then delete it later.</p>
      </div>

      <div className="btns">
        <div>
          <button className="btn_cancel">Cancel</button>
        </div>
        <button className="btn_delete">Delete</button>
      </div>
    </div>
  );
};
