import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {fetchPortfoliodata} from "../../utils/Axios";
import Endpoint from "../../utils/Endpoint";

const initialState = {
  AssectDataOwnerOperator: [],
  selectedProperty: null,
  loading: false,
  error: null,
  assetPermissions: [],
  selectedPortfolios: [],
};

export const fetchPortfolio = createAsyncThunk(
  "portfolio/fetchPortfolio",
  async (email, { getState, rejectWithValue }) => {
    const { auth } = getState();
    const idToken = auth.idToken;
    try {
      const data = await fetchPortfoliodata(email, idToken);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const updateProperty = createAsyncThunk("portfolio/updateProperty", async ({propertyId, updatedData, selectedItem}, {getState, rejectWithValue}) => {
  const {auth} = getState();
  const idToken = auth.idToken;
  try {
    const data = await updateasset(propertyId, updatedData, idToken, selectedItem);
    return {propertyId, updatedData: data};
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

let updateasset = async (propertyId, updatedData, idToken, selectedItem) => {
  const assectid = selectedItem?._id;

  const url = `${Endpoint.updateAssetcard}/${assectid}`;
  try {
    const response = await axios.patch(url, updatedData, {
      headers: {
        "auth-token": idToken,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error updating property: ${error.message}`);
  }
};

const portfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    selectProperty: (state, action) => {
      state.selectedProperty = action.payload;
    },
    setAssetPermissions: (state, action) => {
      state.assetPermissions = action.payload;
    },
    setSelectedPortfolios: (state, action) => {
      state.selectedPortfolios = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPortfolio.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPortfolio.fulfilled, (state, action) => {
        state.loading = false;
        state.AssectDataOwnerOperator = action.payload;
        state.assetPermissions = action.payload.length > 0 ? action.payload[0].assetPermissions : [];
        if (!state.selectedProperty && action.payload.length > 0) {
          state.selectedProperty = action.payload[0];
        }
      })
      
      .addCase(fetchPortfolio.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.AssectDataOwnerOperator = null;
        state.assetPermissions = null;
        state.selectedProperty = null;
      })
      .addCase(updateProperty.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProperty.fulfilled, (state, action) => {
        state.loading = false;
        let {propertyId, updatedData} = action.payload;
        state.AssectDataOwnerOperator = state.AssectDataOwnerOperator.map((property) => (property.id === propertyId ? {...property, ...updatedData} : property));
        if (state.selectedProperty && state.selectedProperty.id === propertyId) {
          state.selectedProperty = {...state.selectedProperty, ...updatedData};
        }
      })
      .addCase(updateProperty.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {selectProperty, setAssetPermissions, setSelectedPortfolios} = portfolioSlice.actions;
export default portfolioSlice.reducer;
