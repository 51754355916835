import "./PageLayout.scss";
import AuthHeader from "../Headers/AuthHeader";

const NotSubscribe = ({ children, crossActive }) => {
  return (
    <div className="PageL-M">
      <AuthHeader crossActive={crossActive} />
      <div className="PageL-RB">{children}</div>
    </div>
  );
};

export default NotSubscribe;
