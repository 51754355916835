import React, {useEffect, useState} from "react";
import "./Team.scss";
import filter from "../../../assets/svg/filtter.svg";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";
import add from "../../../assets/svg/add.svg";
import ContactDetails from "./ContactDetails";
import {DeleteBox} from "./Employee/DeleteBox";
import {InviteBox} from "./Employee/InviteBox";
import {TeamTable} from "./Employee/TeamTable";
import axios from "axios";
import useAuthConfig from "../../../utils/Config";

function Team() {
  const [invite, setInvite] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const handleInvite = () => {
    setInvite(!invite);
  };

  const handlePersonClick = (person) => {
    setSelectedPerson(!selectedPerson);
  };

  const handleDelete = () => {
    setIsDelete(!isDelete);
  };

  let config = useAuthConfig();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await axios("/api/auth/employee", config);
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="teams">
      <div className="team_header">
        <h2>Team Members</h2>

        <div style={{display: "flex", alignItems: "center", gap: "12px"}}>
          <div className="search_bar">
            <input
              type="text"
              placeholder="Type To Search"
            />
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20 18.586L18.586 20L12.929 14.343L14.343 12.929L20 18.586ZM7 12C4.243 12 2 9.757 2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12ZM7 0C3.134 0 0 3.134 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0Z"
                fill="white"
                fill-opacity="0.01"
              />
              <mask
                id="mask0_9862_58034"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 18.586L18.586 20L12.929 14.343L14.343 12.929L20 18.586ZM7 12C4.243 12 2 9.757 2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12ZM7 0C3.134 0 0 3.134 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.134 10.866 0 7 0Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_9862_58034)">
                <rect
                  opacity="0.5"
                  x="-1"
                  y="-1"
                  width="22"
                  height="22"
                  fill="#9EA0A5"
                />
              </g>
            </svg>
          </div>

          <div className="filter_btn">
            <img
              src={filter}
              alt=""
            />
            Filter
          </div>
          <div
            className="dropdown"
            style={{marginTop: "-22px"}}
          >
            <DropDown
              label=""
              selectedData={"Actions"}
              width={"110px"}
            />
          </div>
          <button
            onClick={handleInvite}
            className="btn_add"
          >
            <img
              src={add}
              alt=""
            />
            Add a Colleague
          </button>
        </div>
      </div>

      <TeamTable
        data={data}
        handlePersonClick={handlePersonClick}
      />

      {invite && <InviteBox handleInvite={handleInvite} />}
      {selectedPerson && (
        <ContactDetails
          handlePersonClick={handlePersonClick}
          className={selectedPerson ? "visible" : ""}
        />
      )}
      {isDelete && <DeleteBox handleDelete={handleDelete} />}
    </div>
  );
}

export default Team;
