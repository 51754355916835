import React, {useState} from "react";
import "./Aisupport.scss";
import chart from "../../assets/svg/ai-recom-chart.svg";
import edit from "../../assets/svg/edit.svg";
import arrow from "../../assets/svg/left-arrow-fill.svg";
import send from "../../assets/svg/send.svg";
import aibuilding from "../../assets/svg/ai-building.png";
import left from "../../assets/svg/pagination_arrow_left.svg";
import right from "../../assets/svg/pagination_arrow_right.png";
import cross from "../../assets/svg/cross.svg";
import clock from "../../assets/svg/clock.svg";
import {InputBox} from "../../components/reusableComponents/FormFields/FormFields";

function AiSupport({handleAiSupport, showAi}) {
  const [navActive, setNavActive] = useState(1);
  const [findAns, setFindAns] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showAiBox, setShowAiBox] = useState(false);
  const [accpeted, setAccepted] = useState(false);
  const handleAns = () => {
    setFindAns(!findAns);
  };

  const handleHelp = () => {
    setShowHelp(!showHelp);
  };

  const handleAiBox = () => {
    setShowAiBox(!showAiBox);
  };

  const handleAccepted = () => {
    setAccepted(!accpeted);
  };
  return (
    <div className="ai_support">
      <div className="header">
        <div className="ai_nav">
          <div className=" deals-room-nav asset-nav ass-nav">
            <span
              className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(1);
              }}
            >
              Insights{" "}
            </span>
            <span
              className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(2);
              }}
            >
              Recommendations
            </span>

            <span
              className={navActive === 3 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(3);
              }}
            >
              Ai Support{" "}
            </span>
          </div>{" "}
        </div>

        <div
          className="header_right"
          onClick={handleAiSupport}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.673 5.72566L14.669 5.72866C14.266 6.10366 14.244 6.73466 14.62 7.13666L15.577 8.15766C15.876 8.47766 15.649 8.99966 15.212 8.99966H1C0.447 8.99966 0 9.44766 0 9.99966C0 10.5517 0.447 10.9997 1 10.9997H15.197C15.637 10.9997 15.862 11.5267 15.558 11.8447L14.63 12.8187C14.248 13.2177 14.266 13.8517 14.669 14.2287C15.068 14.6027 15.695 14.5837 16.071 14.1857L18.76 11.3377C19.49 10.5637 19.487 9.35366 18.753 8.58466L16.067 5.76866C15.692 5.37566 15.071 5.35666 14.673 5.72566Z"
              fill="#9EA0A5"
            />
            <rect
              y="4"
              width="13"
              height="2"
              rx="1"
              fill="#9EA0A5"
            />
            <rect
              y="14"
              width="13"
              height="2"
              rx="1"
              fill="#9EA0A5"
            />
          </svg>
        </div>
      </div>

      {navActive === 1 && <Insights />}
      {navActive === 2 && (
        <Recommendations
          handleAccepted={handleAccepted}
          accepted={accpeted}
        />
      )}
      {navActive === 3 && !findAns && !showHelp && !showAiBox && (
        <Support
          handleAns={handleAns}
          handleAiBox={handleAiBox}
        />
      )}
      {navActive === 3 && findAns && !showHelp && (
        <FindAnswers
          handleAns={handleAns}
          handleHelp={handleHelp}
        />
      )}
      {navActive === 3 && showHelp && <HelpCenter handleHelp={handleHelp} />}
      {navActive === 3 && showAiBox && <AiBox handleAiBox={handleAiBox} />}
    </div>
  );
}

export default AiSupport;

const Insights = () => {
  return (
    <div className="insights">
      <p className="text">
        Eastern Riverside looks more promising than the current asset in the future after 3 years. Although the first 1-1.5 years it can bring less income. It looks more promising than the current
        asset in the future after 3 years.
        <br></br>
        <br></br>
        Likewise, the Federal Housing Finance Agency’s seasonally-adjusted purchase-only U.S. house price index rose 4.76% y-o-y in Q2 2019 (3.12% inflation-adjusted), the lowest growth in almost five
        years. The FHFA index increased just 0.75% q-o-q during the latest quarter (-0.02% inflation-adjusted), the weakest showing since Q4 2011 Although the first 1-1.5 years it can bring less
        income.
      </p>

      <Pagination />
    </div>
  );
};

const Recommendations = ({accepted, handleAccepted}) => {
  return (
    <div className="recommendations">
      {!accepted && (
        <>
          <p className="text">
            This asset looks potentially profitable, with the clarification of the fact that the first year may be low income due to the small number of tenants, as a road is being built in this area.
            <br></br>
            <br></br>
            According to the plans, the renovation will end in March 2021 and some tenants will book places for shops in advance.
          </p>
          <img
            src={chart}
            alt=""
          />
        </>
      )}

      <div>
        {accepted && <AcceptedBox />}

        <div className="btn_wrapper">
          <button
            className={`btn_one ${accepted && "btn_active"}`}
            onClick={handleAccepted}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.0212 2.42991L6.13321 9.31791C5.54621 9.90391 4.59621 9.90391 4.00921 9.31791L1.24221 6.55091C0.852207 6.16091 0.852207 5.52791 1.24221 5.13691C1.63321 4.74691 2.26621 4.74691 2.65621 5.13691L4.36221 6.84291C4.75321 7.23391 5.38821 7.23391 5.78021 6.84291L11.6072 1.01591C11.9972 0.624906 12.6312 0.624906 13.0212 1.01591C13.4122 1.40591 13.4122 2.03991 13.0212 2.42991Z"
                fill="#9EA0A5"
              />
            </svg>
            Accept
          </button>
          <button>
            <img
              style={{height: "20px", width: "20px"}}
              src={clock}
              alt=""
            />
            Snooze
          </button>
          <button>
            {" "}
            <img
              src={cross}
              alt=""
            />
            Reject
          </button>
        </div>
      </div>
      <Pagination />
    </div>
  );
};

const Support = ({handleAns, handleAiBox}) => {
  return (
    <div className="support">
      <div className="support_upper">
        <h2>
          <span>👋</span>
          Hi Mark,
        </h2>

        <span>With AI Support you can ask system your personal question or find an answer in the articles.</span>
      </div>

      <div className="question_box_wrapper">
        <QuestionBox
          label={"find your answer"}
          span={"Search in the articles"}
          icon={arrow}
          handleAns={handleAns}
        />
        <QuestionBox
          label={"Ask a question"}
          span={"Our system speaks natural language"}
          icon={send}
          handleAns={handleAiBox}
        />
      </div>
    </div>
  );
};

const QuestionBox = ({label, span, icon, handleAns}) => {
  return (
    <div className="question_box">
      <label>{label}</label>
      <div className="question_input_wrapper">
        <img
          src={edit}
          alt=""
        />
        <input type="text" />
        <img
          onClick={handleAns}
          style={{marginRight: icon === send ? "12px" : 0}}
          src={icon}
          alt=""
        />
      </div>
      <span>{span}</span>
    </div>
  );
};

const FindAnswers = ({handleHelp, handleAns}) => {
  return (
    <div className="find_answers">
      <div className="header">
        <svg
          onClick={handleAns}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
            fill="#9EA0A5"
          />
        </svg>
        <h3>Find your answer</h3>
      </div>

      <div className="find_answers_box">
        <div className="find_answers_box_upper">
          <div className="find_question_box">
            <label>Find your answer</label>
            <div className="question_input_wrapper">
              <input type="text" />
              <img
                src={arrow}
                alt=""
              />
            </div>
            <span>Search in the articles</span>
          </div>
        </div>

        <div className="results">
          <p>Search results for "How it works?"</p>
          <div
            className="answer_box"
            onClick={handleHelp}
          >
            <p>What is Lilypads?</p>
            <span>Lilypads is an AI-powered platform that empowers owners, investors, asset...</span>
          </div>

          <div className="answer_box">
            <p>What is Lilypads?</p>
            <span>Lilypads is an AI-powered platform that empowers owners, investors, asset...</span>
          </div>

          <div className="answer_box">
            <p>What is Lilypads?</p>
            <span>Lilypads is an AI-powered platform that empowers owners, investors, asset...</span>
          </div>

          <div className="btn_wrapper">
            <button>Load More Results</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const HelpCenter = ({handleHelp}) => {
  return (
    <div className="help_center">
      <div className="header">
        <svg
          onClick={handleHelp}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
            fill="white"
          />
        </svg>
        <h3>Help Center</h3>
      </div>

      <div className="help_center_box">
        <h2>What is Lilypads</h2>
        <p className="text">
          Lilypads is an AI-powered platform that empowers owners, investors, asset managers, and operators to maximize revenue by leveraging existing data to increase transactional efficiency and
          optimize property performance.
        </p>
        <img
          style={{marginBottom: "20px"}}
          src={aibuilding}
          alt=""
        />

        <span style={{marginTop: "12px"}}> Acquisitions</span>

        <p className="text"> Streamlines and automates the entire due diligence process, including asset discovery, market analysis, compliance, financial reporting, and valuation. </p>

        <span> Portfolio </span>

        <p className="text"> Pinpoints areas of immediate impact to a property's bottom line by correlating data and proactively suggesting actionable recommendations. </p>

        <span> Disposition </span>

        <p className="text"> Prepares your asset through a reverse due diligence process, allowing task assignment, broker engagement, and an immediate unbiased 3rd party valuation. </p>
      </div>
    </div>
  );
};

const Pagination = () => {
  return (
    <div className="pagination">
      <div className="btns_wrapper">
        <div className="btns">
          <div>
            <img
              src={left}
              alt=""
            />
          </div>
          <div>
            <img
              src={right}
              alt=""
            />
          </div>
        </div>
        <p>2 of 10</p>
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12C9 11.76 8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C19.66 8 21 6.66 21 5C21 3.34 19.66 2 18 2C16.34 2 15 3.34 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C4.34 9 3 10.34 3 12C3 13.66 4.34 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.35C15.11 18.56 15.08 18.78 15.08 19C15.08 20.61 16.39 21.92 18 21.92C19.61 21.92 20.92 20.61 20.92 19C20.92 17.39 19.61 16.08 18 16.08Z"
          fill="#9EA0A5"
        />
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
      >
        <g clip-path="url(#clip0_5704_324188)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 20H4.20001V8H0V20ZM20.9244 11.355L19.1006 17.89C18.7992 19.131 17.639 20 16.3023 20H6.3V8.021L8.10497 1.82501C8.27402 0.775012 9.22322 0 10.3394 0C11.5878 0 12.6 0.964015 12.6 2.15302V8H18.1262C19.9731 8 21.3402 9.64098 20.9244 11.355Z"
            fill="#9EA0A5"
          />
        </g>
        <defs>
          <clipPath id="clip0_5704_324188">
            <rect
              width="21"
              height="20"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
      >
        <g clip-path="url(#clip0_5704_324193)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.9001 0H16.8001V11.9876H18.9001C20.0603 11.9876 21.0001 11.0935 21.0001 9.98969V1.99792C21.0001 0.894065 20.0603 0 18.9001 0ZM4.69779 0H14.7001V11.9876L12.8951 18.1762C12.682 19.4918 11.2456 20.375 9.79029 19.8425C8.91984 19.5249 8.40009 18.6627 8.40009 17.7747V12.9866C8.40009 12.4351 7.92969 11.9876 7.35009 11.9876H2.87289C1.02594 11.9876 -0.340107 10.3533 0.0746427 8.64105L1.89954 2.1178C2.19984 0.878078 3.36009 0 4.69779 0Z"
            fill="#9EA0A5"
          />
        </g>
        <defs>
          <clipPath id="clip0_5704_324193">
            <rect
              width="21"
              height="20"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const AiBox = ({handleAiBox}) => {
  return (
    <div className="aibox">
      <div className="header">
        <svg
          onClick={handleAiBox}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
            fill="white"
          />
        </svg>
        <h3>Ai Operator</h3>
      </div>
    </div>
  );
};

const AcceptedBox = ({handleAccepted}) => {
  return (
    <div className="accepted_box">
      <div className="header">
        <div className="header_left">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.9999 16.2016L4.7999 12.0016L3.3999 13.4016L8.9999 19.0016L20.9999 7.00156L19.5999 5.60156L8.9999 16.2016Z"
              fill="#9EA0A5"
            />
          </svg>
          <h3>Accepted! </h3>
        </div>
        <img
          onClick={handleAccepted}
          src={cross}
          alt=""
        />
      </div>
      <div className="accepted_box_lower">
        <div>
          <p>Thank you for your feedback!</p>
          <p>How pleased you are with it?</p>
        </div>

        <div className="reactions">
          <div>😎</div>
          <div>😎</div>
          <div>😎</div>
          <div>😎</div>
          <div>😎</div>
        </div>

        <div className="inputs">
          <InputBox
            label={"comment"}
            type={"text"}
          />
          <div className="btns">
            <span>Optional</span>
            <button>Send</button>
          </div>
        </div>
      </div>
    </div>
  );
};
