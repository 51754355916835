import React, {useState} from "react";
import DropDown from "../../../../components/reusableComponents/Dropdown/Dropdown";
import Checkbox from "../../settings/Checkbox";
import cross from "../../../../assets/svg/cross.svg";
import {InputBox} from "../../../../components/reusableComponents/FormFields/FormFields";

export const InviteBox = ({handleInvite}) => {
  return (
    <div className="invite_modal">
      <div className="invitebox">
        <div className="basic_header">
          <p>Invite a New Colleague</p>
          <img
            onClick={handleInvite}
            src={cross}
            alt=""
          />
        </div>

        <div className="inputs">
          <div>
            <InputBox
              label={"New Colleague"}
              type={"email"}
              placeholder={"john.doe@example.com"}
            />
            <div style={{display: "flex", gap: "12px"}}>
              <DropDown
                label={"Department"}
                selectedData={"Value"}
              />
              <DropDown
                label={"Snior Manager"}
                selectedData={"Value"}
              />
            </div>
          </div>
          <span>After person will be notified by email they have to confirm invitation.</span>
        </div>
        <div className="btns">
          <div>
            <button className="btn_cancel">Cancel</button>
          </div>
          <div style={{display: "flex", gap: "12px", alignItems: "center"}}>
            <Checkbox label={"Add Another"} />
            <button className="btn_right">Send Invitation</button>
          </div>
        </div>
      </div>
    </div>
  );
};
