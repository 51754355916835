import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {InputBox} from "../../components/reusableComponents/FormFields/FormFields";
import {PrimaryButton} from "../../components/reusableComponents/Buttons/Buttons";
import {Checkbox, FormControl, FormControlLabel} from "@mui/material";
import {Svgimages} from "./Svgimages/svgimages";
import {Location, Asset} from "./Svgimages/svgimages";


const AdvancedFilters = ({onClick}) => {
  return (
    <div className="AssetDiscoveryFilters">
      <div className="AssetDiscoveryFilters-T">
        <Tabs selectedTabClassName="active">
          <TabList>
            <Tab>Location</Tab>
            <Tab>Asset Type</Tab>
            <Tab>Building & Lot</Tab>
            <Tab>Sales</Tab>
            <Tab>Debt</Tab>
            <Tab>Tenants</Tab>
            <Tab>Ownership</Tab>
            <Tab>Tax</Tab>
            <Tab>Comps</Tab>
            <Tab>Amenities</Tab>
            <Tab>Investors</Tab>
            <Tab>Other</Tab>
          </TabList>
          <TabPanel>
            <Location />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          <TabPanel className={"innerTabination"}>
            <Asset />
          </TabPanel>
          {/* <TabPanel>
            <Building />
          </TabPanel>
          <TabPanel>
            <Sales />
          </TabPanel>
          <TabPanel>
            <Debt />
          </TabPanel>
          <TabPanel>
            <Tenants />
          </TabPanel>
          <TabPanel>
            <Ownership />
          </TabPanel>
          <TabPanel>
            <Tax />
          </TabPanel>
          <TabPanel>
            <Comps />
          </TabPanel>
          <TabPanel>
            <Amenities />
          </TabPanel>
          <TabPanel>
            <Investors />
          </TabPanel>
          <TabPanel>
            <Other />
          </TabPanel> */}
        </Tabs>
      </div>

      <div className="AssetDiscoveryFilters-B">
        <div className="white_buttons">
          <span>New Oxford, PA</span> <Svgimages />
        </div>
        <div
          className="applyButton"
          onClick={() => onClick()}
        >
          <PrimaryButton name={"Apply Filters"} />
        </div>
      </div>
    </div>
  );
};

export default AdvancedFilters;
