import React, { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/reducers/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { InputBox } from "../../components/reusableComponents/FormFields/FormFields";
import { PrimaryButton, PrimaryButtonloading } from "../../components/reusableComponents/Buttons/Buttons";
import lilypandsLogo from "../../assets/svg/lilipadsLogomain.svg";
import "./Signin.scss";
import { returnErrors } from "../../store/reducers/error";
import { returnMessages } from "../../store/reducers/message";
import Endpoint from "../../utils/Endpoint";

const Signin = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userLogin, setUserLogin] = useState({
    email: "",
    password: "",
  });

  const handleLogIn = async () => {
    setLoading(true);

    try {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(userLogin.email)) {
        setLoading(false);
        dispatch(returnErrors("Invalid email format", 400));
        return;
      }

      const response = await axios.post(Endpoint.userlogin, userLogin);
      const { success, message, data } = response.data;

      if (success) {
        dispatch(returnMessages(message, 200));
        dispatch(
          login({
            token: data.token,
            refreshToken: data.refreshToken,
            userInformation: data.userDetails,
            HomePage: data.url,
          })
        );
        navigate(data.HomePage); 
      } else {
        dispatch(returnErrors(message, 400));
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Something went wrong. Please try again.";
      dispatch(returnErrors(errorMessage, error.response?.status || 500));
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogIn();
    }
  };

  return (
    <div className="signupMainCon">
      <div className="signupCon-L">
        <div className="signupScroller">
          <div className="signupFirstSection signupsection">
            <div className="top_header">
              <img src={lilypandsLogo} alt="Lilypads Logo" />
              <div className="text_wrapper">
                <h1>Welcome to Lilypads</h1>
                <p>
                  Commercial Real Estate Industry’s first artificial intelligence decision engine
                </p>
              </div>
            </div>
            <div className="sigindetails">
              <h1>Sign In</h1>
              <InputBox
                label="E-mail address"
                type="email"
                value={userLogin.email}
                onChange={(e) =>
                  setUserLogin((prev) => ({ ...prev, email: e.target.value }))
                }
                onKeyPress={handleKeyPress}
              />
              <InputBox
                label="Password"
                type="password"
                value={userLogin.password}
                onChange={(e) =>
                  setUserLogin((prev) => ({ ...prev, password: e.target.value }))
                }
                onKeyPress={handleKeyPress}
              />
              <PrimaryButtonloading
                name={loading ? "Signing in..." : "Sign in"}
                onClick={handleLogIn}
                disabled={loading}
                loading={loading}
                classNames="sigin-button"
              />
            </div>
            <Link to="/password-recovery">
              <h3>Forgot password?</h3>
            </Link>
            <div className="spacerLine30"></div>
            <Link to="/signup">
              <PrimaryButton
                classNames="grey noMargin"
                name="Create Account!"
                onClick={() => {}}
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="signupCon-R">
        <div className="blackoverlay">
          <div className="signin_svg_wrapper">{/* Add image if needed */}</div>
        </div>
      </div>
    </div>
  );
};

export default Signin;