import React, {useEffect, useState} from "react";
import {useNavigate, Link} from "react-router-dom";

// import "./AddDoc.scss";
import "../../pages/addDoc/AddDoc.scss";
import "../../pages/newLoanReq/NewLoanReq.scss";
import {Borrower, BusinnesPlan, Button, Contacts, Disclaimer, General, KeyDeal, Lender, LoanDetails, Team} from "../DealsRoom/SetupLoanReq";
import DropDown from "../../components/reusableComponents/Dropdown/Dropdown";
import {InputBox, InfoBox} from "../../components/reusableComponents/FormFields/FormFields";
import {PrimaryButtonSvgFirst, PrimaryButton, SmallSvgButton} from "../../components/reusableComponents/Buttons/Buttons";

import buildingtwo from "../../assets/syndicate/svg/buildingtwo.svg";
import download from "../../assets/syndicate/svg/download.svg";

import eye from "../../assets/dealsroom/svg/eye.svg";

import buildingsvg from "../../assets/newloanreq/building.svg";
import cross from "../../assets/syndicate/svg/cross.svg";
import save from "../../assets/syndicate/svg/save.svg";
import left from "../../assets/syndicate/svg/left.svg";
import upload from "../../assets/syndicate/svg/upload.svg";

import right from "../../assets/syndicate/svg/right.svg";
import pen2 from "../../assets/dealsroom/svg/pen2.svg";
import searchlocation from "../../assets/newloanreq/searchlocation.svg";

import {SummarySyndicate} from "../DealsRoom/Old Syndicate/SyndicateData";

import {AssetDiscoverySearch, PropertyCardMini} from "../assetDiscovery/PropertyCard";

const NewLoanReq = () => {
  const [navActive, setNavActive] = useState(1);

  return (
    <div className="add-doc-main-wrapper">
      <DocHeader />
      <div className="second-container">
        <div className="upload-doc-section-left">
          {navActive === 1 && <NewGeneral />}
          {navActive === 4 && <NewLoanDetails />}
          {navActive === 2 && <LocationSynd setNavActive={setNavActive} />}
          {/* {navActive === 2 && <LocationNext />} */}
          {navActive === 3 && <AsstInfoSynd />}
          {/* {navActive === 4 && <FinishBox setNavActive={setNavActive} />} */}
          {navActive === 5 && <NewLender />}
          {navActive === 6 && <NewBusinnesPlan />}
          {navActive === 7 && <NewKeyDealPlan />}
          {navActive === 8 && <NewBorrower />}
          {navActive === 9 && <NewTeam />}
          {navActive === 10 && <NewContact />}
          {navActive === 11 && <NewDisclaimer />}
          {navActive === 12 && <NewSummary />}
          {navActive === 13 && <LocationNext setNavActive={setNavActive} />}
        </div>
        <div className="upload-doc-section-right">
          <DocNavigator
            navActive={navActive}
            setNavActive={setNavActive}
          />
        </div>
      </div>
    </div>
  );
};

export default NewLoanReq;

const DocHeader = ({navdata = 1}) => {
  const [navActive, setNavActive] = useState(6);
  const navigate = useNavigate();
  useEffect(() => {
    setNavActive(navdata);
  }, []);
  const buttonData = [
    {icon: `${save}`, value: "Save Draft"},
    {icon: `${eye}`, value: "Lender’s Preview"},
    {icon: `${left}`, value: "Back"},
  ];

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="deals-room-center-header">
      <p>New Loan Request</p>
      <div className="deals-room-header-button">
        <div
          className="header-button-first"
          onClick={goBack}
        >
          <img
            src={cross}
            alt="icon"
          />
          <p>Cancel</p>
        </div>

        {buttonData.map(({icon, value}) => {
          return (
            <Button
              icon={icon}
              value={value}
            />
          );
        })}
        <div className="header-button-last">
          <p>Next</p>
          <img
            src={right}
            alt="icon"
          />
        </div>
      </div>
    </div>
  );
};

const DocNavigator = ({navActive, setNavActive}) => {
  return (
    <div className="doc-navigator newloan-nav">
      <div className="steps">
        {renderStep(1, "General", navActive, setNavActive)}
        {renderStep(2, "Location", navActive, setNavActive)}
        {renderStep(3, "Asset Info", navActive, setNavActive)}
        {renderStep(4, "Loan Details", navActive, setNavActive)}
        {renderStep(5, "Lender", navActive, setNavActive)}
        {renderStep(6, "Business Plan", navActive, setNavActive)}
        {renderStep(7, "Key Deal Points", navActive, setNavActive)}
        {renderStep(8, "Borrower", navActive, setNavActive)}
        {renderStep(9, "Team", navActive, setNavActive)}
        {renderStep(10, "Contacts", navActive, setNavActive)}
        {renderStep(11, "Disclaimer", navActive, setNavActive)}
        {renderStep(12, "Summary", navActive, setNavActive)}
        <div className="progress-bar loan-progress">
          <span className="indicator"></span>
        </div>
      </div>
    </div>
  );
};

const renderStep = (stepNumber, stepText, navActive, setNavActive) => {
  const isActive = navActive === stepNumber;

  return (
    <div
      key={stepNumber}
      className={`step-cnt ${isActive ? "active" : ""}`}
      onClick={() => setNavActive(stepNumber)}
    >
      <span>{stepText}</span>
      <span className="circle"></span>
    </div>
  );
};

const NewGeneral = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container new-loan-general">
        <div className="legendimg">
          <img
            src={buildingsvg}
            alt=""
          />
        </div>
        <h2>General</h2>

        <General />
      </div>
    </div>
  );
};

const LocationSynd = ({setNavActive}) => {
  return (
    <div>
      <div className="mainContainer">
        <div className="docname-container new-loan-general">
          <div className="legendimg">
            <img
              src={buildingtwo}
              alt=""
            />
          </div>
          <h2>Location</h2>
          <div className="financial-container">
            <div className="first-inner-box">
              <img
                src={download}
                alt="download"
              />
              <p>Address</p>
            </div>
            <div className="location-body-loan">
              <div className="addr">
                <InputBox label="ADDRESS" />
              </div>
              <div className="location-body-bottom">
                <div className="locatoin-body-right">
                  <div className="addr-body">
                    <InputBox label="COUNTRY" />
                  </div>
                  <DropDown
                    selectedData={["commercial"]}
                    margin={"16px 0px 0px 0px"}
                    width={"320px"}
                    label="City"
                    type="text"
                  />
                  <div className="addr-body">
                    <InputBox label="MARKET" />
                  </div>
                </div>
                <div className="locatoin-body-left">
                  <DropDown
                    selectedData={["commercial"]}
                    margin={"16px 0px 0px 0px"}
                    width={"320px"}
                    label="State"
                    type="text"
                  />
                  <div className="addr-body">
                    <InputBox label="ZIP" />
                  </div>
                  <div className="addr-body">
                    <InputBox label="SUBMARKET" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="addressButton"
            onClick={() => {
              setNavActive(13);
            }}
          >
            <PrimaryButton name={"Check Address"} />
          </div>
        </div>
      </div>
    </div>
  );
};
const LocationNext = ({setNavActive}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isManualActive, setIsManualActive] = useState(false);

  const handleViewDetails = () => {
    setIsModalOpen(true);
  };
  return (
    <div>
      <div className="mainContainer">
        <div className={`docname-container new-loan-general ${isManualActive && "map-con"}`}>
          <div className="legendimg">
            <img
              src={buildingtwo}
              alt=""
            />
          </div>
          <h2>Location</h2>
          <div className="financial-containe new-location-con">
            <h1>8951 Waltham Woods Road, Parkville, MD 21234.</h1>
            <div className="edi">
              <div
                className="editbtn"
                // onClick={setNavActive(13)}
              >
                <img
                  src={pen2}
                  alt=""
                />
                <span>Change address</span>
              </div>
            </div>

            {!isManualActive && (
              <div className="manual-con">
                <img
                  src={searchlocation}
                  alt=""
                />
                <div>
                  <span>Lilypads hasn’t find the asset with these parameters in the Database. You can add it manually.</span>
                  <div
                    className="addressButton"
                    onClick={() => {
                      setIsManualActive(true);
                    }}
                  >
                    <PrimaryButton name={"Continue Manual Setup"} />
                  </div>
                </div>
              </div>
            )}
          </div>
          {isManualActive && (
            <div className="map-wrapper">
              <span>Lilypads has found this asset in the database.</span>
              <div className="map-div">
                <div className="AssetDiscoverySearch-absoluteCon map-abs">
                  <div className="AssetSearch_property_cards">
                    <div className="cards_wrapper">
                      <PropertyCardMini
                        collapsed={true}
                        onClick={handleViewDetails}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const AsstInfoSynd = () => {
  const [isClicked, setIsClicked] = useState(1);
  return (
    <div className="mainContainer">
      <div className="docname-container new-loan-general">
        <div className="legendimg">
          <img
            src={buildingtwo}
            alt=""
          />
        </div>
        <h2>Asset Info</h2>
        <div className="switch-btn ">
          <span
            onClick={() => {
              setIsClicked(1);
            }}
            className={isClicked === 1 && "back"}
          >
            Upload a file
          </span>
          <span
            onClick={() => {
              setIsClicked(2);
            }}
            className={isClicked === 2 && "back"}
          >
            Enter manually
          </span>
        </div>
        <div className="financial-container">
          <div className="first-inner-box">
            <img
              src={download}
              alt="download"
            />
            <p>Brocker Package</p>
          </div>
          <div className="big-box">
            <div className="inner-big-box">
              <img
                src={upload}
                alt=""
              />
              <p className="first-para">
                <InputBox type={"file"} />
              </p>
              <p className="second-para">Supported file formats: PDF. Max file size 250 MB</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NewLoanDetails = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container new-loan-general">
        <div className="legendimg">
          <img
            src={buildingsvg}
            alt=""
          />
        </div>
        <h2>Loan Details</h2>

        <LoanDetails />
      </div>
    </div>
  );
};

const NewLender = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container new-loan-general">
        <div className="legendimg">
          <img
            src={buildingsvg}
            alt=""
          />
        </div>
        <h2>Loan Details</h2>

        <Lender />
      </div>
    </div>
  );
};
const NewBusinnesPlan = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container new-loan-general">
        <div className="legendimg">
          <img
            src={buildingsvg}
            alt=""
          />
        </div>
        <h2>Business Plan</h2>

        <BusinnesPlan />
      </div>
    </div>
  );
};
const NewKeyDealPlan = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container new-loan-general">
        <div className="legendimg">
          <img
            src={buildingsvg}
            alt=""
          />
        </div>
        <h2>Key Deal Points </h2>

        <KeyDeal />
      </div>
    </div>
  );
};
const NewBorrower = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container new-loan-general">
        <div className="legendimg">
          <img
            src={buildingsvg}
            alt=""
          />
        </div>
        <h2>Borrower</h2>

        <Borrower />
      </div>
    </div>
  );
};
const NewTeam = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container new-loan-general">
        <div className="legendimg">
          <img
            src={buildingsvg}
            alt=""
          />
        </div>
        <h2>Team</h2>

        <Team />
      </div>
    </div>
  );
};
const NewContact = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container new-loan-general">
        <div className="legendimg">
          <img
            src={buildingsvg}
            alt=""
          />
        </div>
        <h2>Contacts</h2>

        <Contacts />
      </div>
    </div>
  );
};
const NewDisclaimer = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container new-loan-general">
        <div className="legendimg">
          <img
            src={buildingsvg}
            alt=""
          />
        </div>
        <h2>Summary</h2>

        <Disclaimer />
      </div>
    </div>
  );
};
const NewSummary = () => {
  return (
    <div className="mainContainer">
      <div className="docname-container new-loan-general">
        <div className="legendimg">
          <img
            src={buildingsvg}
            alt=""
          />
        </div>
        <h2>Disclaimer</h2>

        <SummarySyndicate />
      </div>
    </div>
  );
};
