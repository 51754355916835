import React, {useState} from "react";
import "./notifications.scss";
import {InputBox} from "../../../components/reusableComponents/FormFields/FormFields";
import Checkbox from "./Checkbox";
import dropdownArrows from "../../../assets/Image/dropdownArrows.png";
import info from "../../../assets/svg/info.svg";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";

function Notifications() {
  const [timeList, setTimeList] = useState(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]);
  const [notifications, setNotifications] = useState([
    {id: 1, name: "Comments", selected: false},
    {id: 2, name: "Email", selected: false},
    {id: 3, name: "SMS", selected: false},
    {id: 4, name: "Messenger", selected: false},
  ]);

  const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];
  const [schedule, setSchedule] = useState({
    day: null,
    time: "9",
    period: "AM",
  });

  const [promotions, setPromotions] = useState([
    {id: 1, name: "Email", selected: false},
    {id: 2, name: "Push Notifications", selected: false},
    {id: 3, name: "Messages", selected: false},
    {id: 4, name: "Phone Calls", selected: false},
  ]);

  const handleNotificationToggle = (id, type) => {
    const updateFn = type === "notifications" ? setNotifications : setPromotions;
    const currentData = type === "notifications" ? notifications : promotions;

    updateFn((prev) => prev.map((item) => (item.id === id ? {...item, selected: !item.selected} : item)));
  };

  const handleDaySelection = (index) => {
    setSchedule((prev) => ({
      ...prev,
      day: prev.day === index ? null : index,
    }));
  };

  const handleScheduleChange = (key, value) => {
    setSchedule((prev) => ({...prev, [key]: value}));
  };

  return (
    <div className="notifications">
      <div className="basic_header">
        <div>
          <p>Notifications</p>
          <span>Receive notifications, requests to write a review, pricing notices, and other reminders related to your activities on the platform.</span>
        </div>
      </div>

      <div className="receive">
        <p>Receive SMS notifications to phone number:</p>
        <div>
          <DropDown
            label=""
            data={timeList}
            selectedData={schedule.time}
            setSelectedData={(value) => handleScheduleChange("time", value)}
          />
          <InputBox
            label={""}
            type={"tel"}
            placeholder={"(123) 456-7890"}
          />
        </div>
      </div>

      <div className="notifications_box">
        <p>Notifications</p>
        <div className="row_wrapper">
          {notifications.map((item) => (
            <div
              key={item.id}
              className="row"
            >
              <Checkbox
                label={item.name}
                checked={item.selected}
                onChange={() => handleNotificationToggle(item.id, "notifications")}
              />
              <div>
                <img
                  style={{height: "10px", width: "6px"}}
                  src={dropdownArrows}
                  alt="img"
                />
                <img
                  src={info}
                  alt="info-icon"
                />
              </div>
            </div>
          ))}
        </div>

        <div className="schedule">
          <p>Choose a convenient time to receive recommendations</p>
          <div className="days_wrapper">
            {daysOfWeek.map((day, index) => (
              <div
                key={index}
                className={`days ${schedule.day === index ? "active" : ""}`}
                onClick={() => handleDaySelection(index)}
              >
                {day}
              </div>
            ))}
          </div>
          <div className="timer">
            <DropDown
              label=""
              data={timeList}
              selectedData={schedule.time}
              setSelectedData={(value) => handleScheduleChange("time", value)}
            />
            <DropDown
              label=""
              data={["AM", "PM"]}
              selectedData={schedule.period}
              setSelectedData={(value) => handleScheduleChange("period", value)}
            />
          </div>
        </div>

        {/* Promotions */}
        <p className="promotions">Promotions</p>
        <div className="promotions_row">
          {promotions?.map((item) => (
            <Checkbox
              key={item.id}
              label={item.name}
              checked={item.selected}
              onChange={() => handleNotificationToggle(item.id, "promotions")}
            />
          ))}
        </div>
      </div>

      {/* Auto-Save */}
      <div
        style={{display: "flex", alignItems: "flex-start", gap: "6px"}}
        className="lower"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.49967 13.4993L3.99967 9.99935L2.83301 11.166L7.49967 15.8327L17.4997 5.83268L16.333 4.66602L7.49967 13.4993Z"
            fill="#9EA0A5"
          />
        </svg>
        <span>Changes are saved automatically</span>
      </div>
    </div>
  );
}

export default Notifications;
