import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {updateProperty} from "../../../../store/reducers/portfolioSlice";
import {PrimaryButton} from "../../../../components/reusableComponents/Buttons/Buttons";
import CircularProgress from "@mui/material/CircularProgress";

const PropertyDetailsPopupEdit = ({isOpen, onClose, propertyData, refreshPropertyDetails}) => {
  const [editedPropertyData, setEditedPropertyData] = useState(propertyData);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    try {
      const {assetName, assetDescription} = editedPropertyData;

      await dispatch(
        updateProperty({
          propertyId: propertyData.id,
          updatedData: {assetName, assetDescription},
          selectedItem: propertyData,
        }),
      );

      onClose();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const {name, value} = e.target;
    setEditedPropertyData({...editedPropertyData, [name]: value});
  };

  const handleClose = (e) => {
    if (e.target.className.includes("popup_overlay_PropertyDetailsContent")) {
      onClose();
    }
  };

  useEffect(() => {
    setEditedPropertyData(propertyData);
  }, [propertyData]);

  return isOpen ? (
    <div
      className="popup_overlay_PropertyDetailsContent"
      onClick={handleClose}
    >
      <div className="popup_content_PropertyDetailsContent">
        <div className="modal-header">
          <h2>Edit Property Details</h2>
          <button
            className="close-button"
            onClick={onClose}
          >
            &#10005;
          </button>
        </div>
        <form>
          <label>Asset Name:</label>
          <input
            type="text"
            name="assetName"
            value={editedPropertyData.assetName}
            onChange={handleInputChange}
            placeholder="Asset Name"
          />
          <label>Asset Description:</label>
          <textarea
            name="assetDescription"
            value={editedPropertyData.assetDescription}
            onChange={handleInputChange}
            placeholder="Asset Description"
            style={{height: "200px"}}
          />
          <PrimaryButton
            name={
              loading ? (
                <CircularProgress
                  size={24}
                  color="inherit"
                />
              ) : (
                "Save Changes"
              )
            }
            className="save-button"
            type="button"
            onclick={handleSave}
          ></PrimaryButton>
        </form>
      </div>
    </div>
  ) : null;
};

export default PropertyDetailsPopupEdit;
