import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import Endpoint from "../../../utils/Endpoint";

const initialState = {
  tenantsWithMoveOutDate2023: [],
  rentGrowth: "",
  totalRent: "",
  occupiedPercentage: "",
  latestDate: "",
  leasedOccupancy: "",
  physicalOccupancy: "",
  physicalVacancy: "",
  rentReadyCount: "",
  notRentReadyCount: "",
  totalCurrentLeaseRent: "",
  renewalRate: "",
  Renewal: [],
  historicalRenewalRates: [],
  mostProfitableRenters: [],
  top7Rents: [],
  totalRevenue: "",
  predictedMonth: "",
  activeRenters: 0,
  pendingRent: 0,
  status: "idle",
  error: null,
};

export const fetchMostProfitableRenters = createAsyncThunk("tenantMetrics/fetchMostProfitableRenters", async ({propertyCode, page, limit, idToken}, thunkAPI) => {
  try {
    const response = await axios.get(Endpoint.Most_Profitable_Renters, {
      headers: {"auth-token": idToken},
      params: {property_code: propertyCode, page, limit},
    });

    const data = response.data;


    return {
      mostProfitableRenters: data.tenants || [],
    };
  } catch (error) {
    const errorMsg = error.response?.data || "Network error";
    return thunkAPI.rejectWithValue(errorMsg);
  }
});

export const fetchPortfolioDashboard = createAsyncThunk("tenantMetrics/fetchPortfolioDashboard", async ({propertyCode, idToken}, thunkAPI) => {
  try {
    const response = await axios.get(Endpoint.KPI_Summary, {
      headers: {"auth-token": idToken},
      params: {propertyCode},
    });

    const data = response.data;

    return {
      tenantsWithMoveOutDate2023: data.Lease_rental_growth || [],
      rentGrowth: data.rentGrowth || "",
      totalRent: data.totalRent || "",
      occupiedPercentage: data.occupiedPercentage || "",
      latestDate: data.latestDate || "",
      totalCurrentLeaseRent: data.totalCurrentLeaseRent || "",
      Renewal: data.Renewal || [],
      renewalRate: data.renewalRate || "",
      historicalRenewalRates: data.historicalRenewalRates || "",
      top7Rents: data.top7Rents || [],
    };
  } catch (error) {
    const errorMsg = error.response?.data || "Network error";
    return thunkAPI.rejectWithValue(errorMsg);
  }
});

export const fetchPropertyUnitBreakdown = createAsyncThunk("tenantMetrics/fetchPropertyUnitBreakdown", async ({propertyCode, idToken}, thunkAPI) => {
  try {
    const response = await axios.get(Endpoint.Unit_Breakdown, {
      headers: {"auth-token": idToken},
      params: {propertyCode},
    });

    const data = response.data;

    return {
      leasedOccupancy: data.Leased_occupancy,
      physicalOccupancy: data.Physical_occupancy,
      physicalVacancy: data.Physical_vacancy,
      TOTAL_AVAILABLE_PERCENT: data.TOTAL_AVAILABLE_PERCENT,
      EIGHTEEN_MO_L_ROLL: data.EIGHTEEN_MO_L_ROLL,
    };
  } catch (error) {
    const errorMsg = error.response?.data || "Network error";
    return thunkAPI.rejectWithValue(errorMsg);
  }
});

export const fetchBuildingOverview = createAsyncThunk("tenantMetrics/fetchBuildingOverview", async ({propertyCode, idToken}, thunkAPI) => {
  try {
    const response = await axios.get(Endpoint.BuildingOverview, {
      headers: {"auth-token": idToken},
      params: {propertyCode},
    });

    const data = response.data;
    return {
      totalRevenue: data.totalRevenue || "",
      predictedMonth: data.predictedMonth || "",
      activeRenters: data.activeRenters || 0,
      pendingRent: data.pendingRent || 0,
      mostProfitableRenters: data.mostProfitableRenters || [],
    };
  } catch (error) {
    const errorMsg = error.response?.data || "Network error";
    return thunkAPI.rejectWithValue(errorMsg);
  }
});

const tenantMetricsSlice = createSlice({
  name: "tenantMetrics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPortfolioDashboard.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchPortfolioDashboard.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tenantsWithMoveOutDate2023 = action.payload.tenantsWithMoveOutDate2023;
        state.rentGrowth = action.payload.rentGrowth;
        state.totalRent = action.payload.totalRent;
        state.occupiedPercentage = action.payload.occupiedPercentage;
        state.latestDate = action.payload.latestDate;
        state.totalCurrentLeaseRent = action.payload.totalCurrentLeaseRent;
        state.Renewal = action.payload.Renewal;
        state.renewalRate = action.payload.renewalRate;
        state.historicalRenewalRates = action.payload.historicalRenewalRates;
        state.top7Rents = action.payload.top7Rents;
      })
      .addCase(fetchPortfolioDashboard.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch data";
      })
      .addCase(fetchBuildingOverview.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchBuildingOverview.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.totalRevenue = action.payload.totalRevenue;
        state.predictedMonth = action.payload.predictedMonth;
        state.activeRenters = action.payload.activeRenters;
        state.pendingRent = action.payload.pendingRent;
        state.mostProfitableRenters = action.payload.mostProfitableRenters;
      })
      .addCase(fetchBuildingOverview.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch data";
      })
      .addCase(fetchMostProfitableRenters.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchMostProfitableRenters.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.mostProfitableRenters = action.payload.mostProfitableRenters;
      })

      .addCase(fetchMostProfitableRenters.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch most profitable renters";
      });
  },
});

export default tenantMetricsSlice.reducer;
