import React, {useState, useEffect} from "react";
import axios from "axios";
import cover from "../../../assets/Image/assetDiscovery/general-bg.png";
import decline from "../../../assets/svg/cross-error.svg";
import {Svg1, Svg2} from "./SvgImages/SvgImages";
import "./recom.scss";
import Endpoint from "../../../utils/Endpoint";
import {FaArrowLeft, FaArrowRight} from "react-icons/fa";

export const LenderTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [expandedRow, setExpandedRow] = useState(null);

  const handlePreviewClick = (rowIndex) => {
    setExpandedRow((prevRow) => (prevRow === rowIndex ? null : rowIndex));
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`${Endpoint.Recommendation_History}?page=${page}&limit=5`);

        const {recommendations, total, limit} = response.data.data;

        setData(recommendations);
        setTotalPages(Math.ceil(total / limit));
        setLoading(false);
      } catch (err) {
        setError(err.message || "Error fetching data");
        setLoading(false);
      }
    };

    fetchData();
  }, [page]);

  return (
    <div
      className="lender_table"
      style={{display: "flex", justifyContent: "center", width: "maxContent"}}
    >
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <div className="documents_box">
          <div className="documents_box-B table">
            <div className="uploaded_docs">
              <table
                style={{
                  border: "1px solid #eaedf3",
                  width: "100%",
                  borderCollapse: "collapse",
                }}
              >
                <thead>
                  <tr>
                    <th>RELATED ASSETS</th>
                    <th>DATE</th>
                    <th>TARGET</th>
                    <th>DECISION</th>
                    <th>SUCCESS RATE</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, index) => (
                    <tr
                      key={item?._id}
                      style={{borderBottom: "1px solid #eaedf3"}}
                    >
                      <td>
                        <div
                          className="item first"
                          style={{display: "flex", gap: "10px"}}
                        >
                          <img
                            style={{
                              width: "74px",
                              height: "56px",
                              borderRadius: "3px",
                            }}
                            src={item?.asset_id?.assetsImages?.[0] || cover}
                            alt=""
                          />
                          <div className="text_wrapper">
                            <p>{item.asset_id?.assetName || "Unknown Asset"}</p>
                            <span>{item.asset_id?.assetsAddress || "Unknown Location"}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="item">
                          <div className="text_wrapper">
                            <p>{item?.date?.dateString}</p>
                            <span>{item?.date?.timeString}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="item">
                          <button className={`btn_${item?.target?.toLowerCase()}`}>{item?.target}</button>
                        </div>
                      </td>
                      <td>
                        <div className="item">
                          <div className="text_wrapper">
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "6px",
                                color: item?.decision?.status === "Declined" ? "#CB333B" : "#009639",
                              }}
                            >
                              {item?.decision?.status === "Declined" ? (
                                <>
                                  <img
                                    src={decline}
                                    alt=""
                                  />{" "}
                                  Declined
                                </>
                              ) : (
                                <>
                                  <Svg1 />
                                  Accepted
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="text_wrapper">
                          <p>{item?.successRate}</p>
                        </div>
                      </td>
                      <td>
                        <div
                          className="item"
                          style={{display: "flex", gap: "5px"}}
                        >
                          <Svg2 />
                          <p>Preview</p>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination_LenderTable">
                <button
                  disabled={page === 1}
                  onClick={() => setPage(page - 1)}
                  aria-label="Previous Page"
                >
                  <FaArrowLeft />
                </button>
                <span>
                  Page {page} of {totalPages}
                </span>
                <button
                  disabled={page === totalPages}
                  onClick={() => setPage(page + 1)}
                  aria-label="Next Page"
                >
                  <FaArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
