import React, {useEffect, useState} from "react";
import "./invoicing.scss";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";
import calendar from "../../../assets/svg/calender.svg";
import chart from "../../../assets/Image/invoice-graph.png";
import {InputBox} from "../../../components/reusableComponents/FormFields/FormFields";
import profileIcon from "../../../assets/svg/commentProfile.png";
import invoice from "../../../assets/svg/download.svg";
import dot from "../../../assets/svg/dot-gray.svg";
import {DownloadInvoicePDF, GetPaidPayments} from "../../../utils/Axios";
import {useSelector} from "react-redux";
import {CardSvg} from "./CardSvg";
import axios from "axios";
import {CircularProgress} from "@mui/material";
import PaymentMethod from "./PaymentMethod";
import { InvoiceTable } from "./InvoiceTable";
import { Row2 } from "./Row2";

function Invoicing() {
  const [navActive, setNavActive] = useState(1);
  const [payments, setPayments] = useState([]);
  const {idToken} = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchPaidPayments = async () => {
      try {
        const response = await GetPaidPayments(idToken);
        if (response && response.data) {
          setPayments(response.data);
        }
      } catch (error) {
        console.error("Error fetching paid payments:", error.message);
      }
    };

    if (idToken) {
      fetchPaidPayments();
    }
  }, [idToken]);

  return (
    <div className="invoicing">
      <div className="invoicing_left">
        <div className="invoicing_upper">
          <div className="invoicing_header">
            <h2>Payment Statistics</h2>
            <div className="invoicing_nav">
              <div className="deals-room-nav asset-nav ass-nav">
                <span
                  className={navActive === 1 ? "deals-room-nav-active ut-nav-active" : ""}
                  onClick={() => setNavActive(1)}
                >
                  Monthly
                </span>
                <span
                  className={navActive === 2 ? "deals-room-nav-active ut-nav-active" : ""}
                  onClick={() => setNavActive(2)}
                >
                  Daily
                </span>
              </div>
            </div>

            {/* <div>
              <DropDown
                label=""
                label2={""}
                selectedData={"November"}
              />
            </div>

            <div>
              <DropDown
                label=""
                label2={""}
                selectedData={"2019"}
              />
            </div> */}
          </div>
          <img
            src={chart}
            alt="Invoice Graph"
          />
        </div>

        <div className="invoicing_lower">
          <InvoiceTable
            payments={payments}
            idToken={idToken}
          />
        </div>
      </div>

      <PaymentMethod/>
    </div>
  );
}

export default Invoicing;

