import "./PageLayout.scss";

const NoHeader = ({children, crossActive}) => {
  return (
    <div className="PageL-M">
      <div className="PageL-RB">{children}</div>
    </div>
  );
};

export default NoHeader;
