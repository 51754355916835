import React, {useEffect, useState} from "react";
import axios from "axios";
import profilebig from "../../../../assets/profile.png";
import {useSelector, useDispatch} from "react-redux";
import {returnMessages} from "../../../../store/reducers/message";
import {returnErrors} from "../../../../store/reducers/error";
import {returnWarningMessages} from "../../../../store/reducers/Warning";
import Checkbox from "../../../profile/settings/Checkbox";
import {fetchAssetsByAdminEmail} from "../../../../store/reducers/AdminGivenAccess";
import {Collapse, Button} from "antd";
// import defaultimage from "../../../assets/portfolio/images/profile.png";
import Endpoint from "../../../../utils/Endpoint";
import Dialog from "@mui/material/Dialog";
import {TextField, Box, Typography, CircularProgress} from "@mui/material";
import "./AccessPopup.scss";
import {dropdownOptions} from "./dropdownOptions";
import useAuthConfig from "../../../../utils/Config";
import Timeout from "../../../../components/Autologout/Timeout";

const AccessPopup = ({onClose, selectedItem, userEmail, userInformation}) => {
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errors, setErrors] = useState({firstName: false, lastName: false});
  const {Panel} = Collapse;
  const dispatch = useDispatch();
  const {idToken} = useSelector((state) => state.auth);
  let config = useAuthConfig();
  Timeout(idToken);

  const [specialAccess, setSpecialAccess] = useState({
    "Financial KPI": false,
    OperatingIncomeExpenses: false,
    "Market Analysis": false,
    Utilities: false,
    Maintenance: false,
    ChatAccess: false,
  });

  const handleDropdownToggle = (menuName) => {
    setDropdownOpen((prevMenu) => (prevMenu === menuName ? null : menuName));
  };

  const handleCheckboxChange = (name, checked) => {
    setSpecialAccess((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const formatPermissions = () => {
    const formattedPermissions = {};

    Object.keys(dropdownOptions).forEach((section) => {
      const sectionPermissions = dropdownOptions[section].filter((option) => specialAccess[option.value]).map((option) => option.value);

      if (sectionPermissions.length > 0) {
        formattedPermissions[section] = sectionPermissions;
      }
    });

    formattedPermissions.chatAccess = !!specialAccess.ChatAccess;

    return formattedPermissions;
  };

  const validateFields = () => {
    const newErrors = {
      firstName: firstName.trim() === "",
      lastName: lastName.trim() === "",
    };
    setErrors(newErrors);

    return !newErrors.firstName && !newErrors.lastName;
  };

  const handleSendAccess = async () => {
    if (!validateFields()) return;

    setLoading(true);
    try {
      const formattedPermissions = formatPermissions();

      const requestBody = {
        userEmail: userEmail,
        assetIds: [selectedItem?._id],
        selectedPermissions: formattedPermissions,
        FirstName: firstName.trim(),
        LastName: lastName.trim(),
      };
      const response = await axios.post(Endpoint.giveAccess, requestBody, config);
      setLoading(false);
      fetchAssetsByAdminEmail({idToken, email: userInformation.email});
      if (response.data.success) {
        if (response.data.message === "User already exists") {
          const userId = response.data.data && response.data.data.userId;
          dispatch(returnWarningMessages(`User already exists. User ID: ${userId}`, 300, "Warning"));
        } else {
          dispatch(returnMessages(`Invitation mail sent successfully to ${userEmail}`, 200));
        }
      } else {
        dispatch(returnErrors(response.data.message || "Failed to send access"));
      }

      onClose();
    } catch (error) {
      setLoading(false);

      if (error.response) {
        dispatch(returnErrors(error.response.data.message || "Error assigning assets"));
      } else {
        dispatch(returnErrors("Server error", 400));
      }
    }
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
    >
      <div className="popup-content-AccessPopup">
        <h3>Please Add Access</h3>
        <img
          src={profilebig}
          alt="Profile"
          className="invite_email"
        />
        <h1>{selectedItem.assetName}</h1>
        <p>User Email: {userEmail}</p>

        <div className="invite_employee_cardBox">
          <div className="name_input_fields">
            <Box className="form-group">
              <label htmlFor="firstName">First Name</label>
              <TextField
                id="firstName"
                className="text-field"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={errors.firstName}
                helperText={errors.firstName ? "First Name is required" : ""}
                variant="outlined"
                size="small"
              />
            </Box>
            <Box className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <TextField
                id="lastName"
                className="text-field"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                error={errors.lastName}
                helperText={errors.lastName ? "Last Name is required" : ""}
                variant="outlined"
                size="small"
              />
            </Box>
          </div>
          <div className="checkbox-container">
            {Object.keys(dropdownOptions)?.map((menuName) => (
              <div
                className="menu-item"
                key={menuName}
              >
                <Collapse
                  defaultActiveKey={["1"]}
                  expandIconPosition="end"
                >
                  <Panel
                    header={menuName}
                    key="1"
                  >
                    {dropdownOptions[menuName]?.map((option) => (
                      <Checkbox
                        key={option.value}
                        label={option.label}
                        checked={specialAccess[option.value] || false}
                        onChange={(checked) => handleCheckboxChange(option.value, checked)}
                      />
                    ))}
                  </Panel>
                </Collapse>
              </div>
            ))}
          </div>
        </div>
        <div className="checkbox-container">
          <Checkbox
            key="ChatAccess"
            label="Chat Access"
            checked={specialAccess.ChatAccess || false}
            onChange={(checked) => handleCheckboxChange("ChatAccess", checked)}
          />
        </div>

        <div className="Buttons_for_send_email">
          <button onClick={onClose}>Close</button>
          <button
            className="send-button"
            onClick={handleSendAccess}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress
                size={22}
                color="inherit"
                style={{
                  marginRight: "8px",
                }}
              />
            ) : (
              "Send Access"
            )}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default AccessPopup;
