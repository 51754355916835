import React, {useEffect, useState} from "react";
import "./invoicing.scss";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";
import calendar from "../../../assets/svg/calender.svg";
import chart from "../../../assets/Image/invoice-graph.png";
import {InputBox} from "../../../components/reusableComponents/FormFields/FormFields";
import profileIcon from "../../../assets/svg/commentProfile.png";
import invoice from "../../../assets/svg/download.svg";
import dot from "../../../assets/svg/dot-gray.svg";
import {DownloadInvoicePDF, GetPaidPayments} from "../../../utils/Axios";
import {useSelector} from "react-redux";
import {CardSvg} from "./CardSvg";
import axios from "axios";
import {CircularProgress} from "@mui/material";
import PaymentMethod from "./PaymentMethod";

export const Row2 = ({payment, idToken}) => {
    const {_id, assetName, updatedAtDate, is_paid, paymentDetails, imageUrl} = payment;
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
  
    const togglePopup = () => {
      setShowPopup((prev) => !prev);
    };
  
    const handleDownloadInvoice = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          "/api/GenerateInvoicePDF",
          {_id},
          {
            headers: {
              "auth-token": idToken,
            },
            responseType: "blob",
          },
        );
  
        const blob = new Blob([response.data], {type: "application/pdf"});
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `invoice-${_id}.pdf`;
        link.click();
        window.URL.revokeObjectURL(link.href);
      } catch (error) {
        alert("An error occurred while downloading the invoice.");
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <div className="table_row table_row2">
        <div className="left">
          <div className="item first">
            <div className="text_wrapper">
              <p>{updatedAtDate}</p>
              <div>
                <span> ID:</span>
                <span>{_id}</span>
              </div>
            </div>
          </div>
          <div className="item second">
            <div className="text_wrapper">
              <p>{assetName}</p>
            </div>
          </div>
          <div className="item">
            <div className="text_wrapper">
              <p>${paymentDetails?.value?.toLocaleString()}</p>
            </div>
          </div>
          <div className="item">
            <button className="card_btn">
              <img
                src={imageUrl}
                alt={`Payment method for ${assetName}`}
                className="Payment_history_method"
              />
            </button>
          </div>
          <div className="item">
            <div className="text_wrapper">{is_paid ? <button className="btn_green">Paid</button> : <button className="btn_red">Failed</button>}</div>
          </div>
          <div className="item">
            <div className="dot-icon-wrapper">
              <img
                className="dot-icon"
                src={dot}
                alt="Options Icon"
                onClick={togglePopup}
              />
              {showPopup && (
                <div className="popup-menu">
                  <div
                    className={`popup-item ${loading ? "disabled" : ""}`}
                    onClick={!loading ? () => handleDownloadInvoice(_id) : undefined}
                  >
                    {loading ? (
                      <>
                        Generating invoice{" "}
                        <CircularProgress
                          size={12}
                          className="loader_for_Generatinginvoice"
                        />
                      </>
                    ) : (
                      "Download Invoice"
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };