import React from "react";

export function Svgimage1() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.2 6.4H8.8V4.8H7.2V6.4ZM7.2 9.6H8.8V8H7.2V9.6ZM8.8 14.4V11.2H7.2V14.4H4.8V4H7.2H8.8H11.2V14.4H8.8ZM14.4 12.8V14.4H12.8V2.4H11.2H8.8V0H7.2V2.4H4.8H3.2V14.4H1.6V12.8H0V14.4V16H3.2H4.8H11.2H12.8H16V14.4V12.8H14.4Z"
        fill="white"
        fill-opacity="0.01"
      />
      <mask
        id="mask0_13601_124259"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.2 6.4H8.8V4.8H7.2V6.4ZM7.2 9.6H8.8V8H7.2V9.6ZM8.8 14.4V11.2H7.2V14.4H4.8V4H7.2H8.8H11.2V14.4H8.8ZM14.4 12.8V14.4H12.8V2.4H11.2H8.8V0H7.2V2.4H4.8H3.2V14.4H1.6V12.8H0V14.4V16H3.2H4.8H11.2H12.8H16V14.4V12.8H14.4Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_13601_124259)">
        <rect
          x="-0.800003"
          y="-0.800781"
          width="17.6"
          height="17.6"
          fill="#6B6C6F"
        />
      </g>
    </svg>
  );
}


export function Svgimage2(){
    return (
        <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.59999 5.7752C9.59999 6.6592 8.88399 7.3752 7.99999 7.3752C7.11599 7.3752 6.39999 6.6592 6.39999 5.7752C6.39999 4.8912 7.11599 4.1752 7.99999 4.1752C8.88399 4.1752 9.59999 4.8912 9.59999 5.7752ZM7.99999 14.4C6.54239 14.4 3.99999 8.808 3.99999 5.6C3.99999 3.3944 5.79439 1.6 7.99999 1.6C10.2056 1.6 12 3.3944 12 5.6C12 8.808 9.45759 14.4 7.99999 14.4ZM7.99999 0C4.90719 0 2.39999 2.5072 2.39999 5.6C2.39999 8.6928 4.90719 16 7.99999 16C11.0928 16 13.6 8.6928 13.6 5.6C13.6 2.5072 11.0928 0 7.99999 0Z"
          fill="white"
          fill-opacity="0.01"
        />
        <mask
          id="mask0_13614_124294"
          maskUnits="userSpaceOnUse"
          x="2"
          y="0"
          width="12"
          height="16"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.59999 5.7752C9.59999 6.6592 8.88399 7.3752 7.99999 7.3752C7.11599 7.3752 6.39999 6.6592 6.39999 5.7752C6.39999 4.8912 7.11599 4.1752 7.99999 4.1752C8.88399 4.1752 9.59999 4.8912 9.59999 5.7752ZM7.99999 14.4C6.54239 14.4 3.99999 8.808 3.99999 5.6C3.99999 3.3944 5.79439 1.6 7.99999 1.6C10.2056 1.6 12 3.3944 12 5.6C12 8.808 9.45759 14.4 7.99999 14.4ZM7.99999 0C4.90719 0 2.39999 2.5072 2.39999 5.6C2.39999 8.6928 4.90719 16 7.99999 16C11.0928 16 13.6 8.6928 13.6 5.6C13.6 2.5072 11.0928 0 7.99999 0Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_13614_124294)">
          <rect
            x="-0.800003"
            y="-0.800781"
            width="17.6"
            height="17.6"
            fill="#9EA0A5"
          />
        </g>
      </svg>
    )
}

export function Svgimage3(){
    return (
        <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.4 8C14.4 4.4712 11.5288 1.6 8 1.6C4.4712 1.6 1.6 4.4712 1.6 8C1.6 11.5288 4.4712 14.4 8 14.4C11.5288 14.4 14.4 11.5288 14.4 8ZM16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8ZM12.8 8.8C12.8 9.2416 12.4416 9.6 12 9.6H8.8C7.916 9.6 7.2 8.884 7.2 8V4C7.2 3.5584 7.5584 3.2 8 3.2C8.4416 3.2 8.8 3.5584 8.8 4V7.2C8.8 7.64 9.16 8 9.6 8H12C12.4416 8 12.8 8.3584 12.8 8.8Z"
          fill="white"
          fill-opacity="0.01"
        />
        <mask
          id="mask0_13625_124448"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="16"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.4 8C14.4 4.4712 11.5288 1.6 8 1.6C4.4712 1.6 1.6 4.4712 1.6 8C1.6 11.5288 4.4712 14.4 8 14.4C11.5288 14.4 14.4 11.5288 14.4 8ZM16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8ZM12.8 8.8C12.8 9.2416 12.4416 9.6 12 9.6H8.8C7.916 9.6 7.2 8.884 7.2 8V4C7.2 3.5584 7.5584 3.2 8 3.2C8.4416 3.2 8.8 3.5584 8.8 4V7.2C8.8 7.64 9.16 8 9.6 8H12C12.4416 8 12.8 8.3584 12.8 8.8Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_13625_124448)">
          <rect
            x="-0.800003"
            y="-0.800781"
            width="17.6"
            height="17.6"
            fill="#9EA0A5"
          />
        </g>
      </svg>
    )
}

export function Svgimage4(){
    return (
        <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="path-1-outside-1_13625_124461"
          maskUnits="userSpaceOnUse"
          x="-0.333336"
          y="1"
          width="19"
          height="14"
          fill="black"
        >
          <rect
            fill="white"
            x="-0.333336"
            y="1"
            width="19"
            height="14"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 3C5.66666 3 2.82 5.07333 1.66666 8C2.82 10.9267 5.66666 13 9 13C12.3333 13 15.18 10.9267 16.3333 8C15.18 5.07333 12.3333 3 9 3ZM9 11.3333C7.16 11.3333 5.66666 9.84 5.66666 8C5.66666 6.16 7.16 4.66667 9 4.66667C10.84 4.66667 12.3333 6.16 12.3333 8C12.3333 9.84 10.84 11.3333 9 11.3333ZM9 6C7.89333 6 7 6.89333 7 8C7 9.10667 7.89333 10 9 10C10.1067 10 11 9.10667 11 8C11 6.89333 10.1067 6 9 6Z"
          />
        </mask>
        <path
          d="M1.66666 8L0.271117 7.45005L0.0543929 8L0.271117 8.54995L1.66666 8ZM16.3333 8L17.7289 8.54995L17.9456 8L17.7289 7.45005L16.3333 8ZM9 1.5C5.02758 1.5 1.64183 3.97176 0.271117 7.45005L3.06221 8.54995C3.99816 6.17491 6.30575 4.5 9 4.5V1.5ZM0.271117 8.54995C1.64183 12.0282 5.02758 14.5 9 14.5V11.5C6.30575 11.5 3.99816 9.82509 3.06221 7.45005L0.271117 8.54995ZM9 14.5C12.9724 14.5 16.3582 12.0282 17.7289 8.54995L14.9378 7.45005C14.0018 9.82509 11.6942 11.5 9 11.5V14.5ZM17.7289 7.45005C16.3582 3.97176 12.9724 1.5 9 1.5V4.5C11.6942 4.5 14.0018 6.17491 14.9378 8.54995L17.7289 7.45005ZM9 9.83333C7.98842 9.83333 7.16666 9.01157 7.16666 8H4.16666C4.16666 10.6684 6.33157 12.8333 9 12.8333V9.83333ZM7.16666 8C7.16666 6.98843 7.98842 6.16667 9 6.16667V3.16667C6.33157 3.16667 4.16666 5.33157 4.16666 8H7.16666ZM9 6.16667C10.0116 6.16667 10.8333 6.98843 10.8333 8H13.8333C13.8333 5.33157 11.6684 3.16667 9 3.16667V6.16667ZM10.8333 8C10.8333 9.01157 10.0116 9.83333 9 9.83333V12.8333C11.6684 12.8333 13.8333 10.6684 13.8333 8H10.8333ZM9 4.5C7.0649 4.5 5.5 6.06491 5.5 8H8.5C8.5 7.72176 8.72176 7.5 9 7.5V4.5ZM5.5 8C5.5 9.93509 7.0649 11.5 9 11.5V8.5C8.72176 8.5 8.5 8.27824 8.5 8H5.5ZM9 11.5C10.9351 11.5 12.5 9.93509 12.5 8H9.5C9.5 8.27824 9.27824 8.5 9 8.5V11.5ZM12.5 8C12.5 6.06491 10.9351 4.5 9 4.5V7.5C9.27824 7.5 9.5 7.72176 9.5 8H12.5Z"
          fill="#9EA0A5"
          mask="url(#path-1-outside-1_13625_124461)"
        />
      </svg>
    )
}

export function Svgimage5(){
  return (
    <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_13628_124239)">
      <path
        d="M17.2507 7.28585C13.2341 3.2693 6.69932 3.2693 2.68277 7.28585L0 9.96829L2.74932 12.7176C4.75743 14.7257 7.39527 15.7301 10.0334 15.7301C12.6716 15.7301 15.3091 14.7261 17.3176 12.7176L20.0003 10.0348L17.2507 7.28585ZM16.8395 12.2399C13.0865 15.9926 6.98007 15.9926 3.22703 12.2399L0.955405 9.96829L3.16047 7.76322C6.91351 4.01052 13.0199 4.01052 16.773 7.76322L19.0446 10.0348L16.8395 12.2399Z"
        fill="white"
      />
      <path
        d="M9.6527 7.28906C8.34864 7.28906 7.28783 8.34987 7.28783 9.65393C7.28783 9.84041 7.43919 9.99177 7.62567 9.99177C7.81216 9.99177 7.96351 9.84041 7.96351 9.65393C7.96351 8.72251 8.72128 7.96474 9.6527 7.96474C9.83919 7.96474 9.99054 7.81339 9.99054 7.6269C9.99054 7.44041 9.83952 7.28906 9.6527 7.28906Z"
        fill="white"
      />
      <path
        d="M9.99055 5.60156C7.56893 5.60156 5.59866 7.57183 5.59866 9.99345C5.59866 12.4151 7.56893 14.3853 9.99055 14.3853C12.4122 14.3853 14.3824 12.4151 14.3824 9.99345C14.3824 7.57183 12.4125 5.60156 9.99055 5.60156ZM9.99055 13.7097C7.94156 13.7097 6.27433 12.0424 6.27433 9.99345C6.27433 7.94447 7.94156 6.27724 9.99055 6.27724C12.0395 6.27724 13.7068 7.94447 13.7068 9.99345C13.7068 12.0424 12.0399 13.7097 9.99055 13.7097Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_13628_124239">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
  )
}

export function Svgimage6(){
   return (
    <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_13700_30685)">
                <g filter="url(#filter0_di_13700_30685)">
                  <rect
                    width="24"
                    height="24"
                    rx="12"
                    fill="url(#paint0_linear_13700_30685)"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                    rx="11.5"
                    stroke="#D8DCE6"
                  />
                </g>
              </g>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.4142 12.0009L16.9497 8.46537L15.5355 7.05116L12 10.5867L8.46447 7.05116L7.05025 8.46537L10.5858 12.0009L7.05025 15.5364L8.46447 16.9507L12 13.4151L15.5355 16.9507L16.9497 15.5364L13.4142 12.0009Z"
                fill="white"
                fill-opacity="0.01"
              />
              <mask
                id="mask0_13700_30685"
                maskUnits="userSpaceOnUse"
                x="7"
                y="7"
                width="10"
                height="10"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.4142 12.0009L16.9497 8.46537L15.5355 7.05116L12 10.5867L8.46447 7.05116L7.05025 8.46537L10.5858 12.0009L7.05025 15.5364L8.46447 16.9507L12 13.4151L15.5355 16.9507L16.9497 15.5364L13.4142 12.0009Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_13700_30685)">
                <rect
                  x="3"
                  y="3"
                  width="18"
                  height="18"
                  fill="#6B6C6F"
                />
              </g>
              <defs>
                <filter
                  id="filter0_di_13700_30685"
                  x="-1"
                  y="0"
                  width="26"
                  height="26"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="1" />
                  <feGaussianBlur stdDeviation="0.5" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.0863 0 0 0 0 0.1137 0 0 0 0 0.1451 0 0 0 0.05 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_13700_30685"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_13700_30685"
                    result="shape"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="2" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.05 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect2_innerShadow_13700_30685"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_13700_30685"
                  x1="24"
                  y1="24"
                  x2="24"
                  y2="0"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F6F7F9" />
                  <stop
                    offset="1"
                    stop-color="white"
                  />
                </linearGradient>
                <clipPath id="clip0_13700_30685">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
   )
}