import React, {useState} from "react";
import {Dialog, Button, LinearProgress, CircularProgress, IconButton} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import "./SingleDealsUpload.scss";
import {useSelector} from "react-redux";
import Endpoint from "../../../utils/Endpoint";

const ImportSingleDealPopup = ({open, onClose}) => {
  const [formData, setFormData] = useState({
    image: null,
    pdfFile: null,
  });
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("idle");
  const {userInformation} = useSelector((state) => state.auth);

  const handleFileChange = (event, fileType) => {
    const file = event.target.files[0];
    setFormData({...formData, [fileType]: file});
  };

  const handleSubmit = async () => {
    if (!formData.image || !formData.excelFile) {
      alert("Please upload an image and an Excel file.");
      return;
    }

    const data = new FormData();
    data.append("image", formData.image);
    data.append("excelfiles", formData.excelFile);
    data.append("userId", userInformation?._id);

    setProgress(0);
    setUploadStatus("uploading");

    try {
      await axios.post(Endpoint.uploadFiles, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
      });
      setUploadStatus("success");
      setFormData({image: null, excelFile: null});
    } catch (error) {
      setUploadStatus("error");
      alert("Failed to upload files. Please try again.");
    }
  };

  const renderFileStatus = (file, type) => {
    if (!file) return null;

    return (
      <div className="SingleDealsUpload-file-item">
        <AttachFileIcon style={{color: "#555"}} />
        <span className="SingleDealsUpload-file-name">{file.name}</span>
        {uploadStatus === "success" && <CheckCircleIcon style={{color: "green"}} />}
        {uploadStatus === "error" && <ErrorIcon style={{color: "red"}} />}
        <IconButton onClick={() => setFormData({...formData, [type]: null})}>
          <DeleteIcon style={{color: "#888"}} />
        </IconButton>
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="SingleDealsUpload-dialog"
    >
      <div className="SingleDealsUpload">
        <div className="SingleDealsUpload-dropzone">
          <label htmlFor="upload-image">
            <input
              accept="image/*"
              id="upload-image"
              type="file"
              style={{display: "none"}}
              onChange={(e) => handleFileChange(e, "image")}
            />
            <p>Click here to upload your image or drag and drop</p>
            <PhotoCamera style={{fontSize: "2rem", color: "#1976d2"}} />
          </label>
          <p>Supported Format: SVG, JPG, PNG (10mb each)</p>
        </div>

        {renderFileStatus(formData.image, "image")}

        <div className="SingleDealsUpload-dropzone">
          <label htmlFor="upload-excel">
            <input
              accept=".xlsx, .xls"
              id="upload-excel"
              type="file"
              style={{display: "none"}}
              onChange={(e) => handleFileChange(e, "excelFile")}
            />
            <p>Click here to upload your Excel file or drag and drop</p>
            <AttachFileIcon style={{fontSize: "2rem", color: "#1976d2"}} />
          </label>
          <p>Supported Format: Excel (.xlsx, .xls, max 10MB)</p>
        </div>

        {renderFileStatus(formData.excelFile, "excelFile")}

        {uploadStatus === "uploading" && (
          <div className="SingleDealsUpload-progress-bar">
            <LinearProgress
              variant="determinate"
              value={progress}
            />
            <span>{progress}%</span>
          </div>
        )}

        <div className="SingleDealsUpload-buttons">
          <Button
            onClick={onClose}
            className="cancel-button"
            disabled={uploadStatus === "uploading"}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            className="submit-button"
            disabled={uploadStatus === "uploading"}
          >
            {uploadStatus === "idle" ? "Upload" : "Uploading..."}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ImportSingleDealPopup;
