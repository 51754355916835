import React, {useState} from "react";
import {Link} from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import Checkbox from "../profile/settings/Checkbox";
import plusIcon from "../../assets/portfolio/svgs/plus.svg";
import "./Disposition.scss";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import {updateProperty} from "../../store/reducers/portfolioSlice.js";
import useAuthConfig from "../../utils/Config.js";
import {postutility} from "../../utils/Axios.js";

export const UtilitiesArrangement = () => {
  const {selectedProperty} = useSelector((state) => state.portfolio);

  const dispatch = useDispatch();
  const config = useAuthConfig();
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [newUtility, setNewUtility] = useState({
    utility: "",
    servicer: "",
    paidBy: "",
    meterType: "",
  });

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (e) => {
    const {name, value} = e.target;
    setNewUtility({...newUtility, [name]: value});
  };


  const handleAddUtility = async () => {
    setLoading(true);
    const output = await postutility(selectedProperty?._id, newUtility, config);
    if (output) {
      dispatch(
        updateProperty({
          propertyId: selectedProperty?._id,
          updatedData: output,
          selectedItem: selectedProperty,
        })
      );
      handleClose();
    }
    setLoading(false);
  };
  

  return (
    <div className="utility-arrangement-container">
      <div className="arrangement-table-header-UtilitiesArrangement">
        <h4>#</h4>
        <h4>UTILITY</h4>
        <h4>SERVICER</h4>
        <h4>PAID BY</h4>
        <h4>METER TYPE</h4>
      </div>

      <div className="arrangement-table-content">
        {selectedProperty?.utilities?.map((utility, idx) => (
          <div
            key={idx}
            className="utility-row"
          >
            <p>{idx + 1}</p>
            <p>{utility.utility}</p>
            <p>{utility.servicer}</p>
            <p>{utility.paidBy}</p>
            <p>{utility.meterType}</p>
          </div>
        ))}
      </div>
      <Link
        className="utility-button"
        onClick={handleClickOpen}
      >
        <img
          src={plusIcon}
          alt="Contact icon"
        />
        <h4>Add Utility</h4>
      </Link>

      <Checkbox label="I want my Utility Information to be uploaded into Asset Discovery" />

      <Dialog
        open={openDialog}
        onClose={handleClose}
      >
        <DialogTitle>Add New Utility</DialogTitle>
        <DialogContent>
          <DialogContentText>Please fill in the details to add a new utility.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Utility"
            name="utility"
            value={newUtility.utility}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Servicer"
            name="servicer"
            value={newUtility.servicer}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Paid By"
            name="paidBy"
            value={newUtility.paidBy}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Meter Type"
            name="meterType"
            value={newUtility.meterType}
            onChange={handleInputChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddUtility}
            color="primary"
            disabled={loading} 
            startIcon={loading ? <CircularProgress size={20} /> : null} 
          >
            {loading ? "" : "Add Utility"} 
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
