import React, {useEffect, useState} from "react";
import "./Signup.scss";
import {Checkmark, CheckmarkTICK} from "../../assets/svg/checkmark";
import {IndividualActive, IndividualDisable, OrganizationActive, OrganizationDisable} from "../../assets/svg/SignUpSVGs";
import PhoneInput from "react-phone-number-input";
import errorReducer, {returnErrors} from "../../store/reducers/error";
import {Link, useNavigate} from "react-router-dom";
import imgOne from "../../assets/Image/signupImg.png";
import emailConfirm from "../../assets/Image/emailConfirm.png";
import searchImg from "../../assets/Image/search.png";
import bankOne from "../../assets/Image/banks/one.png";
import bankTwo from "../../assets/Image/banks/two.png";
import bankThree from "../../assets/Image/banks/three.png";
import bankFour from "../../assets/Image/banks/four.png";
import bankFive from "../../assets/Image/banks/five.png";
import bankSix from "../../assets/Image/banks/six.png";
import bankSeven from "../../assets/Image/banks/seven.png";
import bankEight from "../../assets/Image/banks/eight.png";
import DropDown from "../../components/reusableComponents/Dropdown/Dropdown";
import {PrimaryButton, SmallSvgButton} from "../../components/reusableComponents/Buttons/Buttons";
import {InputBox, InfoBox} from "../../components/reusableComponents/FormFields/FormFields";
import uploadIcon from "../../assets/svg/uploadIcon.svg";

import {Slider} from "@mui/material";
import axios from "axios";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import {PostSignUpData, VerifyEmail} from "../../utils/Axios";
import {login} from "../../store/reducers/authSlice";
import Endpoint from "../../utils/Endpoint";
// import { postSignUpData } from "../../store/reducers/signUp";

const Signup = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [sliderState, setSliderState] = useState("one");
  const [accreditedInvestor, setAccreditedInvestor] = useState(true);
  const [accountType, setAccountType] = useState("");
  const [accountSUBType, setAccountSUBType] = useState("");
  const [linkedAccount, setLinkedAccount] = useState("link");
  const [cityList, setCitylist] = useState(["Austin", "New York", "Atlanta"]);
  const [stateList, setStateList] = useState(["Texas", "Georgia", "California"]);
  //all dropdownState
  //DA= Dropdown ACTIVE
  const [countryList, setCountrylist] = useState(["USA", "UK"]);
  const [countryOfCitizenship, setCountryOfCitizenship] = useState("USA");
  const [countryOfCitizenshipDA, setCountryOfCitizenshipDA] = useState(false);
  const [countryOfResidence, setCountryOfResidence] = useState("USA");
  const [countryOfResidenceDA, setCountryOfResidenceDA] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileOprating, setSelectedFileOprating] = useState(null);

  //sixth section Data

  const [phonenumber, setPhoneNumber] = useState("");
  const entityList = ["Limited Partnership", "Single-Member LLC", "S-Corporation", "LLC", "C Corporation"];
  const jointAccountList = ["Tenants in Common", "Rights of Survivorship", "Community Property"];

  // individualAccountInfo //
  const [individualAddressLine1, setIndividualAddressLine1] = useState();
  const [individualAddressLine2, setIndividualAddressLine2] = useState();
  const [individualZipCode, setIndividualZipCode] = useState();
  const [individualDOB, setIndividualDOB] = useState();

  // bank details
  const [bankName, setBankName] = useState();
  const [RoutingNumber, setRoutingNumber] = useState();
  const [AccountNumber, setAccountNumber] = useState();
  const [confirmAccountNumber, setConfirmAccountNumber] = useState();

  //sixth section dropdown
  const [city, setCity] = useState(cityList[0]);
  const [cityDA, setCityDA] = useState(false);
  const [stateAddress, setStateAddress] = useState(stateList[0]);
  const [stateAddressDA, setStateAddressDA] = useState(false);
  const [accountSignupCheck, setAccountSignupCheck] = useState(false);
  const [entity, setEntity] = useState(entityList[0]);
  const [entityDA, setEntityDA] = useState(false);
  const [entityName, setEntityName] = useState();
  const [entityEIN, setEntityEIN] = useState();
  const [entityIndustry, setEntityIndusty] = useState();
  const [entityDateFormation, setEntityDateFormation] = useState();
  const [jurisdiction, setJurisdiction] = useState();
  const [juridiction, setJuridiction] = useState(stateList[0]);
  const [juridictionDA, setJuridictionDA] = useState(false);
  const [jointAccount, setJointAccount] = useState(jointAccountList[0]);
  const [jointAccountDA, setJointAccountDA] = useState(false);
  const [jointAccCity, setJointAccCity] = useState(cityList[0]);
  const [jointAccCityDA, setJointAccCityDA] = useState(false);
  const [jointAccState, setJointAccState] = useState(stateList[0]);
  const [jointAccStateDA, setJointAccStateDA] = useState(false);
  const [percentOwnership, setPercentOwnership] = useState(30);
  const [jointAddress1, setJointAddress1] = useState();
  const [jointAddress2, setJointAddress2] = useState();
  const [jointZipCode, setJointZipCode] = useState();
  const [jointPhoneNumber, setJointPhoneNumber] = useState();
  const [jointSocialSecurity, setJointSocialSecurity] = useState();
  const [jointDateFormation, setJointDateFormation] = useState();
  const [jointName, setJointName] = useState();
  const [jointSurname, setJointSurname] = useState();
  const [jointSocialSecurityHolder2, setJointSocialSecurityHolder2] = useState();
  const [trustAccountType, setTrustAccountType] = useState();
  const [trustAddress1, setTrustAddress1] = useState();
  const [trustAddress2, setTrustAddress2] = useState();
  const [trusAccCity, setTrustAccCity] = useState();
  const [trustPhoneNumber, setTrustPhoneNumber] = useState();
  const [trustAccState, setTrustAccState] = useState();
  const [trustZipcode, setTrustZipCode] = useState();
  const [trustSocialSecurity, setTrustSocialSecurity] = useState();
  const [trustDateFormation, setTrustDateFormation] = useState();
  const [trustDateFormationHolder2, setTrustDateFormationHolder2] = useState();
  const [trustSocialSecurityHolder2, setTrustSocialSecurityHolder2] = useState();
  const [trustSurnameHolder2, setTrustSurnameHolder2] = useState();
  const [trustNameHolder2, setTrustNameHolder2] = useState();
  window.scrollTo(0, 0);

  //seventh section data
  const amountList = ["$10,000", "$20,000", "$50,000"];
  const autoAmountList = ["$10,000", "$20,000", "$50,000"];
  const frequencyList = ["Once a month", "Once in 3 months", "Once a year"];
  const paymentDayList = () => {
    let array = [];
    const nth = function (d) {
      if (d === 1) {
        return d + "st";
      } else if (d === 2) {
        return d + "nd";
      } else if (d === 3) {
        return d + "rd";
      } else {
        return d + "th";
      }
    };
    for (let index = 0; index < 30; index++) {
      let value = nth(index + 1);
      array.push(value);
    }
    return array;
  };

  // seventh section dropdown
  const [initialContri, setInitialContri] = useState(amountList[0]);
  const [initialContriDA, setInitialContriDA] = useState(false);
  const [autoInvest, setAutoInvest] = useState(false);
  const [autoInvestAmount, setAutoInvestAmount] = useState(autoAmountList[0]);
  const [autoInvestDA, setAutoInvestDA] = useState(false);
  const [frequency, setFrequency] = useState(frequencyList[0]);
  const [frequencyDA, setFrequencyDA] = useState(false);
  const [paymentDay, setPaymentDay] = useState(() => {
    const array = paymentDayList();
    return array[0];
  });
  const [paymentDayDA, setPaymentDayDA] = useState(false);

  //Eight section

  const bankAccountTypeList = ["Checking", "Savings"];
  const [bankAccount, setBankAccount] = useState(bankAccountTypeList[0]);
  const [bankAccountDA, setBankAccountDA] = useState(false);

  //Ninth section data
  const [acknowledgementsChecks, setAcknowledgementsChecks] = useState({
    first: false,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
  });

  //tenth section
  const [confrimEmailChecks, setConfrimEmailChecks] = useState({
    first: false,
    second: false,
  });

  const [confirmationCode, setConfirmationCode] = useState();
  const [verifyUserEmailOtp, setVerifyUserEmailOtp] = useState({
    email: "",
    otp: "",
  });
  const {idToken} = useSelector((state) => state.auth);

  //handling Signup data object
  const formData = new FormData();

  const [signupData, setSignupData] = useState({
    purpose: "invest",
    name: "",
    surname: "",
    password: "",
    businessEmail: "",
    companyName: "",
    accreditedInvestor: false,
    userTimeZone: "20",
    countryOfCitizenship: "",
    countryOfResidence: "",
    accountTypeChoosen: "individual",
    accountType: "individual",
    certificateOfFormation: "",
    operatingAgreement: "",
    profileImage: "",
    userEmailVerified: false,
    individualAccountInfo: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      phoneNo: "",
      dob: "1990-01-01",
    },
    entityAccountInfo: {
      name: "",
      type: "",
      ein: "",
      industry: "",
      dateOfFormation: "2021-01-01",
      jurisdictionOfRegistration: "",
      entityCertificateOfFormation: "",
      entityOperatingAgreement: "",
      documentsProvided: false,
    },
    jointAccountInfo: {
      name: "string",
      type: "string",
      addressLine1: "string",
      addressLine2: "string",
      city: "string",
      state: "string",
      zipCode: "string",
      phoneNo: "string",
      percentOfOwnership: "string",
      socialSecurityNumber: "string",
      dateOfFormation: "2021-01-01", // Example date format
      accountHolder2: {
        name: "string",
        surname: "string",
        socialSecurityNumber: "string",
      },
    },
    investmentInfo: {
      initialContribution: 1000, // Numeric value without quotes
      autoInvest: false,
      autoInvestInfo: {
        autoInvestmentAmount: 500, // Numeric value without quotes
        frequency: "string",
        paymentDay: "string",
      },
    },
    trustAccountInfo: {
      type: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      phoneNo: "",
      // percentOfOwnership: "",
      socialSecurityNumber: "",
      dateOfFormation: "2021-01-01", // Example date format
      accountHolder2: {
        name: "",
        surname: "",
        socialSecurityNumber: "string",
        dateOfFormation: "2021-01-01",
      },
    },
    bankAccount: {
      accountType: "string",
      bankName: "string",
      routingNumber: "string",
      accountNumber: "string",
    },
    privacyAndNewsletter: {
      shareInformation: false,
      promotionalOffers: false,
    },
  });


  const [emailVerifyData, setEmailVerifyData] = useState();
  // const { users } = useSelector(state => state.signUpUser);


  useEffect(() => {
    setSignupData((prev) => ({
      ...prev,
      countryOfCitizenship,
      countryOfResidence,
      certificateOfFormation: selectedFile ? true : false,
      operatingAgreement: selectedFileOprating ? true : false,
      profileImage: "",
      userEmailVerified: false,
      individualAccountInfo: {
        ...prev.individualAccountInfo,
        addressLine1: individualAddressLine1,
        addressLine2: individualAddressLine2,
        city: city,
        state: stateAddress,
        zipCode: individualZipCode,
        phoneNo: phonenumber,
        dob: individualDOB,
      },
      entityAccountInfo: {
        ...prev.entityAccountInfo,
        name: entityName,
        type: entity,
        ein: entityEIN,
        industry: entityIndustry,
        dateOfFormation: entityDateFormation,
        jurisdictionOfRegistration: jurisdiction,
        entityCertificateOfFormation: selectedFile,
        entityOperatingAgreement: selectedFileOprating,
        documentsProvided: selectedFile && selectedFileOprating ? true : false, // new value for documentsProvided
      },
      jointAccountInfo: {
        ...prev.jointAccountInfo,
        name: "newJointAccountNameValue",
        type: jointAccount,
        addressLine1: jointAddress1,
        addressLine2: jointAddress2,
        city: jointAccCity,
        state: jointAccState,
        zipCode: jointZipCode,
        phoneNo: jointPhoneNumber,
        percentOfOwnership: percentOwnership,
        socialSecurityNumber: jointSocialSecurity,
        dateOfFormation: jointDateFormation,
        accountHolder2: {
          ...prev.jointAccountInfo.accountHolder2,
          name: jointName,
          surname: jointSurname,
          socialSecurityNumber: jointSocialSecurityHolder2,
        },
      },
      investmentInfo: {
        ...prev.investmentInfo,
        initialContribution: Number(initialContri.slice(1).split(",").join("")),
        autoInvest: autoInvest,
        autoInvestInfo: {
          ...prev.investmentInfo.autoInvestInfo,
          autoInvestmentAmount: autoInvest ? Number(autoInvestAmount.slice(1).split(",").join("")) : "",
          frequency: autoInvest ? frequency : "",
          paymentDay: autoInvest ? paymentDay : "",
        },
      },
      trustAccountInfo: {
        ...prev.trustAccountInfo,
        type: trustAccountType,
        addressLine1: trustAddress1,
        addressLine2: trustAddress2,
        city: trusAccCity,
        state: trustAccState,
        zipCode: trustZipcode,
        phoneNo: trustPhoneNumber,
        // percentOfOwnership: "",
        socialSecurityNumber: trustSocialSecurity,
        dateOfFormation: trustDateFormation,
        accountHolder2: {
          ...prev.trustAccountInfo.accountHolder2,
          name: trustNameHolder2,
          surname: trustSurnameHolder2,
          socialSecurityNumber: trustSocialSecurityHolder2,
          dateOfFormation: trustDateFormationHolder2,
        },
      },
      bankAccount: {
        ...prev.bankAccount,
        accountType: bankAccount,
        bankName: bankName,
        routingNumber: RoutingNumber,
        accountNumber: AccountNumber === confirmAccountNumber ? AccountNumber : "",
      },
      privacyAndNewsletter: {
        ...prev.privacyAndNewsletter,
        shareInformation: confrimEmailChecks.first === true ? true : false,
        promotionalOffers: confrimEmailChecks.second === true ? true : false,
      },
    }));
  }, [
    countryOfResidence,
    countryOfCitizenship,
    city,
    selectedFileOprating,
    selectedFile,
    individualAddressLine1,
    individualAddressLine2,
    stateAddress,
    individualZipCode,
    phonenumber,
    individualDOB,
    autoInvest,
    initialContri,
    bankAccount,
    bankName,
    autoInvestAmount,
    paymentDay,
    frequency,
    RoutingNumber,
    AccountNumber,
    confirmAccountNumber,
    entityName,
    entity,
    entityEIN,
    entityIndustry,
    entityDateFormation,
    jointAddress1,
    jointAddress2,
    jointAccCity,
    jointAccState,
    jointDateFormation,
    jointZipCode,
    jointPhoneNumber,
    percentOwnership,
    jointSocialSecurity,
    jointName,
    jointSurname,
    jointSocialSecurityHolder2,
    trustAccountType,
    trustAddress1,
    trustAddress2,
    trusAccCity,
    trustAccState,
    trustZipcode,
    trustPhoneNumber,
    trustSocialSecurity,
    trustDateFormation,
    trustNameHolder2,
    trustSocialSecurityHolder2,
    trustSurnameHolder2,
    confrimEmailChecks,
  ]);

  //

  const handleDataChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    setSignupData((prev) => ({...prev, [key]: value}));
  };



  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf")) {
      setSelectedFile(file);
    } else {
      alert("Please select a JPG, PNG, or PDF file.");
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("certificateOfFormation", selectedFile);

      axios
        .post("users/upload-certificate-of-formation", formData)
        .then((response) => {
        })
      
    } else {
      // No file selected
      alert("Please select a file to upload.");
    }
  };

  const handleFileChangeOprating = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf")) {
      setSelectedFileOprating(file);
    } else {
      // File format not supported
      alert("Please select a JPG, PNG, or PDF file.");
    }
  };

  const handleUploadOprating = () => {
    if (selectedFileOprating) {
      const operatingAgreement = new FormData();
      operatingAgreement.append("operatingAgreement", selectedFileOprating);

      axios
        .post("users/upload-operating-agreement", operatingAgreement)
        .then((response) => {
        })
        .catch((error) => {
         
        });
    } else {
      alert("Please select a file to upload.");
    }
  };

  const navigate = useNavigate();

  // const handleSignupUser = async () => {

  //   setIsLoading(true)
  //   try {
  //     const response = await axios.post('users/signup', signupData);
  //     navigate('/signup/investor/successfull');
  //     setIsLoading(false)
  //   } catch (error) {

  //     setIsLoading(false)
  //     alert(error)
  //   }
  // }

  const handleSignupUser = async () => {
    setIsLoading(true);
    try {
      const otpNumber = parseInt(confirmationCode, 10);
      const otpVerificationResponse = await axios.post(
        Endpoint.verifyOTP,
        {
          email: signupData.email,
          otp: otpNumber,
        },
        {
          headers: {
            "auth-token": idToken,
          },
        },
      );

      if (otpVerificationResponse.data.status === "true") {
        const signUpResponse = await PostSignUpData(signupData);

        if (signUpResponse.success) {
          navigate("/signup/investor/successfull");
          dispatch(login(signUpResponse.data.token));
        } else {
  
          dispatch(returnErrors("Error signing up", signUpResponse.error));
        }
      } else {

        dispatch(returnErrors("OTP verification failed", 500));
      }
    } catch (error) {
      dispatch(returnErrors("Something Went Wrong", error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendEmailVarification = async () => {
    const res = await VerifyEmail({email: signupData.email});

  };
  const handleDoubleClick = (e) => {
    e.preventDefault();
  };

  return (
    <div className="signupMainCon ">
      <div className="signupCon-L">
        <div className={"signupScroller " + sliderState}>
          <FirstSection
            setSliderState={setSliderState}
            handleDataChange={handleDataChange}
            // errors={errors}
          />
          <SecondSection
            setSliderState={setSliderState}
            handleDataChange={handleDataChange}
            setSignupData={setSignupData}
          />
          <ThirdSection
            setSliderState={setSliderState}
            accreditedInvestor={accreditedInvestor}
            setAccreditedInvestor={setAccreditedInvestor}
            handleDataChange={handleDataChange}
            signupData={signupData}
            setSignupData={setSignupData}
          />
          <FourSection
            setSliderState={setSliderState}
            countryList={countryList}
            countryOfCitizenship={countryOfCitizenship}
            setCountryOfCitizenship={setCountryOfCitizenship}
            countryOfCitizenshipDA={countryOfCitizenshipDA}
            setCountryOfCitizenshipDA={setCountryOfCitizenshipDA}
            countryOfResidence={countryOfResidence}
            setCountryOfResidence={setCountryOfResidence}
            countryOfResidenceDA={countryOfResidenceDA}
            setCountryOfResidenceDA={setCountryOfResidenceDA}
            handleDataChange={handleDataChange}
            setSignupData={setSignupData}
          />
          <FiveSection
            accountType={accountType}
            setAccountType={setAccountType}
            accountSUBType={accountSUBType}
            setAccountSUBType={setAccountSUBType}
            setSliderState={setSliderState}
            handleDataChange={handleDataChange}
            setSignupData={setSignupData}
            dispatch={dispatch}
          />
          <SixtSection
            setSliderState={setSliderState}
            accountType={accountType}
            accountSUBType={accountSUBType}
            countryList={countryList}
            cityList={cityList}
            city={city}
            setCity={setCity}
            cityDA={cityDA}
            setCityDA={setCityDA}
            stateList={stateList}
            stateAddress={stateAddress}
            setStateAddress={setStateAddress}
            stateAddressDA={stateAddressDA}
            setStateAddressDA={setStateAddressDA}
            phonenumber={phonenumber}
            setPhoneNumber={setPhoneNumber}
            entityList={entityList}
            entity={entity}
            setEntity={setEntity}
            entityDA={entityDA}
            setEntityDA={setEntityDA}
            juridiction={juridiction}
            setJuridiction={setJuridiction}
            juridictionDA={juridictionDA}
            setJuridictionDA={setJuridictionDA}
            accountSignupCheck={accountSignupCheck}
            setAccountSignupCheck={setAccountSignupCheck}
            jointAccountList={jointAccountList}
            jointAccount={jointAccount}
            setJointAccount={setJointAccount}
            jointAccountDA={jointAccountDA}
            setJointAccountDA={setJointAccountDA}
            jointAccCity={jointAccCity}
            setJointAccCity={setJointAccCity}
            jointAccCityDA={jointAccCityDA}
            setJointAccCityDA={setJointAccCityDA}
            jointAccState={jointAccState}
            setJointAccState={setJointAccState}
            jointAccStateDA={jointAccStateDA}
            setJointAccStateDA={setJointAccStateDA}
            percentOwnership={percentOwnership}
            setPercentOwnership={setPercentOwnership}
            handleDataChange={handleDataChange}
            setSignupData={setSignupData}
            handleFileChange={handleFileChange}
            handleFileChangeOprating={handleFileChangeOprating}
            handleUpload={handleUpload}
            handleUploadOprating={handleUploadOprating}
            selectedFile={selectedFile}
            selectedFileOprating={selectedFileOprating}
            individualAddressLine1={individualAddressLine1}
            setIndividualAddressLine1={setIndividualAddressLine1}
            individualAddressLine2={individualAddressLine2}
            setIndividualAddressLine2={setIndividualAddressLine2}
            setIndividualZipCode={setIndividualZipCode}
            individualZipCode={individualZipCode}
            individualDOB={individualDOB}
            setIndividualDOB={setIndividualDOB}
            entityName={entityName}
            setEntityName={setEntityName}
            setEntityEIN={setEntityEIN}
            entityEIN={entityEIN}
            setEntityIndusty={setEntityIndusty}
            entityIndustry={entityIndustry}
            setEntityDateFormation={setEntityDateFormation}
            entityDateFormation={entityDateFormation}
            jurisdiction={jurisdiction}
            setJurisdiction={setJurisdiction}
            setJointAddress1={setJointAddress1}
            jointAddress1={jointAddress1}
            setJointAddress2={setJointAddress2}
            jointAddress2={jointAddress2}
            setJointZipCode={setJointZipCode}
            jointZipCode={jointZipCode}
            setJointPhoneNumber={setJointPhoneNumber}
            jointPhoneNumber={jointPhoneNumber}
            jointSocialSecurity={jointSocialSecurity}
            setJointSocialSecurity={setJointSocialSecurity}
            jointDateFormation={jointDateFormation}
            setJointDateFormation={setJointDateFormation}
            jointName={jointName}
            setJointName={setJointName}
            jointSurname={jointSurname}
            setJointSurname={setJointSurname}
            setJointSocialSecurityHolder2={setJointSocialSecurityHolder2}
            jointSocialSecurityHolder2={jointSocialSecurityHolder2}
            trustAccountType={trustAccountType}
            setTrustAccountType={setTrustAccountType}
            trustAddress1={trustAddress1}
            setTrustAddress1={setTrustAddress1}
            trustAddress2={trustAddress2}
            setTrustAddress2={setTrustAddress2}
            setTrustAccCity={setTrustAccCity}
            trusAccCity={trusAccCity}
            setTrustPhoneNumber={setTrustPhoneNumber}
            trustPhoneNumber={trustPhoneNumber}
            setTrustAccState={setTrustAccState}
            trustAccState={trustAccState}
            trustZipcode={trustZipcode}
            setTrustZipCode={setTrustZipCode}
            setTrustSocialSecurity={setTrustSocialSecurity}
            trustSocialSecurity={trustSocialSecurity}
            setTrustDateFormation={setTrustDateFormation}
            trustDateFormation={trustDateFormation}
            trustDateFormationHolder2={trustDateFormationHolder2}
            setTrustDateFormationHolder2={setTrustDateFormationHolder2}
            trustSocialSecurityHolder2={trustSocialSecurityHolder2}
            setTrustSocialSecurityHolder2={setTrustSocialSecurityHolder2}
            trustSurnameHolder2={trustSurnameHolder2}
            setTrustSurnameHolder2={setTrustSurnameHolder2}
            trustNameHolder2={trustNameHolder2}
            setTrustNameHolder2={setTrustNameHolder2}
          />
          <SevenSection
            setSliderState={setSliderState}
            accountType={accountType}
            accountSUBType={accountSUBType}
            amountList={amountList}
            initialContri={initialContri}
            setInitialContri={setInitialContri}
            initialContriDA={initialContriDA}
            setInitialContriDA={setInitialContriDA}
            setAutoInvest={setAutoInvest}
            autoInvest={autoInvest}
            autoAmountList={autoAmountList}
            autoInvestAmount={autoInvestAmount}
            setAutoInvestAmount={setAutoInvestAmount}
            autoInvestDA={autoInvestDA}
            setAutoInvestDA={setAutoInvestDA}
            frequencyList={frequencyList}
            frequency={frequency}
            setFrequency={setFrequency}
            frequencyDA={frequencyDA}
            setFrequencyDA={setFrequencyDA}
            paymentDayList={paymentDayList}
            paymentDay={paymentDay}
            setPaymentDay={setPaymentDay}
            paymentDayDA={paymentDayDA}
            setPaymentDayDA={setPaymentDayDA}
            handleDataChange={handleDataChange}
          />
          <EightSection
            setSliderState={setSliderState}
            accountType={accountType}
            accountSUBType={accountSUBType}
            linkedAccount={linkedAccount}
            setLinkedAccount={setLinkedAccount}
            bankAccountTypeList={bankAccountTypeList}
            bankAccount={bankAccount}
            setBankAccount={setBankAccount}
            bankAccountDA={bankAccountDA}
            setBankAccountDA={setBankAccountDA}
            handleDataChange={handleDataChange}
            bankName={bankName}
            setBankName={setBankName}
            RoutingNumber={RoutingNumber}
            setRoutingNumber={setRoutingNumber}
            AccountNumber={AccountNumber}
            setAccountNumber={setAccountNumber}
            confirmAccountNumber={confirmAccountNumber}
            setConfirmAccountNumber={setConfirmAccountNumber}
          />
          <NineSection
            setSliderState={setSliderState}
            accountType={accountType}
            accountSUBType={accountSUBType}
            acknowledgementsChecks={acknowledgementsChecks}
            setAcknowledgementsChecks={setAcknowledgementsChecks}
            handleDataChange={handleDataChange}
            sliderState={sliderState}
            signupData={signupData}
            handleSendEmailVarification={handleSendEmailVarification}
            dispatch={dispatch}
          />
          <TenSection
            setSliderState={setSliderState}
            accountType={accountType}
            accountSUBType={accountSUBType}
            confrimEmailChecks={confrimEmailChecks}
            setConfrimEmailChecks={setConfrimEmailChecks}
            handleDataChange={handleDataChange}
            handleSignupUser={handleSignupUser}
            isLoading={isLoading}
            emailVerifyData={emailVerifyData}
            setConfirmationCode={setConfirmationCode}
            setVerifyUserEmailOtp={setVerifyUserEmailOtp}
          />
        </div>
      </div>
      <div className="signupCon-R">
        {/* {sliderState} */}
        {sliderState === "ten" ? (
          <>
            <img
              className="imgemailConfirm"
              src={emailConfirm}
              alt="image"
              onDoubleClick={handleDoubleClick}
              style={{userSelect: "none"}}
            />
          </>
        ) : (
          <img
            className="imgOneSignup"
            src={imgOne}
            alt=""
            onDoubleClick={handleDoubleClick}
            style={{userSelect: "none"}}
          />
        )}
      </div>
    </div>
  );
};

export default Signup;

// 1 Create Your Account
// 2 Did you receive an email address from your organization?
// 3 Are you an accredited investor?
// 4 We currently only accept investment from US residents.
// 5 Which account type would you like to open?
// 6 Contact Information (INDIVIDUAL)

function FirstSection({setSliderState, handleDataChange}) {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    surname: "",
    companyName: "",
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const {name, value} = e.target;
    switch (name) {
      case "name":
        setName(value);
        setErrors((prev) => ({
          ...prev,
          name: value.trim() === "" ? "Name is required" : "",
        }));
        break;
      case "surname":
        setSurname(value);
        setErrors((prev) => ({
          ...prev,
          surname: value.trim() === "" ? "Surname is required" : "",
        }));
        break;
      case "companyName":
        setCompanyName(value);
        setErrors((prev) => ({
          ...prev,
          companyName: value.trim() === "" ? "Company name is required" : "",
        }));
        break;
      case "email":
        setEmail(value);
        const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        setErrors((prev) => ({
          ...prev,
          email: value.trim() === "" ? "Email is required" : !emailValid ? "Email is not valid" : "",
        }));
        break;
      case "password":
        setPassword(value);
        setErrors((prev) => ({
          ...prev,
          password: value.trim() === "" ? "Password is required" : value.length < 8 ? "Password must be at least 8 characters long" : "",
        }));
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    // Validate all fields
    const formErrors = {
      name: name.trim() === "" ? "Name is required" : "",
      surname: surname.trim() === "" ? "Surname is required" : "",
      companyName: companyName.trim() === "" ? "Company name is required" : "",
      email: email.trim() === "" ? "Email is required" : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ? "Email is not valid" : "",
      password: password.trim() === "" ? "Password is required" : password.length < 8 ? "Password must be at least 8 characters long" : "",
    };

    setErrors(formErrors);

    // Check if all fields are valid
    const isValid = Object.values(formErrors).every((error) => error === "");

    if (isValid) {
      handleDataChange({
        target: {
          name: "name",
          value: name,
        },
      });
      handleDataChange({
        target: {
          name: "surname",
          value: surname,
        },
      });
      handleDataChange({
        target: {
          name: "companyName",
          value: companyName,
        },
      });
      handleDataChange({
        target: {
          name: "email",
          value: email,
        },
      });
      handleDataChange({
        target: {
          name: "password",
          value: password,
        },
      });

      setSliderState("two");
    }
  };

  return (
    <div className="signupFirstSection signupsection">
      <h1>Create Your Account</h1>
      <h2>Get started with Lilypads and invest wisely.</h2>
      <div className="inputFlexLine">
        <div>
          <InputBox
            label="name"
            name="name"
            type="text"
            value={name}
            onChange={handleInputChange}
            error={errors.name}
          />
          {errors.name && <div className="error-message">{errors.name}</div>}
        </div>
        <div>
          <InputBox
            label="Surname"
            name="surname"
            type="text"
            value={surname}
            onChange={handleInputChange}
            error={errors.surname}
          />
          {errors.surname && <div className="error-message">{errors.surname}</div>}
        </div>
      </div>
      <InputBox
        label="Company name"
        name="companyName"
        type="text"
        value={companyName}
        onChange={handleInputChange}
        error={errors.companyName}
      />
      {errors.companyName && <div className="error-message">{errors.companyName}</div>}
      <InputBox
        label="business E-mail address"
        name="email"
        type="email"
        value={email}
        onChange={handleInputChange}
        error={errors.email}
      />
      {errors.email && <div className="error-message">{errors.email}</div>}
      <InputBox
        label="Password"
        name="password"
        type="password"
        value={password}
        onChange={handleInputChange}
        error={errors.password}
      />
      {errors.password && <div className="error-message">{errors.password}</div>}
      <PrimaryButton
        name="Get Started with Lilypads!"
        onclick={handleSubmit}
      />

      <h3>
        Already on Lilypads?
        <Link to="/signin">
          <span>Sign In.</span>
        </Link>
      </h3>
    </div>
  );
}

function SecondSection({setSliderState, handleDataChange, setSignupData}) {
  const [isChecked, setIsChecked] = useState(false);
  const [showValidationMessage, setShowValidationMessage] = useState(false);

  const handleSubmit = () => {
    if (isChecked) {
      handleDataChange({
        target: {
          name: "userEmailVerified",
          value: true,
        },
      });
      setSliderState("three");
    } else {
      setShowValidationMessage(true);
    }
  };

  const handleCheckboxClick = () => {
    setIsChecked(!isChecked);
    if (showValidationMessage) {
      setShowValidationMessage(false);
    }
  };

  return (
    <div className="signupSecondSection signupsection">
      <h1>Did you receive an email address from your organization?</h1>
      <h2>
        Lilypads is designed for use by employees in the organization, so the email address can be seen by other users who log on using . If the e-mail address has not been provided by your
        organization, you cannot use it in Lilypads. Addresses from free services, such as outlook.com or from general email service providers, are not allowed.
      </h2>

      <div style={{display: "flex ", gap: "20px", alignItems: "baseline", marginTop: "14px"}}>
        <div
          className="checkBoxCon"
          onClick={handleCheckboxClick}
        >
          {isChecked ? <CheckmarkTICK /> : <Checkmark />}
        </div>
        <h2>
          By registering I agree with <a href="#">Term & Conditions.</a>{" "}
        </h2>
      </div>

      {showValidationMessage && <div className="error-message">You must agree to the terms and conditions to proceed.</div>}

      <div className="bottomPosiLock">
        <PrimaryButton
          name="Yes, use this e-mail"
          onclick={handleSubmit}
        />
        <div className="spacerMTM30"></div>
        <PrimaryButton
          name="No, I'll use another"
          onclick={handleSubmit}
          classNames="secondary"
        />{" "}
        <h3>
          Already on Lilypads?
          <Link to="/sigin">
            <span>Sign In.</span>
          </Link>
        </h3>
      </div>
    </div>
  );
}

function ThirdSection({setSliderState, handleDataChange, signupData, setSignupData}) {
  const [errors, setErrors] = useState({
    accreditedInvestor: "",
  });

  const handleToggle = (value) => {
    setSignupData((prev) => ({...prev, accreditedInvestor: value}));
    setErrors((prev) => ({
      ...prev,
      accreditedInvestor: "",
    }));
  };

  const handleSubmit = () => {
    const formErrors = {
      accreditedInvestor: signupData.accreditedInvestor === undefined ? "Please select if you are an accredited investor or not." : "",
    };

    setErrors(formErrors);
    const isValid = Object.values(formErrors).every((error) => error === "");

    if (isValid) {
      setSliderState("four");
    }
  };

  return (
    <div className="signupThirdSection signupsection">
      <h1>Are you an accredited investor?</h1>
      <h2>
        An accredited investor is a person or a business entity who is allowed to deal in securities that may not be registered with financial authorities.
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.16797 14.168H10.8346V9.16797H9.16797V14.168ZM10.0013 1.66797C5.4013 1.66797 1.66797 5.4013 1.66797 10.0013C1.66797 14.6013 5.4013 18.3346 10.0013 18.3346C14.6013 18.3346 18.3346 14.6013 18.3346 10.0013C18.3346 5.4013 14.6013 1.66797 10.0013 1.66797ZM10.0013 16.668C6.3263 16.668 3.33464 13.6763 3.33464 10.0013C3.33464 6.3263 6.3263 3.33464 10.0013 3.33464C13.6763 3.33464 16.668 6.3263 16.668 10.0013C16.668 13.6763 13.6763 16.668 10.0013 16.668ZM9.16797 7.5013H10.8346V5.83463H9.16797V7.5013Z"
            fill="#3E3F42"
          />
        </svg>
      </h2>
      <div className="spacerMT4"></div>
      <div
        className={`radioButtonCon ${signupData.accreditedInvestor && "active"}`}
        onClick={() => handleToggle(true)}
      >
        <div className="radioButtonO">
          <div className="radioButtonI"></div>
        </div>
        Yes, I'm an accredited investor
      </div>
      <div
        className={`radioButtonCon ${!signupData.accreditedInvestor && "active"}`}
        onClick={() => handleToggle(false)}
      >
        <div className="radioButtonO">
          <div className="radioButtonI"></div>
        </div>
        No, I'm not an accredited investor
      </div>

      {errors.accreditedInvestor && <div className="error-message">{errors.accreditedInvestor}</div>}

      <div className="bottomPosiLock">
        <div className="flexlineC">
          <SmallSvgButton
            onclick={() => {
              setSliderState("two");
            }}
            secondary={true}
            name="Back"
          />
          <SmallSvgButton
            onclick={handleSubmit}
            name="Continue"
          />
        </div>
        <h3>
          Already on Lilypads?
          <Link to="/signin">
            <span>Sign In.</span>
          </Link>
        </h3>
      </div>
    </div>
  );
}

function FourSection({
  setSliderState,
  countryList,
  setCountryOfCitizenship,
  countryOfCitizenshipDA,
  setCountryOfCitizenshipDA,
  setCountryOfResidence,
  countryOfResidenceDA,
  setCountryOfResidenceDA,
  setSignupData,
}) {
  const [selectedCountryOfCitizenship, setSelectedCountryOfCitizenship] = useState("");
  const [selectedCountryOfResidence, setSelectedCountryOfResidence] = useState("");
  const [errors, setErrors] = useState({
    countryOfCitizenship: "",
    countryOfResidence: "",
  });

  const handleSubmit = () => {
    const formErrors = {
      countryOfCitizenship: selectedCountryOfCitizenship === "" ? "Please select your country of citizenship." : "",
      countryOfResidence: selectedCountryOfResidence === "" ? "Please select your country of residence." : "",
    };

    setErrors(formErrors);

    // Check if all fields are valid
    const isValid = Object.values(formErrors).every((error) => error === "");

    if (isValid) {
      setSignupData((prev) => ({
        ...prev,
        countryOfCitizenship: selectedCountryOfCitizenship,
        countryOfResidence: selectedCountryOfResidence,
      }));
      setSliderState("five");
    }
  };

  return (
    <div className="signupFourthSection signupsection">
      <h1>We currently only accept investment from US residents.</h1>
      <h2>Please specify your location details.</h2>

      <DropDown
        label="COUNTRY OF CITIZENSHIP"
        data={countryList}
        selectedData={selectedCountryOfCitizenship}
        setSelectedData={setSelectedCountryOfCitizenship}
        dropdownState={countryOfCitizenshipDA}
        dropdownSetter={setCountryOfCitizenshipDA}
      />
      {errors.countryOfCitizenship && <div className="error-message">{errors.countryOfCitizenship}</div>}

      <DropDown
        label="COUNTRY OF RESIDENCE"
        data={countryList}
        selectedData={selectedCountryOfResidence}
        setSelectedData={setSelectedCountryOfResidence}
        dropdownState={countryOfResidenceDA}
        dropdownSetter={setCountryOfResidenceDA}
      />
      {errors.countryOfResidence && <div className="error-message">{errors.countryOfResidence}</div>}

      <div className="bottomPosiLock">
        <div className="flexlineC">
          <SmallSvgButton
            onclick={() => {
              setSliderState("three");
            }}
            secondary={true}
            name="Back"
          />
          <SmallSvgButton
            onclick={handleSubmit}
            name="Continue"
          />
        </div>
        <h3>
          Already on Lilypads?
          <Link to="/signin">
            <span>Sign In.</span>
          </Link>
        </h3>
      </div>
    </div>
  );
}

function FiveSection({setSliderState, setAccountType, accountType, accountSUBType, setAccountSUBType, setSignupData, dispatch}) {
  return (
    <div className="signupFifthSection signupsection">
      <h1>Which account type would you like to open?</h1>
      <h2>Let’s finish registering your account.</h2>

      <div
        onClick={() => {
          if (accountType === "investor") {
            setAccountType("");
            setSignupData((prev) => ({...prev, accountType: ""}));
          } else {
            setAccountType("investor");
            setSignupData((prev) => ({...prev, accountType: "investor"}));
          }
        }}
        className={"AccountSeclector_Individual " + (accountType === "investor" ? "active" : "")}
      >
        {accountType === "investor" ? <IndividualActive /> : <IndividualDisable />}
        Individual
        <div className="accountTypeCheck">{accountType === "investor" ? <CheckmarkTICK /> : <Checkmark />}</div>
      </div>

      <div
        onClick={() => {
          if (accountType === "Organization") {
            setAccountType("");
            setAccountSUBType("");
            setSignupData((prev) => ({...prev, accountType: "", accountTypeChoosen: ""}));
          } else {
            setAccountType("Organization");
            setSignupData((prev) => ({...prev, accountType: "organization"}));
            if (accountSUBType === "") {
              setAccountSUBType("Entity");
            }
          }
        }}
        className={"AccountSeclector_Individual org " + (accountType === "Organization" ? "active" : "")}
      >
        <div className="AccountSeclector_Individual-T">
          {accountType === "Organization" ? <OrganizationActive /> : <OrganizationDisable />}
          Organization
        </div>

        {accountType === "Organization" && (
          <div className="organisationExpand">
            <div
              onClick={(e) => {
                e.stopPropagation();
                if (accountSUBType === "Entity") {
                  setAccountSUBType("");
                  setSignupData((prev) => ({...prev, accountTypeChoosen: ""}));
                } else {
                  setAccountType("Organization");
                  setAccountSUBType("Entity");
                  setSignupData((prev) => ({...prev, accountTypeChoosen: "entity"}));
                }
              }}
              className={"radioButtonCon accountselector " + (accountSUBType === "Entity" ? "active" : "")}
            >
              <div className="radioButtonO">
                <div className="radioButtonI"></div>
              </div>
              Entity Account
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                if (accountSUBType === "Joint") {
                  setAccountSUBType("");
                  setSignupData((prev) => ({...prev, accountTypeChoosen: ""}));
                } else {
                  setAccountType("Organization");
                  setAccountSUBType("Joint");
                  setSignupData((prev) => ({...prev, accountTypeChoosen: "joint"}));
                }
              }}
              className={"radioButtonCon accountselector " + (accountSUBType === "Joint" ? "active" : "")}
            >
              <div className="radioButtonO">
                <div className="radioButtonI"></div>
              </div>
              Joint Account
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                if (accountSUBType === "Trust") {
                  setAccountSUBType("");
                  setSignupData((prev) => ({...prev, accountTypeChoosen: ""}));
                } else {
                  setAccountType("Organization");
                  setAccountSUBType("Trust");
                  setSignupData((prev) => ({...prev, accountTypeChoosen: "trust"}));
                }
              }}
              className={"radioButtonCon accountselector " + (accountSUBType === "Trust" ? "active" : "")}
            >
              <div className="radioButtonO">
                <div className="radioButtonI"></div>
              </div>
              Trust Account
            </div>
          </div>
        )}

        <div className="accountTypeCheck">{accountType === "Organization" ? <CheckmarkTICK /> : <Checkmark />}</div>
      </div>

      <div className="bottomPosiLock">
        <div className="flexlineC">
          <SmallSvgButton
            onclick={() => {
              setSliderState("four");
            }}
            secondary={true}
            name="Back"
          />
          <SmallSvgButton
            onclick={() => {
              if (accountType === "") {
                dispatch(returnErrors("Select Account Type", "error", "Error"));
                return;
              }
              if (accountType === "investor") {
                setSliderState("six");
                return;
              }
              if (accountType === "Organization" && (accountSUBType === "Entity" || accountSUBType === "Joint" || accountSUBType === "Trust")) {
                setSliderState("six");
              } else {
                dispatch(returnErrors("Select Organization Type", "error", "Error"));
              }
            }}
            name="Continue"
          />
        </div>
        <h3>
          Already on Lilypads?
          <Link to="/signin">
            <span>Sign In.</span>
          </Link>
        </h3>
      </div>
    </div>
  );
}

function SixtSection({
  setSliderState,
  accountType,
  accountSUBType,
  city,
  cityList,
  setCity,
  cityDA,
  setCityDA,
  stateList,
  stateAddress,
  setStateAddress,
  stateAddressDA,
  setStateAddressDA,
  countryList,
  phonenumber,
  setPhoneNumber,
  entityList,
  // entity,
  // setEntity,
  // entityDA,
  // setEntityDA,
  // juridiction,
  // setJuridiction,
  // juridictionDA,
  // setJuridictionDA,
  accountSignupCheck,
  setAccountSignupCheck,
  jointAccount,
  setJointAccount,
  // jointAccountDA,
  // setJointAccountDA,
  jointAccountList,
  jointAccCity,
  setJointAccCity,
  jointAccCityDA,
  setJointAccCityDA,
  jointAccState,
  setJointAccState,
  jointAccStateDA,
  setJointAccStateDA,
  percentOwnership,
  setPercentOwnership,
  setSignupData,
  handleUpload,
  handleUploadOprating,
  handleFileChange,
  handleFileChangeOprating,
  selectedFile,
  selectedFileOprating,
  setIndividualAddressLine1,
  individualAddressLine1,
  setIndividualAddressLine2,
  individualAddressLine2,
  individualZipCode,
  setIndividualZipCode,
  setIndividualDOB,
  individualDOB,
  // setEntityName,
  // entityName,
  // entityEIN,
  // setEntityEIN,
  // entityIndustry,
  // setEntityIndusty,
  // entityDateFormation,
  // setEntityDateFormation,
  // jurisdiction,
  // setJurisdiction,
  jointAddress1,
  setJointAddress1,
  jointAddress2,
  setJointAddress2,
  jointZipCode,
  setJointZipCode,
  jointPhoneNumber,
  setJointPhoneNumber,
  setJointSocialSecurity,
  jointSocialSecurity,
  setJointDateFormation,
  jointDateFormation,
  jointName,
  setJointName,
  jointSurname,
  setJointSurname,
  jointSocialSecurityHolder2,
  setJointSocialSecurityHolder2,
  trustAccountType,
  setTrustAccountType,
  setTrustAddress1,
  trustAddress1,
  setTrustAddress2,
  trustAddress2,
  trusAccCity,
  setTrustAccCity,
  setTrustPhoneNumber,
  trustPhoneNumber,
  setTrustAccState,
  trustAccState,
  trustZipcode,
  setTrustZipCode,
  trustSocialSecurity,
  setTrustSocialSecurity,
  trustDateFormation,
  setTrustDateFormation,
  trustDateFormationHolder2,
  setTrustDateFormationHolder2,
  trustSocialSecurityHolder2,
  setTrustSocialSecurityHolder2,
  trustSurnameHolder2,
  setTrustSurnameHolder2,
  trustNameHolder2,
  setTrustNameHolder2,
}) {
  const [entityName, setEntityName] = useState("");
  const [entity, setEntity] = useState("");
  const [entityEIN, setEntityEIN] = useState("");
  const [entityIndustry, setEntityIndusty] = useState("");
  const [entityDateFormation, setEntityDateFormation] = useState("");
  const [jurisdiction, setJurisdiction] = useState("");
  const [entityDA, setEntityDA] = useState(false);
  const [jointAccountDA, setJointAccountDA] = useState(false);
  const [errors, setErrors] = useState({
    entityName: "",
    entity: "",
    entityEIN: "",
    entityIndustry: "",
    entityDateFormation: "",
    jurisdiction: "",
  });
  const [errorsJoint, setErrorsJoint] = useState({
    jointAccount: "",
    jointAddress1: "",
    jointAddress2: "",
    jointAccCity: "",
    jointAccState: "",
    jointZipCode: "",
    jointPhoneNumber: "",
    percentOwnership: "",
    jointSocialSecurity: "",
    jointDateFormation: "",
    jointName: "",
    jointSurname: "",
    jointSocialSecurityHolder2: "",
  });
  const validateFieldsJoint = () => {
    const newErrors = {
      jointAccount: jointAccount === "" ? "Joint Account Type is required" : "",
      jointAddress1: jointAddress1.trim() === "" ? "Address Line 1 is required" : "",
      jointAddress2: jointAddress2.trim() === "" ? "Address Line 2 is required" : "",
      jointAccCity: jointAccCity === "" ? "City is required" : "",
      jointAccState: jointAccState === "" ? "State is required" : "",
      jointZipCode: jointZipCode.trim() === "" ? "ZIP Code is required" : "",
      jointPhoneNumber: jointPhoneNumber === "" ? "Phone Number is required" : "",
      percentOwnership: percentOwnership === "" ? "Percent Ownership is required" : "",
      jointSocialSecurity: jointSocialSecurity.trim() === "" ? "Social Security Number is required" : "",
      jointDateFormation: jointDateFormation.trim() === "" ? "Date of Formation is required" : "",
      jointName: jointName.trim() === "" ? "Name is required" : "",
      jointSurname: jointSurname.trim() === "" ? "Surname is required" : "",
      jointSocialSecurityHolder2: jointSocialSecurityHolder2.trim() === "" ? "Social Security Number is required" : "",
    };
    setErrorsJoint(newErrors);

    return Object.values(newErrors).every((error) => error === "");
  };

  const Joint = () => {
    if (validateFieldsJoint()) {
      setSliderState("seven");
    }
  };

  const validateFields = () => {
    const newErrors = {
      entityName: entityName.trim() === "" ? "Entity Name is required" : "",
      entity: entity === "" ? "Entity Type is required" : "",
      entityEIN: entityEIN.trim() === "" ? "EIN is required" : "",
      entityIndustry: entityIndustry.trim() === "" ? "Industry is required" : "",
      entityDateFormation: entityDateFormation.trim() === "" ? "Date of Formation is required" : "",
      jurisdiction: jurisdiction === "" ? "Jurisdiction is required" : "",
    };

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === "");
  };

  const handleSubmit = () => {
    if (validateFields()) {
      setSliderState("seven");
    }
  };
  return (
    <>
      {accountType === "Individual" && (
        <div className="signupFirstSection signupsection">
          <h1>Contact Information</h1>
          <h2>Lilypads needs a few more details about you.</h2>
          {/* <InputBox
            label="ADDRESS LINE 1"
            type="text"
          />{" "} */}
          <div className="Individual_address_line_1">
            <label htmlFor="address_line_1">ADDRESS LINE 1</label>
            <input
              type="text"
              id="address_line_1"
              onChange={(e) => setIndividualAddressLine1(e.target.value)}
              value={individualAddressLine1}
            />
          </div>
          <h2 style={{opacity: "0.8"}}>This should be the address used for tax purposes.</h2>
          {/* <InputBox
            label="ADDRESS LINE 2"
            type="text"
          /> */}
          <div className="Individual_address_line_1">
            <label htmlFor="address_line_1">ADDRESS LINE 2</label>
            <input
              type="text"
              id="address_line_1"
              onChange={(e) => setIndividualAddressLine2(e.target.value)}
              value={individualAddressLine2}
            />
          </div>
          <DropDown
            label="CITY"
            data={cityList}
            selectedData={city}
            setSelectedData={setCity}
            dropdownState={cityDA}
            dropdownSetter={setCityDA}
          />
          <div className="inputFlexLine">
            <DropDown
              classDataName="small135"
              label="State"
              data={stateList}
              selectedData={stateAddress}
              setSelectedData={setStateAddress}
              dropdownState={stateAddressDA}
              dropdownSetter={setStateAddressDA}
            />

            <div className="Individual_address_line_1">
              <label htmlFor="zip_code_individual">ZIP CODE</label>
              <input
                type="text"
                name=""
                id=""
                value={individualZipCode}
                onChange={(e) => setIndividualZipCode(e.target.value)}
              />
            </div>
          </div>
          <div className="phoneInpCon">
            <PhoneInput
              international
              defaultCountry="US"
              placeholder="Enter phone number"
              value={phonenumber}
              onChange={setPhoneNumber}
            />
          </div>
          {/* <InputBox
            label="Birthday Date"
            type="date"
          /> */}
          <div className="Individual_address_line_1">
            <label htmlFor="Birthday">Birthday Date</label>
            <input
              type="date"
              value={individualDOB}
              onChange={(e) => setIndividualDOB(e.target.value)}
            />
          </div>
          <div className="bottomPosiLock">
            <div className="flexlineC">
              <SmallSvgButton
                onclick={() => {
                  setSliderState("five");
                }}
                secondary={true}
                name="Back"
              />
              <SmallSvgButton
                onclick={() => {
                  setSliderState("seven");
                }}
                name="Continue"
              />
            </div>
            <h3>
              Already on Lilypads? <span>Sign In.</span>
            </h3>
          </div>
        </div>
      )}
      {accountType === "Organization" && accountSUBType === "Entity" && (
        <div className="signupSixthSection signupsection">
          <h1>Entity Information</h1>
          <h2>We just need a few more details.</h2>

          <div className="entity_input">
            <label htmlFor="">Entity Name</label>
            <input
              type="text"
              value={entityName}
              onChange={(e) => setEntityName(e.target.value)}
            />
            {errors.entityName && <div className="error-message">{errors.entityName}</div>}
          </div>

          <DropDown
            label="Entity Type"
            data={entityList}
            selectedData={entity}
            setSelectedData={setEntity}
            dropdownState={entityDA}
            dropdownSetter={setEntityDA}
          />
          {errors.entity && <div className="error-message">{errors.entity}</div>}

          <div className="entity_input">
            <label htmlFor="">EIN</label>
            <input
              type="text"
              placeholder={"___-______"}
              value={entityEIN}
              onChange={(e) => setEntityEIN(e.target.value)}
            />
            {errors.entityEIN && <div className="error-message">{errors.entityEIN}</div>}
          </div>

          <div className="entity_input">
            <label htmlFor="">Industry</label>
            <input
              type="text"
              value={entityIndustry}
              onChange={(e) => setEntityIndusty(e.target.value)}
            />
            {errors.entityIndustry && <div className="error-message">{errors.entityIndustry}</div>}
          </div>

          <div className="entity_input">
            <label htmlFor="">Date of Formation</label>
            <input
              type="date"
              value={entityDateFormation}
              onChange={(e) => setEntityDateFormation(e.target.value)}
            />
            {errors.entityDateFormation && <div className="error-message">{errors.entityDateFormation}</div>}
          </div>

          <DropDown
            label="Jurisdiction of Registration"
            data={jointAccountList}
            selectedData={jurisdiction}
            setSelectedData={setJurisdiction}
            dropdownState={jointAccountDA}
            dropdownSetter={setJointAccountDA}
          />
          {errors.jurisdiction && <div className="error-message">{errors.jurisdiction}</div>}

          <div
            className="entity_upload_buttons"
            style={{display: "flex", flexDirection: "column", gap: "20px"}}
          >
            <div>
              <label
                htmlFor="operatingUpload"
                style={{color: "var(--LP-black, #3e3f42)", fontWeight: "400"}}
              >
                Upload Operating Agreement {selectedFileOprating && <button onClick={handleUploadOprating}>Upload</button>}
              </label>
              <div class="upload-btn-wrapper">
                <button class="btn">
                  <img
                    src={uploadIcon}
                    alt=""
                  />
                  Drag and drop files or click
                </button>
                <input
                  type="file"
                  id="operatingUpload"
                  accept="image/jpeg, image/png, application/pdf"
                  onChange={handleFileChangeOprating}
                  style={{display: "none"}}
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="certificateUpload"
                style={{color: "var(--LP-black, #3e3f42)", fontWeight: "400"}}
              >
                Upload Certificate of Formation {selectedFile && <button onClick={handleUpload}>Upload</button>}
              </label>
              <div class="upload-btn-wrapper">
                <button class="btn">
                  <img
                    src={uploadIcon}
                    alt=""
                  />
                  Drag and drop files or click
                </button>
                <input
                  type="file"
                  id="certificateUpload"
                  accept="image/jpeg, image/png, application/pdf"
                  onChange={handleFileChange}
                  style={{display: "none"}}
                />
              </div>
            </div>
          </div>

          <div
            className="acknowlegeCON"
            onClick={() => setAccountSignupCheck(!accountSignupCheck)}
          >
            {accountSignupCheck ? <CheckmarkTICK /> : <Checkmark />}
            <h2>I will provide the documents later. I acknowledge that my investment will not be processed until I provide this document.</h2>
          </div>

          <div className="flexlineC">
            <SmallSvgButton
              onclick={() => {
                setSliderState("five");
              }}
              secondary={true}
              name="Back"
            />
            <SmallSvgButton
              onclick={handleSubmit}
              name="Continue"
            />
          </div>
          <h3>
            Already on Lilypads? <span>Sign In.</span>
          </h3>
        </div>
      )}
      {accountType === "Organization" && accountSUBType === "Joint" && (
        <div className="signupSixthSection signupsection">
          <h1>Joint Account</h1>
          <h2>We just need a few more details.</h2>
          <DropDown
            label="Joint Account Type"
            data={jointAccountList}
            selectedData={jointAccount}
            setSelectedData={setJointAccount}
            dropdownState={jointAccountDA}
            dropdownSetter={setJointAccountDA}
          />
          {errorsJoint.jointAccount && <div className="error-message">{errorsJoint.jointAccount}</div>}

          <div className="spacerLine30"></div>
          <h2>Account Holder #1</h2>
          <div className="joint_details">
            <label htmlFor="">ADDRESS LINE 1</label>
            <input
              type="text"
              value={jointAddress1}
              onChange={(e) => setJointAddress1(e.target.value)}
            />
            {errorsJoint.jointAddress1 && <div className="error-message">{errorsJoint.jointAddress1}</div>}
          </div>
          <h2 style={{opacity: "0.8"}}>This should be the address used for tax purposes.</h2>
          <div className="joint_details">
            <label htmlFor="">ADDRESS LINE 2</label>
            <input
              type="text"
              value={jointAddress2}
              onChange={(e) => setJointAddress2(e.target.value)}
            />
            {errorsJoint.jointAddress2 && <div className="error-message">{errorsJoint.jointAddress2}</div>}
          </div>
          <DropDown
            label="CITY"
            data={cityList}
            selectedData={jointAccCity}
            setSelectedData={setJointAccCity}
            dropdownState={jointAccCityDA}
            dropdownSetter={setJointAccCityDA}
          />
          {errorsJoint.jointAccCity && <div className="error-message">{errorsJoint.jointAccCity}</div>}

          <div
            style={{maxWidth: ""}}
            className="inputFlexLine"
          >
            <DropDown
              label="STATE"
              data={stateList}
              selectedData={jointAccState}
              setSelectedData={setJointAccState}
              dropdownState={jointAccStateDA}
              dropdownSetter={setJointAccStateDA}
            />
            {errorsJoint.jointAccState && <div className="error-message">{errorsJoint.jointAccState}</div>}

            <div className="joint_details">
              <label htmlFor="">ZIP CODE</label>
              <input
                type="text"
                value={jointZipCode}
                onChange={(e) => setJointZipCode(e.target.value)}
              />
              {errorsJoint.jointZipCode && <div className="error-message">{errorsJoint.jointZipCode}</div>}
            </div>
          </div>
          <div className="phoneInpCon">
            <PhoneInput
              international
              defaultCountry="US"
              placeholder="Enter phone number"
              value={jointPhoneNumber}
              onChange={setJointPhoneNumber}
            />
            {errorsJoint.jointPhoneNumber && <div className="error-message">{errorsJoint.jointPhoneNumber}</div>}
          </div>
          <div className="dropDownOuterCon ">
            <p style={{marginBottom: "0px"}}> Percent Ownership </p>
            <div className="slider_container">
              <div className="top_div inputFlexLine">
                <span>{percentOwnership}</span>
                <p>%</p>
              </div>
              <Slider
                defaultValue={percentOwnership}
                aria-label="Default"
                valueLabelDisplay="auto"
                onChange={(event) => {
                  setPercentOwnership(event.target.value);
                }}
              />
              {errorsJoint.percentOwnership && <div className="error-message">{errorsJoint.percentOwnership}</div>}
            </div>
          </div>
          <div className="joint_details">
            <label htmlFor="">Social Security Number</label>
            <input
              type="text"
              value={jointSocialSecurity}
              onChange={(e) => setJointSocialSecurity(e.target.value)}
            />
            {errorsJoint.jointSocialSecurity && <div className="error-message">{errorsJoint.jointSocialSecurity}</div>}
          </div>
          <div className="joint_details">
            <label htmlFor="">Date of Formation</label>
            <input
              type="date"
              value={jointDateFormation}
              onChange={(e) => setJointDateFormation(e.target.value)}
            />
            {errorsJoint.jointDateFormation && <div className="error-message">{errorsJoint.jointDateFormation}</div>}
          </div>
          <div className="spacerLine30"></div>
          <h2>Account Holder #2</h2>
          <div
            style={{maxWidth: ""}}
            className="inputFlexLine"
          >
            <div className="joint_details">
              <label htmlFor="">Name</label>
              <input
                type="text"
                value={jointName}
                onChange={(e) => setJointName(e.target.value)}
              />
              {errorsJoint.jointName && <div className="error-message">{errorsJoint.jointName}</div>}
            </div>
            <div className="joint_details">
              <label htmlFor="">Surname</label>
              <input
                type="text"
                value={jointSurname}
                onChange={(e) => setJointSurname(e.target.value)}
              />
            </div>
          </div>
          {/* <InputBox
            label="Social Security Number"
            type="text"
          /> */}
          <div className="joint_details">
            <label htmlFor="">Social Security Number</label>
            <input
              type="text"
              value={jointSocialSecurityHolder2}
              onChange={(e) => setJointSocialSecurityHolder2(e.target.value)}
            />
          </div>
          <InputBox
            label="Date of Formation"
            type="date"
          />
          <div
            className="acknowlegeCON"
            onClick={() => setAccountSignupCheck(!accountSignupCheck)}
          >
            {accountSignupCheck ? <CheckmarkTICK /> : <Checkmark />}
            <h2>The second person associated with this joint account has the same address and phone number.</h2>
          </div>
          <div className="flexlineC">
            <SmallSvgButton
              onclick={() => {
                setSliderState("five");
              }}
              secondary={true}
              name="Back"
            />
            <SmallSvgButton
              onclick={() => {
                setSliderState("seven");
              }}
              name="Continue"
            />
          </div>
          <h3>
            Already on Lilypads? <span>Sign In.</span>
          </h3>
        </div>
      )}
      {accountType === "Organization" && accountSUBType === "Trust" && (
        <div className="signupSixthSection signupsection">
          <h1>Trust Account</h1>
          <h2>We just need a few more details.</h2>
          <DropDown
            label="Trust Account Type"
            data={jointAccountList}
            selectedData={trustAccountType}
            setSelectedData={setTrustAccountType}
            dropdownState={jointAccountDA}
            dropdownSetter={setJointAccountDA}
          />
          {/* <InfoBox
            icon={null}
            message={
              "Note: This form of account ownership is only available to married individuals residing in Arizona, California, Idaho, Louisiana, Nevada, New Mexico, Texas, Wisconsin, or Washington."
            }
          /> */}
          <div className="spacerLine30"></div>
          <h2>Account Holder #1</h2>
          {/* <InputBox
            label="ADDRESS LINE 1"
            type="text"
            placeholder="Street"
            onChange={(e) => setSignupData((prev) => ({ ...prev, addressLine1: e.target.value }))}
          /> */}
          <div className="trus_details">
            <label htmlFor="">ADDRESS LINE 1</label>
            <input
              type="text"
              placeholder="Street"
              value={trustAddress1}
              onChange={(e) => setTrustAddress1(e.target.value)}
            />
          </div>

          <h2 style={{opacity: "0.8"}}>This should be the address used for tax purposes.</h2>
          {/* <InputBox
            label="ADDRESS LINE 2"
            type="text"
            placeholder="Apt, ZIP, etc."
          /> */}
          <div className="trus_details">
            <label htmlFor="">ADDRESS LINE 2</label>
            <input
              type="text"
              value={trustAddress2}
              placeholder="Apt, ZIP, etc."
              onChange={(e) => setTrustAddress2(e.target.value)}
            />
          </div>
          <DropDown
            label="CITY"
            data={cityList}
            selectedData={trusAccCity}
            setSelectedData={setTrustAccCity}
            dropdownState={jointAccCityDA}
            dropdownSetter={setJointAccCityDA}
          />
          <div
            style={{maxWidth: ""}}
            className="inputFlexLine"
          >
            <DropDown
              label="STATE"
              data={stateList}
              selectedData={trustAccState}
              setSelectedData={setTrustAccState}
              dropdownState={jointAccStateDA}
              dropdownSetter={setJointAccStateDA}
            />
            {/* <InputBox
              label="ZIP CODE"
              type="text"
            /> */}
            <div className="trus_details">
              <label htmlFor="">ZIP CODE</label>
              <input
                type="text"
                value={trustZipcode}
                placeholder="Apt, ZIP, etc."
                onChange={(e) => setTrustZipCode(e.target.value)}
              />
            </div>
          </div>
          <div className="phoneInpCon">
            <PhoneInput
              international
              defaultCountry="US"
              placeholder="Enter phone number"
              value={trustPhoneNumber}
              onChange={setTrustPhoneNumber}
            />
          </div>
          {/* <InputBox label="Percent Ownership" type="text" /> */}

          {/* <InputBox
            label="Social Security Number"
            type="text"
          /> */}
          <div className="trus_details">
            <label htmlFor="">Social Security Number</label>
            <input
              type="text"
              value={trustSocialSecurity}
              onChange={(e) => setTrustSocialSecurity(e.target.value)}
            />
          </div>
          {/* <InputBox
            label="Date of Formation"
            type="date"
          /> */}
          <div className="trus_details">
            <label htmlFor="">Date of Formation</label>
            <input
              type="date"
              value={trustDateFormation}
              onChange={(e) => setTrustDateFormation(e.target.value)}
            />
          </div>
          <div className="spacerLine30"></div>
          <h2>Account Holder #2</h2>
          <div
            style={{maxWidth: ""}}
            className="inputFlexLine"
          >
            {/* <InputBox
              label="Name"
              type="text"
              placeholder="Herman"
            /> */}
            <div className="trus_details">
              <label htmlFor="">Name</label>
              <input
                type="text"
                value={trustNameHolder2}
                onChange={(e) => setTrustNameHolder2(e.target.value)}
              />
            </div>
            {/* <InputBox
              label="Surname"
              type="text"
              placeholder="Hesse"
            /> */}
            <div className="trus_details">
              <label htmlFor="">Surname</label>
              <input
                type="text"
                value={trustSurnameHolder2}
                onChange={(e) => setTrustSurnameHolder2(e.target.value)}
              />
            </div>
          </div>
          {/* <InputBox
            label="Social Security Number"
            type="text"
          /> */}
          <div className="trus_details">
            <label htmlFor="">Social Security Number</label>
            <input
              type="text"
              value={trustSocialSecurityHolder2}
              onChange={(e) => setTrustSocialSecurityHolder2(e.target.value)}
            />
          </div>
          {/* <InputBox
            label="Date of Formation"
            type="date"
          /> */}

          <div className="trus_details">
            <label htmlFor="">Date of Formation</label>
            <input
              type="date"
              value={trustDateFormationHolder2}
              onChange={(e) => setTrustDateFormationHolder2(e.target.value)}
            />
          </div>
          <div
            className="acknowlegeCON"
            onClick={() => setAccountSignupCheck(!accountSignupCheck)}
          >
            {accountSignupCheck ? <CheckmarkTICK /> : <Checkmark />}
            <h2>The second person associated with this joint account has the same address and phone number.</h2>
          </div>
          <div className="flexlineC">
            <SmallSvgButton
              onclick={() => {
                setSliderState("five");
              }}
              secondary={true}
              name="Back"
            />
            <SmallSvgButton
              onclick={() => {
                setSliderState("seven");
              }}
              name="Continue"
            />
          </div>
          <h3>
            Already on Lilypads? <span>Sign In.</span>
          </h3>
        </div>
      )}
    </>
  );
}

function SevenSection({
  setSliderState,
  initialContri,
  setInitialContri,
  initialContriDA,
  setInitialContriDA,
  amountList,
  autoInvest,
  setAutoInvest,
  autoAmountList,
  autoInvestAmount,
  setAutoInvestAmount,
  autoInvestDA,
  setAutoInvestDA,
  frequencyList,
  frequency,
  setFrequency,
  frequencyDA,
  setFrequencyDA,
  paymentDayList,
  paymentDay,
  setPaymentDay,
  paymentDayDA,
  setPaymentDayDA,
}) {
  const [errors, setErrors] = useState({
    initialContri: "",
    autoInvestAmount: "",
    frequency: "",
    paymentDay: "",
  });

  const handleSubmit = () => {
    const formErrors = {
      initialContri: initialContri === "" ? "Please select an initial contribution amount." : "",
      autoInvestAmount: autoInvest && autoInvestAmount.trim() === "" ? "Please select an auto-invest amount." : "",
      frequency: autoInvest && frequency.trim() === "" ? "Please select a frequency for the auto-invest." : "",
      paymentDay: autoInvest && paymentDay.trim() === "" ? "Please select a payment day for the auto-invest." : "",
    };
    setErrors(formErrors);

    // Check if all fields are valid before proceeding
    let allFieldsValid = true;
    Object.values(formErrors).forEach((error) => {
      if (error !== "") {
        allFieldsValid = false;
      }
    });

    if (allFieldsValid) {
      setSliderState("eight");
    }
  };

  return (
    <div className="signupsevenSection signupsection">
      <h1> </h1>
      <h2>Please specify your location details.</h2>
      <DropDown
        label="Initial contribution"
        data={amountList}
        selectedData={initialContri}
        setSelectedData={(value) => {
          setInitialContri(value);
          setErrors((prev) => ({
            ...prev,
            initialContri: value === "" ? "Please select an initial contribution amount." : "",
          }));
        }}
        dropdownState={initialContriDA}
        dropdownSetter={setInitialContriDA}
        defaultValue=""
      />
      {errors.initialContri && (
        <div className="error-message">
          <span className="error-icon">!</span>
          {errors.initialContri}
        </div>
      )}

      <h2>How much should your initial contribution be?</h2>

      <div className="spacerLine30"></div>

      <div
        className="acknowlegeCON"
        onClick={() => {
          setAutoInvest(!autoInvest);
        }}
      >
        {autoInvest ? <CheckmarkTICK /> : <Checkmark />}
        <h2>
          Auto-invest(optional) <br />
          <span style={{opacity: "0.7", marginTop: "10px"}}>Auto-Invest allows you to schedule once or twice monthly recurring investments.</span>
        </h2>
      </div>
      {autoInvest ? (
        <div>
          <DropDown
            label="Auto-Invest Amount"
            data={autoAmountList}
            selectedData={autoInvestAmount}
            setSelectedData={(value) => {
              setAutoInvestAmount(value);
              setErrors((prev) => ({
                ...prev,
                autoInvestAmount: value.trim() === "" ? "Please select an auto-invest amount." : "",
              }));
            }}
            dropdownState={autoInvestDA}
            dropdownSetter={setAutoInvestDA}
            defaultValue=""
          />
          {errors.autoInvestAmount && (
            <div className="error-message">
              <span className="error-icon">!</span>
              {errors.autoInvestAmount}
            </div>
          )}
          <div
            style={{maxWidth: ""}}
            className="inputFlexLine"
          >
            <DropDown
              label="Frequency"
              data={frequencyList}
              selectedData={frequency}
              setSelectedData={(value) => {
                setFrequency(value);
                setErrors((prev) => ({
                  ...prev,
                  frequency: value.trim() === "" ? "Please select a frequency for the auto-invest." : "",
                }));
              }}
              dropdownState={frequencyDA}
              dropdownSetter={setFrequencyDA}
              defaultValue=""
            />
            {errors.frequency && (
              <div className="error-message">
                <span className="error-icon">!</span>
                {errors.frequency}
              </div>
            )}
            <DropDown
              label="Payment Day"
              data={paymentDayList()}
              selectedData={paymentDay}
              setSelectedData={(value) => {
                setPaymentDay(value);
                setErrors((prev) => ({
                  ...prev,
                  paymentDay: value.trim() === "" ? "Please select a payment day for the auto-invest." : "",
                }));
              }}
              dropdownState={paymentDayDA}
              dropdownSetter={setPaymentDayDA}
              defaultValue=""
            />
            {errors.paymentDay && (
              <div className="error-message">
                <span className="error-icon">!</span>
                {errors.paymentDay}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="bottomPosiLock">
          <div className="flexlineC">
            <SmallSvgButton
              onclick={() => {
                setSliderState("six");
              }}
              secondary={true}
              name="Back"
            />
            <SmallSvgButton
              onclick={handleSubmit}
              name="Continue"
            />
          </div>
          <h3>
            Already on Lilypads?
            <Link to="/sigin">
              <span>Sign In.</span>
            </Link>
          </h3>
        </div>
      )}

      {autoInvest && (
        <div className="bottomPosiLock">
          <div className="flexlineC">
            <SmallSvgButton
              onclick={() => {
                setSliderState("five");
              }}
              secondary={true}
              name="Back"
            />
            <SmallSvgButton
              onclick={handleSubmit}
              name="Continue"
            />
          </div>
          <h3>
            Already on Lilypads?
            <Link to="/sigin">
              <span>Sign In.</span>
            </Link>
          </h3>
        </div>
      )}
    </div>
  );
}

function EightSection({
  setSliderState,
  linkedAccount,
  setLinkedAccount,
  bankAccountTypeList,
  bankAccount = "",
  setBankAccount,
  bankAccountDA,
  setBankAccountDA,
  setBankName,
  bankName = "",
  setRoutingNumber,
  RoutingNumber = "",
  setAccountNumber,
  AccountNumber = "",
  setConfirmAccountNumber,
  confirmAccountNumber = "",
}) {
  const [errors, setErrors] = useState({
    linkedAccount: "",
    bankAccount: "",
    bankName: "",
    RoutingNumber: "",
    AccountNumber: "",
    confirmAccountNumber: "",
  });

  const [selectedBank, setSelectedBank] = useState(null);

  const handleSubmit = () => {
    const formErrors = {
      bankAccount: linkedAccount === "manual" && (bankAccount.trim() === "" || !bankAccount) ? "Please select a bank account type." : "",
      bankName: linkedAccount === "manual" && (bankName.trim() === "" || !bankName) ? "Please enter the bank name." : "",
      RoutingNumber: linkedAccount === "manual" && (RoutingNumber.trim() === "" || !RoutingNumber) ? "Please enter the routing number." : "",
      AccountNumber: linkedAccount === "manual" && (AccountNumber.trim() === "" || !AccountNumber) ? "Please enter the account number." : "",
    };
    setErrors(formErrors);

    // Check if all fields are valid before proceeding
    let allFieldsValid = true;
    Object.values(formErrors).forEach((error) => {
      if (error !== "") {
        allFieldsValid = false;
      }
    });

    if (allFieldsValid) {
      // Reset errors before proceeding
      setErrors({
        linkedAccount: "",
        bankAccount: "",
        bankName: "",
        RoutingNumber: "",
        AccountNumber: "",
        confirmAccountNumber: "",
      });

      // Proceed to the next section
      setSliderState("nine");
    }
  };

  const handleBankSelect = (bank) => {
    setSelectedBank(bank);
    setErrors((prev) => ({
      ...prev,
      linkedAccount: "",
    }));
  };

  return (
    <div className="signupEightSection signupsection">
      <h1>Choose a source of funds.</h1>
      <h2>Connect your account by authenticating through your online banking credentials.</h2>
      <div className={"tabselectorL " + linkedAccount}>
        <div className="tabSelT">
          <div
            onClick={() => setLinkedAccount("link")}
            className="tabItem"
          >
            Link Account
          </div>
          <div
            onClick={() => setLinkedAccount("manual")}
            className="tabItem"
          >
            Enter Manually
          </div>
        </div>
        <div className="tabSelB"></div>

        <div className="tabline">
          <div className="tablineInner"></div>
        </div>
      </div>
      {linkedAccount === "link" && (
        <div className="linkedAccountCon">
          <div className="searchBar">
            <input
              placeholder="Search"
              type="text"
            />
            {/* Assuming searchImg is defined */}
            {/* <img
              src={searchImg}
              alt="search"
            /> */}
          </div>

          <div className="linkAccountImgCon">
            {/* Example images */}
            <img
              src={bankOne}
              alt="bankOne"
              onClick={() => handleBankSelect(bankOne)}
              className={selectedBank === bankOne ? "selected" : ""}
            />
            <img
              src={bankTwo}
              alt="bankTwo"
              onClick={() => handleBankSelect(bankTwo)}
              className={selectedBank === bankTwo ? "selected" : ""}
            />
            <img
              src={bankThree}
              alt="bankTwo"
              onClick={() => handleBankSelect(bankTwo)}
              className={selectedBank === bankTwo ? "selected" : ""}
            />
            <img
              src={bankFour}
              alt="bankTwo"
              onClick={() => handleBankSelect(bankTwo)}
              className={selectedBank === bankTwo ? "selected" : ""}
            />
            <img
              src={bankFive}
              alt="bankTwo"
              onClick={() => handleBankSelect(bankTwo)}
              className={selectedBank === bankTwo ? "selected" : ""}
            />
            <img
              src={bankSix}
              alt="bankTwo"
              onClick={() => handleBankSelect(bankTwo)}
              className={selectedBank === bankTwo ? "selected" : ""}
            />
            <img
              src={bankSeven}
              alt="bankTwo"
              onClick={() => handleBankSelect(bankTwo)}
              className={selectedBank === bankTwo ? "selected" : ""}
            />
            <img
              src={bankEight}
              alt="bankTwo"
              onClick={() => handleBankSelect(bankTwo)}
              className={selectedBank === bankTwo ? "selected" : ""}
            />
          </div>
          {errors.linkedAccount && (
            <div className="error-message">
              <span className="error-icon">!</span>
              {errors.linkedAccount}
            </div>
          )}
        </div>
      )}
      {linkedAccount === "manual" && (
        <>
          <DropDown
            label="ACCOUNT TYPE"
            data={bankAccountTypeList}
            selectedData={bankAccount}
            setSelectedData={(value) => {
              setBankAccount(value);
              setErrors((prev) => ({
                ...prev,
                bankAccount: value.trim() === "" ? "Please select a bank account type." : "",
              }));
            }}
            dropdownState={bankAccountDA}
            dropdownSetter={setBankAccountDA}
          />
          {errors.bankAccount && (
            <div className="error-message">
              <span className="error-icon">!</span>
              {errors.bankAccount}
            </div>
          )}

          <div className="bank_details">
            <label htmlFor="bankName">BANK NAME</label>
            <input
              id="bankName"
              type="text"
              value={bankName}
              onChange={(e) => {
                setBankName(e.target.value);
                setErrors((prev) => ({
                  ...prev,
                  bankName: e.target.value.trim() === "" ? "Please enter the bank name." : "",
                }));
              }}
            />
            {errors.bankName && <div className="error-message">{errors.bankName}</div>}
          </div>

          <div className="bank_details">
            <label htmlFor="routingNumber">ROUTING NUMBER</label>
            <input
              id="routingNumber"
              type="text"
              value={RoutingNumber}
              onChange={(e) => {
                setRoutingNumber(e.target.value);
                setErrors((prev) => ({
                  ...prev,
                  RoutingNumber: e.target.value.trim() === "" ? "Please enter the routing number." : "",
                }));
              }}
            />
            {errors.RoutingNumber && <div className="error-message">{errors.RoutingNumber}</div>}
          </div>

          <div className="bank_details">
            <label htmlFor="accountNumber">ACCOUNT NUMBER</label>
            <input
              id="accountNumber"
              type="text"
              value={AccountNumber}
              onChange={(e) => {
                setAccountNumber(e.target.value);
                setErrors((prev) => ({
                  ...prev,
                  AccountNumber: e.target.value.trim() === "" ? "Please enter the account number." : "",
                }));
              }}
            />
            {errors.AccountNumber && <div className="error-message">{errors.AccountNumber}</div>}
          </div>

          <div className="bank_details">
            <label htmlFor="confirmAccountNumber">CONFIRM ACCOUNT NUMBER</label>
            <input
              id="confirmAccountNumber"
              type="text"
              value={confirmAccountNumber}
              onChange={(e) => {
                setConfirmAccountNumber(e.target.value);
              }}
            />
          </div>
        </>
      )}

      <div className="bottomPosiLock">
        <div className="flexlineC">
          <SmallSvgButton
            onclick={() => {
              setSliderState("seven");
            }}
            secondary={true}
            name="Back"
          />
          <SmallSvgButton
            onclick={handleSubmit}
            name="Continue"
          />
        </div>
        <h3>
          Already on Lilypads?
          <Link to="/signin">
            <span>Sign In.</span>
          </Link>
        </h3>
      </div>
    </div>
  );
}

function NineSection({setSliderState, acknowledgementsChecks, setAcknowledgementsChecks, sliderState, signupData, handleSendEmailVarification, dispatch}) {
  return (
    <div className="signupnine signupsection">
      <h1>Acknowledgements</h1>
      <h2>Please indicate agreement with the following:</h2>
      <div style={{display: "flex"}}>
        <div
          className="acknowlegeCON"
          onClick={() =>
            setAcknowledgementsChecks((prev) => ({
              ...prev,
              first: !prev.first,
            }))
          }
        >
          {acknowledgementsChecks.first ? <CheckmarkTICK /> : <Checkmark />}
        </div>
        <h2>I have reviewed each Offering Circular and Subscription Agreement, per my selected investment plan, and understand the risks associated with such offerings.</h2>
      </div>
      <div style={{display: "flex"}}>
        <div
          className="acknowlegeCON"
          onClick={() =>
            setAcknowledgementsChecks((prev) => ({
              ...prev,
              second: !prev.second,
            }))
          }
        >
          {acknowledgementsChecks.second ? <CheckmarkTICK /> : <Checkmark />}
        </div>
        <h2>I represent that my investment(s) in this offering(s) does not constitute more than the greater of 10% of my gross annual income or net worth, either individually or in the aggregate.</h2>
      </div>
      <div style={{display: "flex"}}>
        <div
          className="acknowlegeCON"
          onClick={() =>
            setAcknowledgementsChecks((prev) => ({
              ...prev,
              third: !prev.third,
            }))
          }
        >
          {acknowledgementsChecks.third ? <CheckmarkTICK /> : <Checkmark />}
        </div>
        <h2>
          I understand that there is no guarantee of any financial return on this investment(s) and that I run the risk of loss in my investment(s); and that I have not been provided tax advice by
          Lilypads.
        </h2>
      </div>
      <div style={{display: "flex"}}>
        <div
          className="acknowlegeCON"
          onClick={() =>
            setAcknowledgementsChecks((prev) => ({
              ...prev,
              fourth: !prev.fourth,
            }))
          }
        >
          {acknowledgementsChecks.fourth ? <CheckmarkTICK /> : <Checkmark />}
        </div>
        <h2>
          I recognize that my investment is in real property, which is fundamentally a long-term, illiquid investment; that liquidations, if approved, are paid out quarterly for the eRElTs, and
          monthly after a minimum 60-day waiting period for the eFunds; and requests for liquidation may be suspended during periods of financial stress.
        </h2>
      </div>
      <div style={{display: "flex"}}>
        <div
          className="acknowlegeCON"
          onClick={() =>
            setAcknowledgementsChecks((prev) => ({
              ...prev,
              fifth: !prev.fifth,
            }))
          }
        >
          {acknowledgementsChecks.fifth ? <CheckmarkTICK /> : <Checkmark />}
        </div>
        <h2>I certify that the information provided is true and correct and understand it will be used in the W-9. I have reviewed and acknowledge the W-9.</h2>
      </div>

      <div className="flexlineC">
        <SmallSvgButton
          onclick={() => {
            setSliderState("five");
          }}
          secondary={true}
          name="Back"
        />
        <SmallSvgButton
          onclick={() => {
            const {first, second, third, fourth, fifth} = acknowledgementsChecks;
            if (first && second && third && fourth && fifth) {
              setSliderState("ten");
              if (sliderState === "nine") {
                handleSendEmailVarification();
              }
            } else {
              dispatch(returnErrors("Kindly check all agreements", "error", "Error"));
            }
          }}
          name="Continue"
        />
      </div>
      <h3>
        Already on Lilypads?{" "}
        <Link to="/signin">
          <span>Sign In.</span>
        </Link>
      </h3>
    </div>
  );
}

function TenSection({setSliderState, confrimEmailChecks, setConfrimEmailChecks, handleSignupUser, isLoading, emailVerifyData, setConfirmationCode, setVerifyUserEmailOtp}) {
  return (
    <div className="signupten signupsection">
      <h1>Confirm your email</h1>
      <h2>{`We have sent an email to ${emailVerifyData?.email || "example@gmail.com"} Please check your inbox for an activation link.`}</h2>
      <InputBox
        label="Confirmation Code"
        type="password"
        onChange={(e) => {
          setConfirmationCode(e.target.value);
          setVerifyUserEmailOtp((prev) => ({...prev, otp: e.target.value}));
        }}
      />
      <div
        style={{margin: "20px 0"}}
        className="acknowlegeCON"
        onClick={() =>
          setConfrimEmailChecks((prev) => ({
            ...prev,
            first: !prev.first,
          }))
        }
      >
        {confrimEmailChecks.first ? <CheckmarkTICK /> : <Checkmark />}
        <h2>
          Let Lilypads pass on my information to certain partners so they can send me up-to-date information about their products and services. If you would like to receive more information or
          unsubscribe at any time, please read the privacy statement.
        </h2>
      </div>
      <div
        style={{margin: "20px 0", marginBottom: "40px"}}
        className="acknowlegeCON"
        onClick={() =>
          setConfrimEmailChecks((prev) => ({
            ...prev,
            second: !prev.second,
          }))
        }
      >
        {confrimEmailChecks.second ? <CheckmarkTICK /> : <Checkmark />}
        <h2>
          Lilypads can send me promotional and commercial offers about <a href="#">Lilypads</a> products and services for the enterprise.
        </h2>
      </div>
      <Link onClick={handleSignupUser}>
        <PrimaryButton name={isLoading ? "Please wait..." : "Confirm"} />
      </Link>
      <div className="spacerMTM30"></div>
      <PrimaryButton
        name="Re-send registration code"
        onclick={() => {
          setSliderState("one");
        }}
        classNames="secondary"
      />
      {/* <h3>
        Already on Lilypads? <span>Sign In.</span>
      </h3> */}
      <h3>
        Already on Lilypads?
        <Link to="/sigin">
          <span>Sign In.</span>
        </Link>
      </h3>
    </div>
  );
}
