import React, {useState, useEffect, useRef} from "react";
import "./Stakeholders.scss";
import {PrimaryButton} from "../../../components/reusableComponents/Buttons/Buttons";
import {PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import {useSelector} from "react-redux";
import filter from "../../../assets/Manage-syndicate/Svg/filter.svg";
import image1 from "../../../assets/Manage-syndicate/image/Person (Circle).png";
import Search from "../../../components/reusableComponents/Search/Search";
import DotMenu from "../../../components/reusableComponents/Tripledot/Tripledot";
import axios from "axios";
import {useParams} from "react-router-dom";
import note from "../../../assets/Manage-syndicate/Svg/Note.svg";
import {useDispatch} from "react-redux";
import {returnMessages} from "../../../store/reducers/message";
import {returnErrors} from "../../../store/reducers/error";
import SuccessModal from "../../../components/reusableComponents/Alerts/Alerts/SuccessModal/index";
import {TextField} from "@mui/material";
import default_image from '../../../assets/profile.png'
import Endpoint from "../../../utils/Endpoint";

const Stakeholders = () => {
  const {idToken} = useSelector((state) => state.auth);
  const [manageStakeholders, setManageStakeholders] = useState([]);
  const userAssetData = useSelector((state) => state?.userInfo?.userAssetData?._id);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const {syndicateId} = useParams();
  const dispatch = useDispatch();
  let assetid = useSelector((state) => state.portfolio?.selectedProperty?._id);

  const handleManageStakeholdersClick = () => {
    setShowPopup(true);
  };

  const getdata = async (assetId, syndicateId) => {
    const config = {
      headers: {
        "auth-token": idToken,
        "asset-id": assetid,
        "syndicate-id": syndicateId,
      },
    };

    try {
      setLoading(true);
      const response = await axios.get(Endpoint.getmanageStakeholders, config);
      if (response.status === 200) {
        setManageStakeholders(response.data.data);
      } else {
        setManageStakeholders([]);
      }
    } catch (error) {
      setManageStakeholders([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getdata(userAssetData, syndicateId);
  }, [syndicateId, userAssetData]);

  const handleDeleteStakeholder = async (stakeholderId) => {
    try {
      await axios.delete(`${Endpoint.manageStakeholders}/${stakeholderId}`, {
        headers: {
          "auth-token": idToken,
        },
      });
      dispatch(returnMessages("Stakeholder deleted successfully", 200));
      getdata(userAssetData, syndicateId);
    } catch (error) {
      dispatch(returnErrors("Error deleting stakeholder", 400));
    }
  };

  return (
    <div>
      <div className="Stakeholders-container">
        <form className="example">
          <Search />
        </form>
        <div className="button-container">
          <PrimaryButton
            name="Manage Stakeholders"
            classNames="Stakeholders-Manage"
            padding="10px"
            svgHeight="20px"
            svgWidth="20px"
            onclick={handleManageStakeholdersClick}
          />
          {showPopup && (
            <div className={`stakeholder-popup-menu ${showPopup ? "show" : ""}`}>
              <StakeholderPopup
                togglePopup={() => setShowPopup(false)}
                refreshStakeholders={() => getdata(userAssetData, syndicateId)}
                assetid={assetid}
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <div>
          <div className="dropdown-data">
            <select name="Saved views">
              <option
                value=""
                disabled
                selected
              >
                Saved View
              </option>
              <option value="view1">View 1</option>
              <option value="view2">View 2</option>
            </select>
            <select name="columns">
              <option
                value=""
                disabled
                selected
              >
                Columns
              </option>
              <option value="column1">Column 1</option>
              <option value="column2">Column 2</option>
            </select>
            <select name="RELATIONSHIP">
              <option
                value=""
                disabled
                selected
              >
                Relationship
              </option>
              <option value="relationship1">Relationship 1</option>
              <option value="relationship2">Relationship 2</option>
            </select>
            <select name="COST CENTER">
              <option
                value=""
                disabled
                selected
              >
                Cost Center
              </option>
              <option value="center1">Cost Center 1</option>
              <option value="center2">Cost Center 2</option>
            </select>
            <select name="HOLDING(1)">
              <option
                value=""
                disabled
                selected
              >
                Holding(1)
              </option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
            </select>
            <select name="NOTICES">
              <option
                value=""
                disabled
                selected
              >
                Notices
              </option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
            </select>
            <select name="ADDRESS">
              <option
                value=""
                disabled
                selected
              >
                Address
              </option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
            </select>
            <select name="DATE CREATED">
              <option
                value=""
                disabled
                selected
              >
                Date Created
              </option>
              <option value="date_created1">Created 1</option>
              <option value="date_created2">Created 2</option>
            </select>
            <PrimaryButtonSvgFirst
              name="Filter"
              classNames="ManageStakeholders-Download"
              svgPath={filter}
              padding="10px"
              svgHeight="20px"
              svgWidth="20px"
            />
          </div>
        </div>
      </div>

      <div>
        <div className="userdata-header">
          <p>Stakeholder name</p>
          <p>Contact email</p>
          <p>RELATIONSHIP</p>
        </div>
      </div>
      <div>
        {loading ? (
          <div className="loading-screen">
            <div className="circular-loader"></div>
          </div>
        ) : manageStakeholders?.length > 0 ? (
          manageStakeholders?.map((stakeholder, index) => (
            <div
              className="StackHolder-details"
              key={index}
            >
              <div className="StackHolder-details-profile">
                <input type="checkbox" />
                <img
                  src={stakeholder?.image_url || image1}
                  alt="Profile"
                />
                <p>{stakeholder?.manage_stakeholders_name}</p>
              </div>
              <p>{stakeholder?.manage_stakeholders_email}</p>
              <p>{stakeholder?.manage_stakeholders_relationship}</p>
              <DotMenuForStakeholder onDelete={() => handleDeleteStakeholder(stakeholder._id)} />
            </div>
          ))
        ) : (
          <div className="Equityplans-data">
            <div className="Equityplans-data-container-maindiv">
              <div className="Equityplans-Error-Container">
                <div className="Equityplans-data-container-image">
                  <img
                    src={note}
                    alt="image"
                  />
                </div>
                <p>No stakeholders have been created</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const DotMenuForStakeholder = ({onDelete}) => {
  const profileMenuItems = [{label: "Delete", action: onDelete}];
  return <DotMenu menuItems={profileMenuItems} />;
};

export default Stakeholders;

const StakeholderPopup = ({togglePopup, refreshStakeholders, assetid}) => {
  const [loading, setLoading] = useState(false);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const userAssetData = useSelector((state) => state.userInfo.userAssetData);
  const idToken = useSelector((state) => state.auth.idToken);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const {syndicateId} = useParams();
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [formData, setFormData] = useState({
    manage_stakeholders_name: "",
    asset_id: userAssetData ? userAssetData?._id : "",
    manage_stakeholders_email: "",
    manage_stakeholders_relationship: "",
    general: {
      some_other_field: "",
    },
    image: null,
    syndicate_id: syndicateId,
  });

  const handleChange = (e) => {
    const {name, value, files} = e.target;

    if (name === "image" && files.length > 0) {
      setFormData({...formData, image: files[0]});
      setImagePreview(URL.createObjectURL(files[0]));
    } else if (name.startsWith("general.")) {
      const fieldName = name.split(".")[1];
      setFormData((prevFormData) => ({
        ...prevFormData,
        general: {
          ...prevFormData.general,
          [fieldName]: value,
        },
      }));
    } else {
      setFormData({...formData, [name]: value});
    }
  };

  const uploadImage = async () => {
    if (formData.image) {
      setImageUploadLoading(true);
      const formDataToSend = new FormData();
      formDataToSend.append("Profile_image", formData.image);

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            "auth-token": idToken,
          },
        };

        const response = await axios.post(`${Endpoint.upload_Profile_images}?assetid=${assetid}`, formDataToSend, config);
        if (response.data && response.data.data && response.data.data.imageUrl) {
          return response.data.data.imageUrl;
        } else {
          dispatch(returnErrors("Failed to upload image", response.status));
          return null;
        }
      } catch (error) {
        dispatch(returnErrors("Error uploading image", error.response ? error.response.status : 400));
        return null;
      } finally {
        setImageUploadLoading(false);
      }
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let imageUrl = uploadedImageUrl;
    if (!uploadedImageUrl) {
      imageUrl = await uploadImage();
    }
    if (imageUrl) {
      setLoading(true);
      const finalAssetId = formData.asset_id || (userAssetData ? userAssetData._id : "");
      const payload = {
        asset_id: finalAssetId,
        syndicate_id: formData.syndicate_id,
        manage_stakeholders_name: formData.manage_stakeholders_name,
        manage_stakeholders_email: formData.manage_stakeholders_email,
        manage_stakeholders_relationship: formData.manage_stakeholders_relationship,
        general: formData.general,
        image_url: imageUrl,
      };

      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            "auth-token": idToken,
          },
        };

        const response = await axios.post(Endpoint.manageStakeholders, payload, config);
        if (response.data && response.data.success) {
          dispatch(returnMessages("Stakeholder Added Successfully", 200));
          refreshStakeholders();
          setShowSuccessModal(true);
          togglePopup();
        } else {
          dispatch(returnErrors("Failed to add stakeholder", response.status));
        }
      } catch (error) {
        dispatch(returnErrors("An error occurred", error.response ? error.response.status : 400));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div style={popupContainerStyle}>
      <div style={popupStyle}>
        <div style={popupContentStyle}>
          <h2 style={{ marginBottom: "15px", textAlign: "center" }}>Add Stakeholder</h2>
  
          <form onSubmit={handleSubmit} style={formStyle}>
            <div style={imageUploadContainerStyle}>
              <label htmlFor="image" style={fileInputLabelStyle}>
                <img
                  src={imagePreview || default_image} 
                  alt="Profile Preview"
                  style={imagePreviewStyle}
                />
              </label>
            
              <input
                type="file"
                id="image"
                name="image"
                accept="image/*"
                onChange={handleChange}
                style={fileInputStyle} 
              />
            </div>
  
            <div style={formFieldStyle}>
              <TextField
                id="manage_stakeholders_name"
                name="manage_stakeholders_name"
                value={formData.manage_stakeholders_name}
                onChange={handleChange}
                variant="standard"
                label="Name"
                fullWidth
              />
            </div>
            <div style={formFieldStyle}>
              <TextField
                type="email"
                id="manage_stakeholders_email"
                name="manage_stakeholders_email"
                value={formData.manage_stakeholders_email}
                onChange={handleChange}
                variant="standard"
                label="Email"
                fullWidth
              />
            </div>
            <div style={formFieldStyle}>
              <TextField
                type="text"
                id="manage_stakeholders_relationship"
                name="manage_stakeholders_relationship"
                value={formData.manage_stakeholders_relationship}
                onChange={handleChange}
                variant="standard"
                label="Relationship"
                fullWidth
              />
            </div>
  
            <div style={buttonContainerStyle}>
              <button
                type="button"
                onClick={togglePopup}
                style={cancelButtonStyle}
              >
                Cancel
              </button>
              <button
                type="submit"
                style={addButtonStyle}
                disabled={loading || imageUploadLoading}
              >
                {loading || imageUploadLoading ? "Uploading..." : "Add Stakeholder"}
              </button>
            </div>
          </form>
        </div>
      </div>
      {showSuccessModal && <SuccessModal toggle={setShowSuccessModal} />}
    </div>
  );
  
  
};

const popupContainerStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const popupStyle = {
  width: "450px",
  backgroundColor: "#fff",
  borderRadius: "10px",
  overflow: "hidden",
  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
};

const popupContentStyle = {
  padding: "30px",
  textAlign: "center",
};

const formStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const formFieldStyle = {
  marginBottom: "15px",
  width: "100%",
};

const imageUploadContainerStyle = {
  marginBottom: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const fileInputLabelStyle = {
  cursor: "pointer",
  marginBottom: "10px",
};

const fileInputStyle = {
  display: "none", 
};

const selectImageButtonStyle = {
  backgroundColor: "#007BFF",
  color: "#fff",
  padding: "10px 15px",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  marginTop: "10px",
  fontSize: "14px",
  transition: "background-color 0.3s ease",
};

const imagePreviewStyle = {
  width: "100px",
  height: "100px",
  borderRadius: "50%",
  objectFit: "cover",
  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.15)",
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
  width: "100%",
};

const cancelButtonStyle = {
  padding: "10px 20px",
  backgroundColor: "#f44336",
  color: "#fff",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  width: "45%",
};

const addButtonStyle = {
  padding: "10px 20px",
  backgroundColor: "#4CAF50",
  color: "#fff",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  width: "45%",
  transition: "background-color 0.3s ease",
};
