import React, {useEffect, useRef, useState} from "react";
import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import {Label, NotebookPanel, MessageBox, ComparisionPanel, SharePanel, InvestorFeedPanel, BookmarkPanel} from "../assets/Assect";
import {PhoneSvg, BookmarkSvg, NotebookSvg, CompareSvg, ShareSvg, PiechartSvg, SliderIcon} from "../Svgimages/svgallimnages";
import { ContactPanel } from "./ContactPanel";


export function Accectdata({tabNames, collapsed, item, card, assetIdList}) {
    return (
      <Tabs
        selectedTabClassName="active"
        defaultIndex={(collapsed && -1) || 0}
      >
        <TabList>
          <Tab>
            <PhoneSvg />
            {tabNames && <span>Contacts</span>}
          </Tab>
          <Tab>
            <BookmarkSvg />
            {tabNames && <span>Save</span>}
          </Tab>
          <Tab>
            <NotebookSvg />
            {tabNames && <span>Add Note</span>}
          </Tab>
          <Tab>
            <CompareSvg />
            {tabNames && <span>Compare</span>}
          </Tab>
          <Tab>
            <ShareSvg />
            {tabNames && <span>Share</span>}
          </Tab>
          <Tab>
            <PiechartSvg />
            {tabNames && <span>Investor</span>}
          </Tab>
        </TabList>
        <TabPanel>
          {/* <AccectPanel item={item} /> */}
          <ContactPanel
            card={card}
            assetIdList={assetIdList}
          />
        </TabPanel>
        <TabPanel>
          <BookmarkPanel />
        </TabPanel>
        <TabPanel>
          <NotebookPanel />
        </TabPanel>
        <TabPanel>
          <ComparisionPanel />
        </TabPanel>
        <TabPanel>
          <SharePanel />
        </TabPanel>
        <TabPanel>
          <InvestorFeedPanel />
        </TabPanel>
      </Tabs>
    );
  }