import ProgressBar from "@ramonak/react-progress-bar";
import "./style.scss";

const ProgressBarComponent = ({ completed, height = "20px" }) => {
  return (
    <ProgressBar
      completed={completed}
      bgColor="#7d55c7"
      isLabelVisible={false}
      labelColor="#ffffff"
      maxCompleted={100}
      className="custom-progress-bar"
      style={{ height }} 
    />
  );
};

export default ProgressBarComponent;
