const Endpoint = {
  userlogin: "/api/auth/login",
  checkTokken: "api/auth/checkToken",
  verifyOTP: "api/auth/verifyOTP",
  userBasic: "api/auth/edit/user",
  // User
  getUserDetails: "api/auth/user-details",
  Signupdata: "api/auth/register",
  updatepassword: "api/auth/update-password",
  getUserPortfolio: "users/asset-discovery",
  UserLogIn: "api/auth/login",
  PostQuestionsData: "api/auth/persona/add",
  VerifyEmail: "/api/auth/sendVerificationEmail",

  // Assets
  // getAsset:`api/assets/search/assets/?assets_name=${query}`,
  PostLoanSetupData: "api/loan/add",
  FetchValuationInfo: "/api/deal-economics/valuation",
  fetchCrownFunding: "/api/crowdfunding",

  // checklist
  getCheckListData: "api/assets/getCheckListData",

  // image_upload

  image_upload: "/api/Portfolio/image_upload",

  // SETUP_SYNDICATE
  PostSetupSyndicate: "api/add-Syndicate",
  GetSyndicates: "api/Syndicates",

  // Manage Syndicate
  PostManageSindicate: "/api/Add/manageStakeholders",

  // portfolio
  AssetHeader: "api/Portfolio/getAccessGivenByEmail",
  Maintenance: "api/Portfolio/getallownerdata",
  getAdmindata: `api/Portfolio/get-portfolio-by-ID`,
  addingfetchinvistor: "api/Portfolio/giveAsccess",
  Sendemailtgiveaccess: "api/Portfolio/giveAccess",
  linechartdata: "/api/Portfolio/getallownerdata",
  getassetbyid: "api/Portfolio/getallownerdata",
  getallownerdata: "api/Portfolio/getallownerdata",
  getassectbyemail: "api/Portfolio/getAccessGivenByAdmin",
  fetchMurndataByEmail: (email) => `api/murndata/get-murndata-by-email?email=${email}`,
  UpdateAsset: (id) => `/api/portfolio/updateAsset/${id}`,
  // Deals
  startDeals: (id) => `api/Deals/StartDeals/${id}`,
  addCheckList: (id) => `/api/assets/:assetId`,
  Similar_assets: (assetId) => `api/assets/GetSimilarProperties/${assetId}`,

  // portfolio -
  // Maintenance
  getallmaintance: "/api/Portfolio/Maintenance",

  // Asset_discovery -
  onMarketDispositions: "api/assets/AssetDiscovery/getDispositionsWithAssets",
  increment_preference: "/api/assets/persona/increment_preference",
  crowdfunding: "api/crowdfunding",
  search_assets: "/api/assets/search_assets",
  gettask: "api/Task/gettask",
  getpdffromaws: "/api/Deals/get_pdfdata",
  GetInvestmentStatus: "/api/GetInvestmentStatus",
  investnow: "api/investnow",
  investstnow: "/api/investstnow",
  getEditedPdf: "/api/Deals/getEditedPdf",
  submitPaymentValue: "/api/submitPaymentValue",
  GetPaymentData: "/api/GetPaymentData",
  uploaded_pdfs: "api/Portfolio/uploaded_pdfs",
  uploadPdf: "api/Portfolio/uploadPdf",
  utility: "/api/Portfolio/utility",
  UserDetailsWithReadyToInvestimage: "/api/UserDetailsWithReadyToInvestimage",
  StorePaymentValue: "/api/StorePaymentValue",
  Contract_pdfUpload: "/api/Deals/Contract_pdfUpload",
  add_crowdfunding: "/api/add-crowdfunding",
  addinvestor: "/api/addinvestor",
  getBudgetData: "/api/Portfolio/getBudgetData",
  getalldeals: "api/Deals/getDeals",

  // redux -
  getAccessGivenByAdmin: "api/Portfolio/getAccessGivenByAdmin",
  getBudgetData: "/api/Portfolio/getBudgetData",
  generate_property: "/api/Portfolio/generate_property",
  OperatingIncomeExpences: "api/Portfolio/OperatingIncomeExpences",
  UnitTurnover: "api/Portfolio/UnitTurnover",
  PaymentsDetails: "/api/PaymentsDetails",
  get_Asset_Dashboard: "api/Portfolio/get_Asset_Dashboard",
  OperatingIncomeExpences: "api/Portfolio/OperatingIncomeExpences",
  KPI: "api/Portfolio/KPI",
  getaddinvestor: "api/getaddinvestor",
  getnotifications: "api/Deals/notifications",
  mark_as_read: "api/Deals/mark_as_read",
  getoldchats: "/api/openai/GetAllChatsForUser",
  GetAllChatsForUser: "/api/openai/GetAllChatsForUser",

  // Server -

  StartChat: "https://techdemoapi.lilypads.com/api/startchat",
  continuechat: "https://techdemoapi.lilypads.com/api/continuechat1",
  refreshchat: "https://techdemoapi.lilypads.com/api/refreshchat",

  // local -

  // StartChat: "http://localhost:4000/api/S",
  // continuechat: "http://localhost:4000/api/C",
  // refreshchat: "http://localhost:4000/api/R",

  // other -

  getmessages: "api/openai/GetChatHistory",
  getAllstatus: "api/openai/getStatusesByMessage",
  Implement: "api/openai/Implemented/",
  FollowUp: "/api/openai/FollowUp",
  // send Feedback -
  sendfeedback: "api/openai/Submit_feedBack",
  getfeedback: "api/openai/get_feedBack",
  addassigntask: "api/openai/Assigned",
  Userlist: (email) => `api/Task/users/getallEmployeeList?email=${email}`,

  //feedback -
  InviteaEmployeetoAsset: "/api/Portfolio/giveAccess",
  Likechat: "api/openai/likeFeedback",

  // Task Section -
  getalltask: "api/Task/gettask",
  addtask: "/api/Task/addtask",

  // Subscribe -
  subscribeUser: "api/auth/subscribeUser",
  updateLilypadsAccess: "api/auth/updateLilypadsAccess",

  //asset-dashboard -
  Most_Profitable_Renters: "/api/Portfolio/Most_Profitable_Renters",
  KPI_Summary: "/api/Portfolio/KPI_Summary",
  Unit_Breakdown: "/api/Portfolio/Unit_Breakdown",
  BuildingOverview: "/api/Portfolio/BuildingOverview",

  // Contract PDF -
  Contract_pdfUpload: "/api/Deals/Contract_pdfUpload",
  edited_PDF: "/api/Deals/edited_PDF",
  get_pdfdata: "/api/Deals/get_pdfdata",

  // Profile - Data -
  profile: "/api/auth/uploads/profile",

  // generate_property =
  generate_property: "/api/Portfolio/generate_property",
  ExportAsExcelData: "/api/Portfolio/ExportAsExcelData",
  giveAccess: "/api/Portfolio/giveAccess",
  updateAccess: "api/Portfolio/updateAccess",
  GetPaymentDetailsWithAssetInfo: "api/GetPaymentDetailsWithAssetInfo",
  uploadExcel: "/api/Portfolio/uploadExcel",
  ExportASExcelData: "/api/Portfolio/ExportASExcelData",

  // manageStakeholders

  manageStakeholders: "/api/Add/manageStakeholders/Add",
  getmanageStakeholders: "api/GetAll/manageStakeholders",
  getallownerdata: "/api/Portfolio/getallownerdata",
  paymentConfirmation: "/api/paymentConfirmation",
  checkbox: "/api/assets",

  //  getDispositionsWithAssets
  getDispositionsWithAssets: "api/assets/AssetDiscovery/getDispositionsWithAssets",
  ContactPanelStartDeals: "api/Deals/StartDeals",

  // PDF Edit -
  getEditedPdf: "/api/Deals/getEditedPdf",
  GetInvestmentStatus: "/api/GetInvestmentStatus",
  get_pdfdata: "/api/Deals/get_pdfdata",
  submitPaymentValue: "/api/submitPaymentValue",
  GetPaymentData: "/api/GetPaymentData",
  uploadimageStartSyndicate: "/api/uploadimage",

  // card
  upload_image: "/api/portfolio/upload-image",
  updateAssetcard: "api/portfolio/updateAsset",
  uploadPdfcard: "api/Portfolio/uploadPdf",
  delete_imagecard: "/api/Portfolio/delete_image",

  // AssetDiscovery
  DispositionAssetDiscovery: "/api/assets/AssetDiscovery",
  manageStakeholders: "/api/manageStakeholders",
  upload_Profile_images: "/api/upload_Profile_images",

  // syndicates
  fetchSyndicates: "api/syndicates",
  GetAllDealsSyndicate: "api/asset/GetAllDealsSyndicate",
  userFavorites: "api/assets/getfavorites",
  addFavorites: "/api/assets/favorites",
  saveuserSearchHistory: "/api/assets/saveuserSearchHistory",
  getuserhistory: "/api/assets/getUserSearchHistory",
  uploadFiles: "/api/Deals/uploadExcel",
  GetPaidPayments: "/api/GetPaidPayments",
  GenerateInvoicePDF: "/api/GenerateInvoicePDF",
  deleteUserSearchHistory: "/api/assets/deleteUserSearchHistory",
  views_Count: "/api/assets/views",
  CrowdfundingSearchResults: "/api/Search_results",

  // InvestNow -

  AddingInvested: "/api/AddingInvested",

  // Payment Info -
  add_payments_info: "/api/card_info/add_info",
  get_payments_info: "/api/card_info/get_info",
  patch_payments_info:"/api/card_info/patch_info",
  delete_payments_info: "/api/card_info/delete_info",
  Recommendation_History:"/api/Recommendation_History/getRecommendation_History",
 
};

export default Endpoint;
