export const AuthUserRoutes = (userInformation, location) => {
  const links = [];

  //   Investment Pipeline

  if (userInformation?.roleTypes !== 2 && userInformation?.roleTypes !== 3 && userInformation?.roleTypes === 4 && userInformation?.LilypadsAI_Access === false) {
    links.push({
      to: "/investment-pipeline",
      label: "Investment Pipeline",
      active: location.pathname === "/investment-pipeline",
    });
  }
  //   Portfolio

  if (userInformation?.roleTypes === 3 || userInformation?.LilypadsAI_Access === false) {
    links.push({
      to: `/${userInformation?._id}/portfolio`,
      label: "Portfolio",
      active: location.pathname === `/${userInformation?._id}/portfolio`,
    });
  }

  //   Asset Discovery

  if (userInformation?.roleTypes === 3 || userInformation?.LilypadsAI_Access === false) {
    links.push({
      to: `/${userInformation?._id}/asset-discovery`,
      label: "Asset Discovery",
      active: location.pathname === `/${userInformation?._id}/asset-discovery`,
    });
  }

  if (userInformation?.roleTypes === 4 || userInformation?.LilypadsAI_Access === false) {
    const encodedAssetId = btoa(userInformation?.assetId);
    links.push({
      to: `/dealsroom?data=${encodedAssetId}`,
      label: "Deals",
      active: location.pathname === `/dealsroom?data=${encodedAssetId}`,
    });
  }

  //   tasks

  if (userInformation?.roleTypes === 3 || userInformation?.LilypadsAI_Access === false) {
    links.push({
      to: `/${userInformation?._id}/tasks`,
      label: "Tasks",
      active: location.pathname === `/${userInformation?._id}/tasks`,
    });
  }

  //    Lilypads AI

  if ((userInformation?.roleTypes === 2 && userInformation?.roleTypes === 4) || userInformation?.LilypadsAI_Access === true) {
    links.push({
      to: `/${userInformation?._id}/LilypadsAI`,
      label: " Lilypads AI",
      active: location.pathname === `/${userInformation?._id}/tasks`,
    });
  }

  return links;
};
