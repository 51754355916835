import React, { useEffect, useState } from "react";
import "./assethistory.scss";
import { AssetHistoryBox } from "./ViewHistory/AssetHistoryBox";
import { getallAssetHistory } from "../../../utils/Axios";
import { useSelector } from "react-redux";
import LoaderOverlay from "../../../components/reusableComponents/Loader";

function AssetHistory({ userId }) {
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { idToken } = useSelector((state) => state.auth);
  const { userInformation } = useSelector((state) => state.auth);

  const gethistory = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getallAssetHistory(userInformation._id, idToken);
      setHistory(Array.isArray(response.data) ? response.data : []);
    } catch (err) {
      setError("Failed to load asset history. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    gethistory();
  }, [userId, idToken]);

  return (
    <div className="asset_history">
      {isLoading ? (
        <div>
           {isLoading && <LoaderOverlay desc="Please Wait Untill Map Load Or Refresh Page" />}
        </div>
      ) : error ? (
        <div>{error}</div>
      ) : history?.length > 0 ? (
        history.map((item) => (
          <AssetHistoryBox
            key={item._id}
            asset={item.assetId}
            idToken={idToken}
            userId={userInformation._id}
            onDelete={(deletedAssetId) => {
              setHistory((prevHistory) => prevHistory.filter((asset) => asset.assetId._id !== deletedAssetId));
            }}
          />
        ))
      ) : (
        <div>No asset history available.</div>
      )}
    </div>
  );
}

export default AssetHistory;
