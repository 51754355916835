import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import {InputBox} from "../../../components/reusableComponents/FormFields/FormFields";
import linkButtonSvg from "../../../assets/portfolio/svgs/link-button-svg.svg";
import {PrimaryButtonSvg, PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import {SearchInput, SortDropdown} from "../Inputs";
import plusIcon from "../../../assets/portfolio/svgs/plus.svg";
import dispositonIcon from "../../../assets/portfolio/svgs/dispositon.svg";
import Lilypads_AI from "../../../assets/Openai/AI Lilipads.svg";
import manageAssetIcon from "../../../assets/portfolio/svgs/manage-asset.svg";
import profilebig from "../../../assets/profile.png";
import {useSelector, useDispatch} from "react-redux";
import {returnMessages} from "../../../store/reducers/message";
import {returnErrors} from "../../../store/reducers/error";
import {returnWarningMessages} from "../../../store/reducers/Warning";
import Checkbox from "../../profile/settings/Checkbox";
import {fetchAssetsByAdminEmail} from "../../../store/reducers/AdminGivenAccess";
import {Collapse, Button} from "antd";
import defaultimage from "../../../assets/portfolio/images/profile.png";
import {inviteinvestor} from "../../../store/reducers/InviteInvestor";
import Endpoint from "../../../utils/Endpoint";
import Dialog from "@mui/material/Dialog";
import {TextField, Box, Typography, CircularProgress} from "@mui/material";
import AccessPopup from "./InviteEmployee/Inviteemployee";
import Timeout from "../../../components/Autologout/Timeout";

const AssetHeader = ({selectedItem}) => {
  const [openInvite, setOpenInvite] = useState(false);
  const [inviteBoxKey, setInviteBoxKey] = useState(0);
  const [loadingSyndicate, setLoadingSyndicate] = useState(false);
  const dispatch = useDispatch();
  const {userInformation, idToken} = useSelector((state) => state.auth);
  const assets = useSelector((state) => state.AdminGivenAccess?.assets) || [];
  let investoraccount = useSelector((state) => state.InviteInvestor?.assets) || [];
  const {userPermissions} = useSelector((state) => state.userPermissions || {});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  let {selectedProperty} = useSelector((state) => state.portfolio);
  Timeout(idToken)

  useEffect(() => {
    const fetchAssets = async () => {
      if (userInformation?.email) {
        try {
          setLoading(true);
          await dispatch(fetchAssetsByAdminEmail({idToken, email: userInformation.email}));
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      }
    };

    fetchAssets();
  }, [dispatch, idToken, userInformation?.email]);

  // invite investor
  useEffect(() => {
    if (userInformation?.email) {
      setLoading(true);
      dispatch(inviteinvestor({idToken, email: userInformation.email}))
        .then(() => setLoading(false))
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    }
  }, [dispatch, idToken, userInformation?.email]);

  const handleInvite = () => {
    setOpenInvite(!openInvite);
    if (!openInvite) {
      setInviteBoxKey((prevKey) => prevKey + 1);
    }
  };

  // Syndicate
  const StartSyndicate = async () => {
    try {
      setLoadingSyndicate(true);
      await axios.post(
        Endpoint.add_crowdfunding,
        {assetId: selectedProperty?._id},
        {
          headers: {
            "auth-token": idToken,
          },
        },
      );
    } catch (error) {
    } finally {
      setLoadingSyndicate(false);
    }
  };

  return (
    <div style={{position: "relative", width: "100%"}}>
      <div className="asset-header-container">
        <div className="overlapping-images-portfolioDatanew">
          {userInformation?.roleTypes === 2 &&
            userInformation?.email &&
            (assets && assets.length > 0
              ? assets.slice(0, assets.length <= 2 ? 2 : 4).map((asset, index) => (
                  <img
                    key={index}
                    src={asset?.userId?.profile_image || defaultimage}
                    alt="Profile"
                    style={{
                      width: "30px",
                      height: "30px",
                      margin: "10px",
                      borderRadius: "50%",
                      marginLeft: index === 0 ? "0px" : "-20px",
                    }}
                  />
                ))
              : null)}
        </div>
        {userInformation?.roleTypes === 2 && userInformation?.email && <h6>{assets?.length} Total Collaborators</h6>}
        {userInformation?.roleTypes === 2 && userInformation?.email && (
          <Link
            className="invite-button"
            onClick={handleInvite}
          >
            <img
              src={plusIcon}
              alt="Invite icon"
            />
            <h4>Invite</h4>
          </Link>
        )}
        <div className="vertical-line">&nbsp;</div>
        {userInformation?.roleTypes === 2 && (
          <Link
            className="invite-button"
            to={`/portfolio/${selectedItem?._id}/start-disposition`}
          >
            <img
              src={dispositonIcon}
              alt="Disposition icon"
            />
            <h4>Start Disposition</h4>
          </Link>
        )}
        {userInformation?.roleTypes === 2 && (
          <Link
            className="invite-button"
            onClick={StartSyndicate}
            to={`/${selectedProperty?._id}/dealsroom`}
          >
            {loadingSyndicate ? (
              <div style={{width: "20px", height: "20px"}} />
            ) : (
              // import dispositonIcon from "../../../assets/Openai/AI Lilipads.svg";
              <>
                <img
                  src={dispositonIcon}
                  alt="Disposition icon"
                />
                <h4>Setup Syndicate</h4>
              </>
            )}
          </Link>
        )}
        {userInformation?.roleTypes === 2 && userInformation?.LilypadsAI_Access === true && (
          <Link
            className="invite-button"
            to={`/${userInformation?._id}/${userInformation?.roleTypes}/LilypadsAI`}
          >
            <img
              src={Lilypads_AI}
              alt="Disposition icon"
            />
            <h4>Lilypads AI</h4>
          </Link>
        )}

        {userInformation?.roleTypes === 2 && (
          <Link
            to={`/portfolio/${encodeURIComponent(selectedProperty?.assetName)}/asset-dashboard${userInformation?.roleTypes === 2 ? "?admin=true" : ""}`}
            className="invite-button"
            style={{
              backgroundImage: "linear-gradient(to top, #6547b4, #7657c0)",
              marginLeft: "10px",
            }}
          >
            <img
              src={manageAssetIcon}
              alt="Manage icon"
              style={{width: "20px", height: "20px"}}
            />
            <h4 style={{color: "white"}}>Manage Asset</h4>
          </Link>
        )}
      </div>
      {openInvite && userPermissions && (
        <InviteBox
          key={inviteBoxKey}
          handleInvite={handleInvite}
          assetsData={assets}
          selectedItem={selectedItem}
          investoraccount={investoraccount}
          userInformation={userInformation}
        />
      )}
    </div>
  );
};

const InviteBox = ({handleInvite, assetsData, selectedItem, investoraccount,userInformation}) => {
  const [userEmail, setUserEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [updatedAssetsData, setUpdatedAssetsData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedAssetDetails, setSelectedAssetDetails] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [activeTab, setActiveTab] = useState("employees");
  const filteredAssetsData = assetsData.filter((asset) => asset.assetIds.some((a) => a._id === selectedItem?._id));
console.log("assetsData",assetsData)
  
  const dispatch = useDispatch();
  const {idToken} = useSelector((state) => state.auth);

  useEffect(() => {
    if (!selectedItem || !assetsData || assetsData.length === 0) {
      return;
    }

    const selectedAssetName = selectedItem?.assetName.trim().toLowerCase();
    const filteredAssets = assetsData?.filter((asset) => {
      const hasPermission = asset.assetPermissions?.some((perm) => {
        const sectionName = perm.section.trim().toLowerCase();
        return sectionName === selectedAssetName;
      });
      return hasPermission;
    });
    setUpdatedAssetsData(filteredAssets);
  }, [assetsData, selectedItem]);

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setUserEmail(email);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const handleAddClick = () => {
    if (!emailError && userEmail) {
      setShowPopup(true);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedAssetDetails(null);
  };

  const handleShowDetails = (asset) => {
    setSelectedAssetDetails(asset);
    setShowPopup(true);
  };

  const handleSendAccess = async () => {
    setLoading(true);
    try {
      const requestBody = {
        userEmail: userEmail,
        assetIds: [selectedItem?._id],
      };
      const config = {
        headers: {
          "auth-token": idToken,
        },
      };

      const response = await axios.post(Endpoint.addinvestor, requestBody, config);
      setLoading(false);

      if (response?.data?.success && response?.data?.message === "Investor already exists") {
        const userId = response?.data?.data && response?.data?.data?.userId;
        dispatch(returnWarningMessages(`Investor already exists. Investor ID: ${userId}`, 300, "Warning"));
      } else if (response.data.success) {
        dispatch(returnMessages(`Invitation mail sent successfully to ${userEmail}`, 200));
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        dispatch(returnErrors(error.response.data.message || "Error assigning assets"));
      } else {
        dispatch(returnErrors("Server error", 400));
      }
    }
  };

  return (
    <div className="invitebox_modal">
      <div className="invitebox_wrapper_portfolioDatanew">
        <div className="invitebox_header">
          <h2>Invite People</h2>
          <button
            onClick={handleInvite}
            className="close-button-InviteBox"
          >
            &times;
          </button>
        </div>

        <div className="tab_navigation">
          <button
            className={activeTab === "employees" ? "active-tab" : ""}
            onClick={() => setActiveTab("employees")}
          >
            Employee
          </button>
          <button
            className={activeTab === "syndicate" ? "active-tab" : ""}
            onClick={() => setActiveTab("syndicate")}
          >
            Investor
          </button>
        </div>

        {activeTab === "employees" && (
          <div className="Syndicate_data">
            <div className="Portfolio_invitebox_people">
              <div className="All_people">
                <div className="scrollable_container">
                  {filteredAssetsData?.map((asset, index) => (
                    <div
                      key={index}
                      className="invitebox_profile"
                    >
                      <img
                        src={asset?.userId?.profile_image || profilebig}
                        alt="Profile"
                      />
                      <div>
                        <p>
                          {asset.userId?.name} {asset.userId?.surname}
                        </p>
                        <div style={{display: "flex", flexDirection: "column"}}>
                          <span>{asset.assetIds[0]?.assetName}</span>
                        </div>
                        <p>{asset.userId?.email}</p>
                      </div>
                      <button
                        className="button_portfolioDatanew"
                        onClick={() => handleShowDetails(asset)}
                      >
                        Details
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="Total_number_people">
                <p className="text">
                  <b style={{fontSize: "13px"}}>{filteredAssetsData?.length} people </b> have access to <br /> {selectedItem?.assetName}
                </p>
              </div>
            </div>
          </div>
        )}

        {activeTab === "syndicate" && (
          <div className="Syndicate_data">
            <div className="Portfolio_invitebox_people">
              <div className="All_people">
                <div className="scrollable_container">
                  {investoraccount?.map((investor, index) => (
                    <div
                      key={index}
                      className="invitebox_profile"
                    >
                      <img
                        src={investor?.userId?.profile_image || profilebig}
                        alt="Admin Profile"
                      />
                      <div>
                        <p>
                          {investor?.userId?.name} {investor?.userId?.surname}
                        </p>
                        <p>{investor?.userId?.email}</p>
                        <div style={{display: "flex", flexDirection: "column"}}>
                          <span>{investor?.assetIds[0]?.assetName}</span>
                        </div>
                      </div>
                      {/* <button
                        className="button_portfolioDatanew"
                        onClick={() => handleShowDetails(investor)}
                      >
                        Details
                      </button> */}
                    </div>
                  ))}
                </div>
              </div>
              <div className="Total_number_people">
                <p className="text">
                  <b style={{fontSize: "13px"}}>{investoraccount?.length} people </b> have access to <br /> {selectedItem?.assetName}
                </p>
              </div>
            </div>
          </div>
        )}

        {activeTab === "employees" && (
          <div className="add_people">
            <div>
              <InputBox
                label={"add people"}
                value={userEmail}
                onChange={handleEmailChange}
              />
              <button onClick={handleAddClick}>Add</button>
            </div>
            {emailError && <p style={{color: "red"}}>{emailError}</p>}
            <span>After people will be notified by email they have to confirm invitation.</span>
          </div>
        )}

        {activeTab === "syndicate" && (
          <div className="add_people">
            <div>
              <InputBox
                label={"add people"}
                value={userEmail}
                onChange={handleEmailChange}
              />
              <button
                onClick={handleSendAccess}
                disabled={loading}
                className="investment_send_email"
              >
                {loading ? (
                  <CircularProgress
                    size={20}
                    style={{color: "white"}}
                  />
                ) : (
                  "Invite Investor"
                )}
              </button>
            </div>
            {emailError && <p style={{color: "red"}}>{emailError}</p>}
            <span>After people will be notified by email they have to confirm invitation.</span>
          </div>
        )}
      </div>

      {showPopup && selectedAssetDetails && (
        <AssetDetailsPopup
          asset={selectedAssetDetails}
          onClose={handleClosePopup}
          assetsData={filteredAssetsData.find((asset) => asset?.userId?._id === selectedAssetDetails?.userId?._id)}
        />
      )}
      {showPopup && !selectedAssetDetails && (
        <AccessPopup
          onClose={handleClosePopup}
          selectedItem={selectedItem}
          userEmail={userEmail}
          userInformation={userInformation}
        />
      )}
    </div>
  );
};

const AssetDetailsPopup = ({asset, onClose}) => {
  const [assetDetails, setAssetDetails] = useState(asset || {});
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const {idToken} = useSelector((state) => state.auth);

  const handleCheckboxChange = async (section, field) => {
    const updatedValue = !assetDetails[field];
    setAssetDetails((prevDetails) => ({
      ...prevDetails,
      [field]: updatedValue,
    }));
    setUpdateLoading(true);
    setUpdateError("");
    setSuccessMessage("");

    try {
      if (!assetDetails._id) {
        throw new Error("User permissions ID not available.");
      }

      const payload = {
        _id: assetDetails._id,
        updatedPermissions: [
          {
            section,
            permissions: {
              [field]: updatedValue,
            },
          },
        ],
        assetIds: [asset._id],
        chatAccess: !!asset.chatAccess,
      };

      await axios.patch(Endpoint.updateAccess, payload, {
        headers: {
          "auth-token": idToken,
        },
      });

      setSuccessMessage(`${field} status updated successfully.`);
    } catch (err) {
      setUpdateError(`Failed to update ${field} status.`);
    } finally {
      setUpdateLoading(false);
    }
  };

  const renderCheckbox = (label, field, section) => (
    <div
      key={field}
      style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px"}}
    >
      <label style={{flex: "1", display: "flex", alignItems: "center", cursor: "pointer", gap: "30px"}}>
        <Checkbox
          type="checkbox"
          checked={!!assetDetails[field]}
          onChange={() => handleCheckboxChange(section, field)}
          style={{marginRight: "15px"}}
        />
        {label}
      </label>
    </div>
  );

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "8px",
          width: "80%",
          maxWidth: "600px",
          padding: "20px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          position: "relative",
        }}
      >
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #eaeaea", paddingBottom: "15px"}}>
          <h2 style={{margin: 0}}>Asset Details</h2>
          <Button
            onClick={onClose}
            style={{fontSize: "1.5rem", lineHeight: "1em", backgroundColor: "transparent", border: "none", cursor: "pointer"}}
          >
            &times;
          </Button>
        </div>
        <div style={{padding: "20px 0"}}>
          <div style={{display: "flex", alignItems: "center", marginBottom: "20px"}}>
            <img
              src={asset?.owner?.profileImage || profilebig}
              alt="Profile"
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                objectFit: "cover",
                marginRight: "20px",
              }}
            />
            <div style={{display: "flex", flexDirection: "column"}}>
              <div style={{marginBottom: "10px"}}>
                <span style={{fontWeight: "bold"}}>Employee Name: </span>
                <span>{asset?.userId?.name}</span>
              </div>
              <div>
                <span style={{fontWeight: "bold"}}>Access Given: </span>
                <span>{asset?.owner?.name || "N/A"}</span>
              </div>
            </div>
          </div>
          <div style={{marginBottom: "20px"}}>
            <h3 style={{fontSize: "1.2rem", marginBottom: "10px"}}>Property Data Overview:</h3>
            <div>
              {renderCheckbox("Financial KPI", "FinancialKPI", "Property Data Overview")}
              {renderCheckbox("Operating Income & Expenses", "OperatingIncomeExpenses", "Property Data Overview")}
              {renderCheckbox("Market Analysis", "MarketAnalysis", "Property Data Overview")}
              {renderCheckbox("Utilities", "Utilities", "Property Data Overview")}
              {renderCheckbox("Maintenance", "Maintenance", "Property Data Overview")}
              {renderCheckbox("Asset Name", "assetName", "Property Data Overview")}
              {renderCheckbox("Asset Description", "assetDescription", "Property Data Overview")}
            </div>
          </div>
          <div style={{marginBottom: "20px"}}>
            <h3 style={{fontSize: "1.2rem", marginBottom: "10px"}}>Chat Access</h3>
            <div>{renderCheckbox("Chat Access", "chatAccess", "chatAccess")}</div>
          </div>
        </div>
        {updateLoading && <p>Updating...</p>}
        {updateError && <p style={{color: "red"}}>{updateError}</p>}
        {successMessage && <p style={{color: "green"}}>{successMessage}</p>}
      </div>
    </div>
  );
};

const OverviewHeader = ({item}) => {
  const [headerIndex, setHeaderIndex] = useState(0);
  const headerTabs = ["Commercial", "Multifamily"];

  return (
    <div className="overview-header-container">
      <div className="overview-header-left">
        {headerTabs?.map((tab, index) => (
          <Link
            key={index}
            className={headerIndex === index ? "active" : ""}
            onClick={() => setHeaderIndex(index)}
          >
            {tab}
          </Link>
        ))}
      </div>
      <div className="overview-header-right">
        <SearchInput />
        <SortDropdown options={["Latest", "Oldest"]} />
      </div>
    </div>
  );
};

const PortfolioDashboard = () => {
  const {AssectDataOwnerOperator, loading, error, selectedProperty} = useSelector((state) => state.portfolio);
  return (
    <div className="dashboard-container">
      {AssectDataOwnerOperator?.map((item) => (
        <div
          className="portfolioDatanew-card"
          key={item.id}
        >
          <img
            className="All-assect-images"
            src={item.assetsImages[2]}
            alt={"image"}
          />
          <div className="card-info">
            <div className="upperSection">
              <h2>{item.assetName}</h2>
              <p>
                {item.assetsAddress}
                <br />
                {item.assetsAddress}
              </p>
            </div>
            <div className="lowerSection">
              <div className="price-wrapper">
                <h2>
                  {"0"}
                  {item.currency}/sqm
                </h2>
                <p
                  style={{
                    color: `${item.status === "My Asset" ? "#6B6C6F" : "#9EA0A5"}`,
                  }}
                >
                  {item.status}
                </p>
              </div>
              <Link
                to="/"
                className="asset-link-btn"
              >
                <PrimaryButtonSvg
                  classNames="white-bg noMargin secondary"
                  name="View Asset"
                  svgPath={linkButtonSvg}
                />
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};





export {AssetHeader, InviteBox, OverviewHeader, PortfolioDashboard};
