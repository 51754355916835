import React, { useState } from "react";
import "./password.scss";
import { InputBox } from "../../../components/reusableComponents/FormFields/FormFields";
import Checkbox from "./Checkbox";
import VPNDetector from "./Vpnfile";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import SuccessModal from "../../../components/reusableComponents/Alerts/Alerts/SuccessModal";
import ErrorModal from "../../../components/reusableComponents/Alerts/Alerts/ErrorModal";
import { returnMessages } from "../../../store/reducers/message";
import { returnErrors } from "../../../store/reducers/error";
import { PasswordReset } from "../../../utils/Axios";

function Password() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [currentPassword, setCurrentPassword] = useState("");
  const { idToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [Loading,setLoading] = useState(false) 

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    const strength = Math.min(value.length / 8, 1);
    setPassword(value);
    setPasswordStrength(strength);
    setPasswordMatch(confirmPassword === value);
  };

  const handleConfirmPasswordChange = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
    setPasswordMatch(password === value);
  };

  const handleCurrentPasswordChange = (event) => {
    setCurrentPassword(event.target.value);
  };

  const isPasswordValid = () => {
    return password.length >= 8 && password === confirmPassword;
  };

  const handleUpdatePassword = async () => {
    if (!isPasswordValid()) return;
  
    const updatePassword = {
      oldPassword: currentPassword,
      newPassword: password,
    };
  
    setLoading(true);
    try {
      const response = await PasswordReset(updatePassword, idToken);
  
      if (response.success) {
        dispatch(returnMessages(response.message, "Success"));
        setLoading(false);
        return response;
      } else {
        dispatch(returnErrors(response.message, "Error"));
        setLoading(false);
        return response;
      }
    } catch (error) {
      if (error.response) {
        dispatch(returnErrors(error.response.data.message || "Error While Updating Password", error.response.status));
      } else {
        dispatch(returnErrors("Server error", 500));
      }
      setLoading(false);
      throw new Error(error.response?.data?.message || "Error While Updating Password");
    }
  };
  
  

  return (
    <div className="password">
      <div className="password_upper">
        <div className="basic_header">
          <div>
            <p>Password</p>
            <span>Keep your account safe using a strong password</span>
          </div>
        </div>
        <div className="inputs">
          <div className="genral-input-password">
            <InputBox
              type={"password"}
              label={"Current Password"}
              placeholder={"Current Password"}
              onChange={handleCurrentPasswordChange}
              value={currentPassword}
              className="Old-Password-input"
            />
            <InputBox
              type={"password"}
              label={"New Password"}
              placeholder={"New Password"}
              value={password}
              onChange={handlePasswordChange}
              passwordStrength={passwordStrength}
              className="New-Password-input"
            />
            <InputBox
              type={"password"}
              label={"Password Confirmation"}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              placeholder={"Re-Enter New Password"}
              className="New-Password-input"
            />
          </div>
          <div className="password-genral-input">
            {password && <ProgressBar strength={passwordStrength} />}
            {!passwordMatch && (
              <span className="password_match">Passwords do not match</span>
            )}
          </div>
          <button
            className={`update_btn ${isPasswordValid() ? "enabled" : ""}`}
            disabled={!isPasswordValid()}
            onClick={handleUpdatePassword}
          >
            Update Password
          </button>
        </div>
      </div>
      {/* <div className="password_lower">
        <div className="basic_header">
          <div>
            <p>Privacy</p>
            <span>Set privacy preferences.</span>
          </div>
        </div>
        <div className="basic_header privacy">
          <div>
            <p>Discoverability</p>
            <span>Allow users from other companies to view your profile</span>
            <div className="privacy_row">
              <Checkbox label={"email"} />
              <Checkbox label={"mobile phone"} />
              <Checkbox label={"Location"} />
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", alignItems: "flex-start", gap: "6px" }}
          className="lower"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.49967 13.4993L3.99967 9.99935L2.83301 11.166L7.49967 15.8327L17.4997 5.83268L16.333 4.66602L7.49967 13.4993Z"
              fill="#9EA0A5"
            />
          </svg>
          <span>Changes are saved automatically</span>
        </div>
      </div>
      <VPNDetector />
      <ErrorModal /> */}
    </div>
  );
}

export default Password;

const ProgressBar = ({ strength, style }) => {
  const getColor = () => {
    if (strength === 1) {
      return "green";
    } else if (strength >= 0.5) {
      return "orange";
    } else {
      return "red";
    }
  };

  const getWidth = () => {
    return `${strength * 100}%`;
  };

  const getStrengthText = () => {
    if (strength === 1) {
      return "Strong";
    } else if (strength >= 0.5) {
      return "Strong Enough";
    } else {
      return "Weak";
    }
  };

  return (
    <div className="progress-bar" style={style}>
      <div
        className="progress-bar-inner"
        style={{ width: getWidth(), backgroundColor: getColor() }}
      ></div>
      <div className="progress-bar-text">{getStrengthText()}</div>
    </div>
  );
};