import React, {useState,useEffect} from "react";
import DropDown from "../../components/reusableComponents/Dropdown/Dropdown";
import {InputBox} from "../../components/reusableComponents/FormFields/FormFields";
import "./StartSyndicate.scss";
import {useNavigate} from "react-router-dom";
import cross from "../../assets/syndicate/svg/cross.svg";
import save from "../../assets/syndicate/svg/save.svg";
import eye from "../../assets/syndicate/svg/eye.svg";
import download from "../../assets/syndicate/svg/download.svg";
import submit from "../../assets/syndicate/svg/upload.svg";
import {useDispatch} from "react-redux";
import {PostSetupSyndicate} from "../../utils/Axios";
import {useSelector} from "react-redux";
import {returnMessages} from "../../store/reducers/message";
import {PrimaryButtonSvgFirst} from "../../components/reusableComponents/Buttons/Buttons";
import {DealsRoomRight} from "./Old Syndicate/SyndicateData";
import axios from "axios";
import Endpoint from "../../utils/Endpoint";

export const DealsRoomLeft = ({handleMenuClicked, navActive, setNav, setNavActive, setSetUpSyndicateData, setUpSyndicateData}) => {
  const SelectedData = useSelector((state) => state.portfolio?.selectedProperty);
  return (
    <div className="StartSyndicate-conatiner">
      <div className="StartSyndicate-deals-room-left-container">
        {navActive !== 11 && (
          <>
            <div className="hero-section-image">
              <img
                className="StartSyndicate-hero-img"
                src={SelectedData?.assetsImages[0]}
                alt=""
              />
              <div className="hero-section-img-title">
                <span>{SelectedData?.assetName}</span>
                {/* <div>
                  <span>Edit Cover</span>
                </div> */}
              </div>
            </div>
            <div className="StartSyndicate-data">
              <span
                className={navActive === 1 ? "deals-room-nav-active" : ""}
                onClick={() => setNavActive(1)}
              >
                General
              </span>
              <span
                className={navActive === 2 ? "deals-room-nav-active" : ""}
                onClick={() => setNavActive(2)}
              >
                Summary
              </span>
            </div>
          </>
        )}

        {navActive === 1 && (
          <GeneralSyndicate
            setUpSyndicateData={setUpSyndicateData}
            setSetUpSyndicateData={setSetUpSyndicateData}
            setNavActive={setNavActive}
          />
        )}
        {navActive === 2 && (
          <Summary
            setUpSyndicateData={setUpSyndicateData}
            setNavActive={setNavActive}
          />
        )}

        {navActive === 11 && <SubmitProposal handleMenuClicked={handleMenuClicked} />}
      </div>
    </div>
  );
};

const SubmitProposal = ({handleMenuClicked}) => {
  return (
    <div className="sp">
      <div className="sp_text">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <g clipPath="url(#clip0_13390_372443)">
            <path
              d="M20 0C8.97231 0 0 8.97154 0 20C0 31.0285 8.97231 40 20 40C31.0277 40 40 31.0285 40 20C40 8.97154 31.0277 0 20 0ZM20 38.4615C9.82077 38.4615 1.53846 30.1792 1.53846 20C1.53846 9.82077 9.82077 1.53846 20 1.53846C30.1792 1.53846 38.4615 9.82077 38.4615 20C38.4615 30.1792 30.1792 38.4615 20 38.4615Z"
              fill="#009639"
            />
            <path
              d="M29.4247 11.7969L17.6024 25.0969L10.4801 19.3992C10.1493 19.1338 9.66391 19.1884 9.39929 19.5192C9.13391 19.8515 9.18775 20.3354 9.51929 20.6008L17.2116 26.7546C17.3531 26.8677 17.5231 26.9231 17.6924 26.9231C17.9047 26.9231 18.1162 26.8354 18.2678 26.6646L30.5754 12.8185C30.8578 12.5015 30.8293 12.0146 30.5116 11.7331C30.1931 11.45 29.7078 11.4785 29.4247 11.7969Z"
              fill="#009639"
            />
          </g>
          <defs>
            <clipPath id="clip0_13390_372443">
              <rect
                width="40"
                height="40"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
        <p>
          The Syndicate was successfully created and invitation has been sent
          <br />
          to Private Investors interested in such opportunities
        </p>
      </div>

      <div className="sp_btns">
        <button
          icon={eye}
          value={"Investor's Preview"}
        />
        <div
          onClick={() => handleMenuClicked(15)}
          style={{marginTop: "0px"}}
          className="header-button-last"
        >
          <p>Track Updates</p>
        </div>
      </div>
    </div>
  );
};

export const GeneralSyndicate = ({setUpSyndicateData, setSetUpSyndicateData, setNavActive}) => {
  const [dealNameList, setDealNameList] = useState(["Core", "Core+", "Value-Added", "Opportunistic"]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [countryOfCitizenshipDA, setCountryOfCitizenshipDA] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSetUpSyndicateData((prevState) => ({
        ...prevState,
        general: {
          ...prevState.general,
          image: file,
        },
      }));
      setSelectedImage(URL.createObjectURL(file));
    } else {
      alert("File is not valid or too large.");
    }
  };

  const handleChangeGeneral = (key, value) => {
    setSetUpSyndicateData((prevState) => ({
      ...prevState,
      general: {
        ...prevState.general,
        [key]: value,
      },
    }));
  };

  const goToSummary = () => {
    setNavActive(2);
  };

  return (
    <>
      {/* Name */}
      <div className="first-box box-new">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Name</p>
        </div>
        <div className="box_desc">
          <p>Please enter the full name of the syndicate.</p>
        </div>
        <div className="financial-body">
          <InputBox
            placeholder={"Name"}
            onChange={(e) => handleChangeGeneral("Name", e.target.value)}
            value={setUpSyndicateData?.general?.Name || ""}
            width="100%"
          />
        </div>
      </div>
      {/* Yield */}
      <div className="first-box box-new">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Yield</p>
        </div>
        <div className="box_desc">
          <p>Enter the expected annual return on investment in percentage terms.</p>
        </div>
        <div className="financial-body">
          <InputBox
            placeholder={"Yield"}
            onChange={(e) => handleChangeGeneral("Yield", e.target.value)}
            value={setUpSyndicateData?.general?.Yield || ""}
            width="100%"
          />
        </div>
      </div>
      {/* LTV */}
      <div className="first-box box-new">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>LTV</p>
        </div>
        <div className="box_desc">
          <p>Please enter the loan-to-value (LTV) ratio, expressed as a percentage.</p>
        </div>
        <div className="financial-body">
          <InputBox
            placeholder={"LTV"}
            onChange={(e) => handleChangeGeneral("LTV", e.target.value)}
            value={setUpSyndicateData?.general?.LTV || ""}
            width="100%"
          />
        </div>
      </div>
      {/* Hold Period */}
      <div className="first-box">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Hold Period</p>
        </div>
        <div className="box_desc">
          <p>Please enter the expected holding period for the investment in years.</p>
        </div>
        <div className="setup-general">
          <InputBox
            placeholder={"Hold Period"}
            onChange={(e) => handleChangeGeneral("Hold_Period", e.target.value)}
            value={setUpSyndicateData?.general?.Hold_Period || ""}
            width="100%"
          />
        </div>
      </div>
      {/* Amount */}
      <div className="first-box box-new">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Amount</p>
        </div>
        <div className="box_desc">
          <p>Please enter the amount of capital you are looking to invest.</p>
        </div>
        <div className="financial-body">
          <InputBox
            placeholder={"Amount"}
            onChange={(e) => handleChangeGeneral("Amount", e.target.value)}
            value={setUpSyndicateData?.general?.Amount || ""}
            width="100%"
          />
        </div>
      </div>
      {/* Minimum Investment */}
      <div className="first-box box-new">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Minimum Investment</p>
        </div>
        <div className="box_desc">
          <p>Please enter the minimum amount you are looking to invest.</p>
        </div>
        <div className="financial-body">
          <InputBox
            placeholder={"Minimum Investment"}
            onChange={(e) => handleChangeGeneral("Minimum_Investment", e.target.value)}
            value={setUpSyndicateData?.general?.Minimum_Investment || ""}
            width="100%"
          />
        </div>
      </div>
      {/* Redemption Period */}
      <div className="first-box box-new">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Redemption Period</p>
        </div>{" "}
        <div className="box_desc">
          <p>Please enter the expected redemption period for the investment, in years.</p>
        </div>
        <div className="financial-body">
          <InputBox
            placeholder={"Redemption Period"}
            onChange={(e) => handleChangeGeneral("Redemption_Period", e.target.value)}
            value={setUpSyndicateData?.general?.Redemption_Period || ""}
            width="100%"
          />
        </div>
      </div>
      {/* Strategy - Dropdown */}
      <div className="first-box box-new">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Strategy</p>
        </div>
        <div className="box_desc">
          <p>Choose the investment strategy that aligns with your investment goals.</p>
        </div>
        <div className="financial-body">
          <DropDown
            classDataName={"syndicate-dropdown"}
            data={dealNameList}
            selectedData={setUpSyndicateData?.general?.Strategy}
            setSelectedData={(value) => handleChangeGeneral("Strategy", value)}
            dropdownState={countryOfCitizenshipDA}
            dropdownSetter={setCountryOfCitizenshipDA}
          />
        </div>
      </div>
      {/* Area, SF */}
      <div className="first-box box-new">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Area, SF</p>
        </div>
        <div className="box_desc">
          <p>Please enter the area of the property in square feet.</p>
        </div>
        <div className="financial-body">
          <InputBox
            placeholder={"Area, SF"}
            onChange={(e) => handleChangeGeneral("Area", e.target.value)}
            value={setUpSyndicateData?.general?.Area || ""}
            width="100%"
          />
        </div>
      </div>
      {/* Appraisal Value */}
      <div className="first-box box-new">
        <div className="first-inner-box">
          <img
            src={download}
            alt="download"
          />
          <p>Appraisal Value</p>
        </div>
        <div className="box_desc">
          <p>Please enter the appraised value of the property.</p>
        </div>
        <div className="financial-body">
          <InputBox
            placeholder={"Appraisal Value"}
            onChange={(e) => handleChangeGeneral("Appraisal_Value", e.target.value)}
            value={setUpSyndicateData?.general?.Appraisal_Value || ""}
            width="100%"
          />
        </div>
      </div>

      {/* Image Upload */}
      <div className="first-box box-new">
        <div className="first-inner-box">
          <img
            src={download}
            alt="Image Upload"
          />
          <p>Upload Image</p>
        </div>
        <div className="box_desc">
          <p>Upload an image of the property.</p>
        </div>
        <div className="financial-body">
          <input
            id="file-upload-input"
            className="file-upload"
            type="file"
            accept="image/*"
            style={{display: "none"}}
            onChange={handleImageChange}
          />

          {selectedImage && (
            <div>
              <img
                src={selectedImage}
                alt="Selected Preview"
                style={{maxWidth: "100%"}}
              />
            </div>
          )}
          <div className="Button_Combine">
            <button
              className="Upload_buttton_StartSyndicate"
              onClick={() => document.getElementById("file-upload-input").click()}
            >
              Select Image
            </button>
          </div>
          <button
            className="Summary_data_column"
            onClick={goToSummary}
          >
            Go to Summary
          </button>
        </div>
      </div>
    </>
  );
};

const StartSyndicate = ({handleMenuClicked}) => {
  const [navActive, setNav] = useState(1);
  const [loading, setLoading] = useState(false);
  const {idToken} = useSelector((state) => state?.auth);
  const selectedData = useSelector((state) => state?.portfolio?.selectedProperty?.assetId);
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [setUpSyndicateData, setSetUpSyndicateData] = useState({
    asset_id: selectedData,
    general: {
      Name: "",
      Yield: "",
      LTV: "",
      Hold_Period: "",
      Amount: "",
      Minimum_Investment: "",
      Redemption_Period: "",
      Strategy: "",
      Area: "",
      Appraisal_Value: "",
      image: null,
    },
    disclaimer: "",
  });

  const setNavActive = (id) => {
    setNav(id);
  };

  const dispatch = useDispatch();

  const uploadImage = async (file) => {
    if (!file) {
      alert("Please select an image first.");
      return null;
    }

    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(`${Endpoint.uploadimageStartSyndicate}?assetId=${selectedData}`, formData, {
        headers: {"Content-Type": "multipart/form-data"},
      });

      if (response.data.success) {
        return response?.data?.data?.imageUrl;
      } else {
        alert("Error uploading image: " + response.data.message);
        return null;
      }
    } catch (error) {
      alert("There was an issue uploading the image. Please try again.");
      return null;
    }
  };

  const handleSubmitSetUpData = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("asset_id", setUpSyndicateData.asset_id);

    for (const [key, value] of Object.entries(setUpSyndicateData.general)) {
      formData.append(`general[${key}]`, value || "");
    }

    if (setUpSyndicateData.general.image) {
      const uploadedImageUrl = await uploadImage(setUpSyndicateData.general.image);
      if (uploadedImageUrl) {
        formData.append("general[imageUrl]", uploadedImageUrl);
      }
    }

    formData.append("disclaimer", setUpSyndicateData.disclaimer || "");

    try {
      const result = await PostSetupSyndicate(formData, idToken);
      if (result.success) {
        dispatch(returnMessages(result.message, 200));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="deals-room-center asset-center-con setup-StartSyndicate">
      <div className="deals-room-center-header-StartSyndicate">
        <p>Start a Syndicate</p>
        <div className="deals-room-header-button">
          <>
            <PrimaryButtonSvgFirst
              svgPath={cross}
              name={"Cancel"}
              classNames="StartSyndicate-button"
            />
            <PrimaryButtonSvgFirst
              svgPath={save}
              name={"Save"}
              classNames="StartSyndicate-button"
            />
            <PrimaryButtonSvgFirst
              svgPath={submit}
              name={loading ? <div className="loading-spinner-StartSyndicate"></div> : "Submit"}
              classNames="StartSyndicate-button"
              onclick={handleSubmitSetUpData}
              disabled={loading}
            />
          </>
        </div>
      </div>

      <div className="StartSyndicate-deals-room-container">
        <DealsRoomLeft
          handleMenuClicked={handleMenuClicked}
          navActive={navActive}
          setNavActive={setNavActive}
          setSetUpSyndicateData={setSetUpSyndicateData}
          setUpSyndicateData={setUpSyndicateData}
        />
        <div className="dealsRoomRightWrapper">
          <DealsRoomRight data={setUpSyndicateData} />
        </div>
      </div>
    </div>
  );
};

const Summary = ({setUpSyndicateData, setNavActive}) => {
  const {Name, Yield, LTV, Hold_Period, Amount, Minimum_Investment, Redemption_Period, Strategy, Area, Appraisal_Value, image} = setUpSyndicateData.general;

  const goBackToGeneral = () => {
    setNavActive(1);
  };

  return (
    <div className="summary-container">
      <div className="summary-details">
        <h3>Summary</h3>
        <p>
          <strong>Name:</strong> {Name}
        </p>
        <p>
          <strong>Yield:</strong> {Yield}
        </p>
        <p>
          <strong>LTV:</strong> {LTV}
        </p>
        <p>
          <strong>Hold Period:</strong> {Hold_Period}
        </p>
        <p>
          <strong>Amount:</strong> {Amount}
        </p>
        <p>
          <strong>Minimum Investment:</strong> {Minimum_Investment}
        </p>
        <p>
          <strong>Redemption Period:</strong> {Redemption_Period}
        </p>
        <p>
          <strong>Strategy:</strong> {Strategy}
        </p>
        <p>
          <strong>Area, SF:</strong> {Area}
        </p>
        <p>
          <strong>Appraisal Value:</strong> {Appraisal_Value}
        </p>
      </div>
      {image && (
        <div className="summary-image-container">
          <img
            src={URL.createObjectURL(image)}
            alt="Uploaded"
            className="summary-image"
          />
          <div className="StartSyndicate_Summary">
            <button
              onClick={goBackToGeneral}
              className="back-button PrimaryButton"
            >
              Back
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default StartSyndicate;
