import React, {useEffect, useRef, useState} from "react";

import {IOSSwitch, InputBox} from "../../../components/reusableComponents/FormFields/FormFields";
import ProfilePill from "../../../assets/svg/ADProfilePill.png";
import {PrimaryButton, PrimaryButtonSvgFirst} from "../../../components/reusableComponents/Buttons/Buttons";
import labelFlag from "../../../assets/svg/label_flag.png";
import ComparisionIcon from "../../../assets/svg/comparepng.png";
import {PhoneSvg, BookmarkSvg, NotebookSvg, CompareSvg, ShareSvg, PiechartSvg, BulbSvg, SliderIcon, EditIcon, flagIcon, ProfilePicIcon, comparisionSvg, compareArrows} from "../Svgimages/svgallimnages";
//Bookmark Panel
export function BookmarkPanel() {
  const handleNewlabel = () => {
    setItems((prev) => {
      const newLabel = {colour: prev[3].colour, text: "New Label", value: "0"};
      const newArray = [...prev, newLabel];
      return newArray;
    });
  };
  const [items, setItems] = useState([
    {
      colour: "#00629b",
      text: "Good Location",
      value: "2",
    },
    {
      colour: "#1c6de1",
      text: "For review",
      value: "2",
    },
    {
      colour: "#cb333b",
      text: "Show to the broker",
      value: "2",
    },
    {
      colour: "#ed962d",
      text: "Interesting",
      value: "2",
    },
    {
      colour: "#7ec331",
      text: "Ask for a discount",
      value: "2",
    },
    {
      colour: "#9ea0a5",
      text: "For Greg",
      value: "2",
    },
  ]);
  return (
    <div className="tab_panel_wrapper">
      <div className="bookmark_container">
        <div className="header">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10ZM10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C14.411 2 18 5.589 18 10C18 14.411 14.411 18 10 18ZM11 13C11 13.552 10.552 14 10 14C9.448 14 9 13.552 9 13V11H7C6.448 11 6 10.552 6 10C6 9.448 6.448 9 7 9H9V7C9 6.448 9.448 6 10 6C10.552 6 11 6.448 11 7V9H13C13.552 9 14 9.448 14 10C14 10.552 13.552 11 13 11H11V13Z"
              fill="white"
              fill-opacity="0.01"
            />
            <mask
              id="mask0_13635_40581"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="20"
              height="20"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10ZM10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C14.411 2 18 5.589 18 10C18 14.411 14.411 18 10 18ZM11 13C11 13.552 10.552 14 10 14C9.448 14 9 13.552 9 13V11H7C6.448 11 6 10.552 6 10C6 9.448 6.448 9 7 9H9V7C9 6.448 9.448 6 10 6C10.552 6 11 6.448 11 7V9H13C13.552 9 14 9.448 14 10C14 10.552 13.552 11 13 11H11V13Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_13635_40581)">
              <rect
                x="-1"
                y="-1"
                width="22"
                height="22"
                fill="#6B6C6F"
              />
            </g>
          </svg>

          <p onClick={() => handleNewlabel()}>Add New Label</p>
        </div>
        <div className="labels_wrapper">
          {items.map((item) => {
            return (
              <Label
                items={items}
                colour={item.colour}
                text={item.text}
                value={item.value}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
export const Label = ({colour, text, value, items}) => {
  const [currentColour, setCurrentColour] = useState(colour);
  const [currentText, setCurrentText] = useState(text);
  const colours = items.map((item) => item.colour);
  const uniqueColours = [...new Set(colours)];
  const defaultColours = uniqueColours;
  const [selectedColour, setSelectedColour] = useState();
  const [editMode, setEditMode] = useState(false);

  const handleAccept = ({decision}) => {
    setEditMode(false);
    if (!decision) {
      setCurrentColour(colour);
    } else {
      setCurrentColour(selectedColour);
    }
  };
  const handleSelectedColour = (color) => {
    setSelectedColour(color);
  };
  const inputRef = useRef(null);
  useEffect(() => {
    if (editMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editMode]);
  return (
    <>
      <div style={{display: "flex", width: "100%", alignItems: "center", gap: "20px", maxWidth: "290px"}}>
        <div
          className="label"
          style={{backgroundColor: selectedColour || currentColour}}
        >
          <div className="left">
            <img
              src={labelFlag}
              alt=""
              style={{invert: `${selectedColour || currentColour === "fff" ? "1" : "0"}`}}
            />
            {!editMode ? (
              <p style={{color: `${selectedColour === "#fff" ? "#3E3F42" : "#fff"}`}}>{currentText}</p>
            ) : (
              <input
                ref={inputRef}
                id="label_input"
                style={{color: `${selectedColour === "#fff" ? "#3E3F42" : "#fff"}`}}
                value={currentText}
                onChange={(e) => {
                  setCurrentText(e.target.value);
                }}
              ></input>
            )}
          </div>
          <div className="right">
            <span>{value}</span>
          </div>
        </div>
        {(!editMode && (
          <div
            onClick={() => {
              setEditMode(true);
            }}
          >
            <EditIcon />
          </div>
        )) || (
          <div className="button_wrapper">
            <svg
              onClick={() => handleAccept(true)}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.9999 16.2016L4.7999 12.0016L3.3999 13.4016L8.9999 19.0016L20.9999 7.00156L19.5999 5.60156L8.9999 16.2016Z"
                fill="#56C568"
              />
            </svg>
            <svg
              onClick={() => handleAccept(false)}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                fill="#EB5757"
              />
            </svg>
          </div>
        )}
      </div>
      {
        <div
          className="colour_picker"
          style={{display: `${editMode ? "flex" : "none"}`}}
        >
          {defaultColours.map((color, index) => (
            <div
              key={index}
              className={`circle ${selectedColour === color ? "active" : ""}`}
              style={{background: color}}
              onClick={() => handleSelectedColour(color)}
            ></div>
          ))}
        </div>
      }
    </>
  );
};
export function NotebookPanel({}) {
  const [formData, setFormData] = useState({
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here, for example, log the submitted message
    // Reset the form after submission
    setFormData({message: ""});
  };

  return (
    <div className="tab_panel_wrapper">
      <div className="notebook_wrapper">
        <div className="messages_wrapper">
          <MessageBox />
          <MessageBox />
        </div>
        <div className="form_wrapper">
          <form onSubmit={handleSubmit}>
            <div>
              <input
                id="message"
                name="message"
                value={formData?.message}
                onChange={handleChange}
                type="text"
                placeholder="Type your message.."
                required
              ></input>
            </div>
            <div>
              <button type="submit">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_5731_126713)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 9.27979L10.78 20L20 0L0 9.27979ZM3.509 9.88184L15.949 4.09277L10.161 16.5703L8.58801 14.8599L11.706 8.25537L5.073 11.3643L3.509 9.88184Z"
                      fill="#7D55C7"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5731_126713">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export const MessageBox = ({}) => {
  return (
    <div className="message">
      <div className="header">
        <div className="left">
          <ProfilePicIcon />
        </div>
        <div className="right">
          <p>You</p>
          <span>Nov. 20, 2019, 09:52 AM</span>
        </div>
      </div>
      <div className="textbox">
        <p>We have lake-front vacation rentals. No specific liability waiver for that, but house rules stipulate risks.</p>
      </div>
    </div>
  );
};
export function ComparisionPanel({}) {
  return (
    <div className="tab_panel_wrapper">
      <div className="comparision_wrapper">
        <div className="top">
          <button>
            <img
              src={ComparisionIcon}
              alt=""
            />
            <p>Add to comparison</p>
          </button>
          <p>Clear List</p>
        </div>
        <div className="mid">
          <div className="choice_list">
            <div className="row">
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.5 8H11.5V6H9.5V8ZM9.5 12H11.5V10H9.5V12ZM11.5 18V14H9.5V18H6.5V5H9.5H11.5H14.5V18H11.5ZM18.5 16V18H16.5V3H14.5H11.5V0H9.5V3H6.5H4.5V18H2.5V16H0.5V18V20H4.5H6.5H14.5H16.5H20.5V18V16H18.5Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_2520_27488"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="21"
                  height="20"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.5 8H11.5V6H9.5V8ZM9.5 12H11.5V10H9.5V12ZM11.5 18V14H9.5V18H6.5V5H9.5H11.5H14.5V18H11.5ZM18.5 16V18H16.5V3H14.5H11.5V0H9.5V3H6.5H4.5V18H2.5V16H0.5V18V20H4.5H6.5H14.5H16.5H20.5V18V16H18.5Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_2520_27488)">
                  <rect
                    x="-0.5"
                    y="-1"
                    width="22"
                    height="22"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
              <p>Lotus Temple - Beautiful Offices</p>
              <svg
                className="removeButton"
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.9142 9.00091L14.4497 5.46537L13.0355 4.05116L9.5 7.58669L5.96447 4.05116L4.55025 5.46537L8.08579 9.00091L4.55025 12.5364L5.96447 13.9507L9.5 10.4151L13.0355 13.9507L14.4497 12.5364L10.9142 9.00091Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_2537_203369"
                  maskUnits="userSpaceOnUse"
                  x="4"
                  y="4"
                  width="11"
                  height="10"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.9142 9.00091L14.4497 5.46537L13.0355 4.05116L9.5 7.58669L5.96447 4.05116L4.55025 5.46537L8.08579 9.00091L4.55025 12.5364L5.96447 13.9507L9.5 10.4151L13.0355 13.9507L14.4497 12.5364L10.9142 9.00091Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_2537_203369)">
                  <rect
                    x="0.5"
                    width="18"
                    height="18"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
            </div>
            <div className="row">
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.5 8H11.5V6H9.5V8ZM9.5 12H11.5V10H9.5V12ZM11.5 18V14H9.5V18H6.5V5H9.5H11.5H14.5V18H11.5ZM18.5 16V18H16.5V3H14.5H11.5V0H9.5V3H6.5H4.5V18H2.5V16H0.5V18V20H4.5H6.5H14.5H16.5H20.5V18V16H18.5Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_2520_27488"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="21"
                  height="20"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.5 8H11.5V6H9.5V8ZM9.5 12H11.5V10H9.5V12ZM11.5 18V14H9.5V18H6.5V5H9.5H11.5H14.5V18H11.5ZM18.5 16V18H16.5V3H14.5H11.5V0H9.5V3H6.5H4.5V18H2.5V16H0.5V18V20H4.5H6.5H14.5H16.5H20.5V18V16H18.5Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_2520_27488)">
                  <rect
                    x="-0.5"
                    y="-1"
                    width="22"
                    height="22"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
              <p>Lotus Temple - Beautiful Offices</p>
              <svg
                className="removeButton"
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.9142 9.00091L14.4497 5.46537L13.0355 4.05116L9.5 7.58669L5.96447 4.05116L4.55025 5.46537L8.08579 9.00091L4.55025 12.5364L5.96447 13.9507L9.5 10.4151L13.0355 13.9507L14.4497 12.5364L10.9142 9.00091Z"
                  fill="white"
                  fill-opacity="0.01"
                />
                <mask
                  id="mask0_2537_203369"
                  maskUnits="userSpaceOnUse"
                  x="4"
                  y="4"
                  width="11"
                  height="10"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.9142 9.00091L14.4497 5.46537L13.0355 4.05116L9.5 7.58669L5.96447 4.05116L4.55025 5.46537L8.08579 9.00091L4.55025 12.5364L5.96447 13.9507L9.5 10.4151L13.0355 13.9507L14.4497 12.5364L10.9142 9.00091Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_2537_203369)">
                  <rect
                    x="0.5"
                    width="18"
                    height="18"
                    fill="#9EA0A5"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div className="bottom">
          <button>
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.00817 11.668H2.1665V13.3346H8.00817V15.8346L11.3332 12.5013L8.00817 9.16797V11.668V11.668ZM12.9915 10.8346V8.33464H18.8332V6.66797H12.9915V4.16797L9.6665 7.5013L12.9915 10.8346V10.8346Z"
                fill="white"
              />
            </svg>
            <p>Compare Assets</p>
          </button>
        </div>
      </div>
    </div>
  );
}
export function SharePanel({}) {
  return (
    <div className="tab_panel_wrapper">
      <div className="share_wrapper">
        <div className="input_wrapper">
          <InputBox
            type={"text"}
            label="Add people"
            placeholder="Name or email address"
          />
          <PrimaryButton name={"Share"} />
        </div>
        <div className="tags">
          <div className="tag">
            <p>Della.Garrett@real-inc.com</p>{" "}
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.4142 9.00091L13.9497 5.46537L12.5355 4.05116L9 7.58669L5.46447 4.05116L4.05025 5.46537L7.58579 9.00091L4.05025 12.5364L5.46447 13.9507L9 10.4151L12.5355 13.9507L13.9497 12.5364L10.4142 9.00091Z"
                fill="white"
                fill-opacity="0.01"
              />
              <mask
                id="mask0_13677_148860"
                maskUnits="userSpaceOnUse"
                x="4"
                y="4"
                width="10"
                height="10"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.4142 9.00091L13.9497 5.46537L12.5355 4.05116L9 7.58669L5.46447 4.05116L4.05025 5.46537L7.58579 9.00091L4.05025 12.5364L5.46447 13.9507L9 10.4151L12.5355 13.9507L13.9497 12.5364L10.4142 9.00091Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_13677_148860)">
                <rect
                  opacity="0.5"
                  width="18"
                  height="18"
                  fill="#BCBCBC"
                />
              </g>
            </svg>
          </div>
          <div className="tag">
            <p>Jack Stone@smartinvest.com</p>{" "}
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.4142 9.00091L13.9497 5.46537L12.5355 4.05116L9 7.58669L5.46447 4.05116L4.05025 5.46537L7.58579 9.00091L4.05025 12.5364L5.46447 13.9507L9 10.4151L12.5355 13.9507L13.9497 12.5364L10.4142 9.00091Z"
                fill="white"
                fill-opacity="0.01"
              />
              <mask
                id="mask0_13677_148860"
                maskUnits="userSpaceOnUse"
                x="4"
                y="4"
                width="10"
                height="10"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.4142 9.00091L13.9497 5.46537L12.5355 4.05116L9 7.58669L5.46447 4.05116L4.05025 5.46537L7.58579 9.00091L4.05025 12.5364L5.46447 13.9507L9 10.4151L12.5355 13.9507L13.9497 12.5364L10.4142 9.00091Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_13677_148860)">
                <rect
                  opacity="0.5"
                  width="18"
                  height="18"
                  fill="#BCBCBC"
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="copy_card">
          <div className="circle">
            <svg
              width="20"
              height="12"
              viewBox="0 0 20 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.99978 10H5.99978C3.66278 10 1.78677 7.9851 2.01977 5.6001C2.22177 3.5221 4.08976 2 6.17776 2H6.99978C7.55178 2 7.99978 1.552 7.99978 1C7.99978 0.448 7.55178 0 6.99978 0H6.25177C2.96577 0 0.100774 2.53194 0.00277406 5.81494C-0.0992259 9.21294 2.62478 12 5.99978 12H6.99978C7.55178 12 7.99978 11.552 7.99978 11C7.99978 10.448 7.55178 10 6.99978 10ZM13.7478 0H12.9998C12.4478 0 11.9998 0.448 11.9998 1C11.9998 1.552 12.4478 2 12.9998 2H13.8218C15.9098 2 17.7778 3.5221 17.9798 5.6001C18.2128 7.9851 16.3368 10 13.9998 10H12.9998C12.4478 10 11.9998 10.448 11.9998 11C11.9998 12.104 12.9998 12 13.9998 12C17.3748 12 20.0988 9.21294 19.9968 5.81494C19.8988 2.53194 17.0338 0 13.7478 0ZM4.99978 5H14.9998C15.5518 5 15.9998 5.448 15.9998 6C15.9998 7.346 15.2108 7 4.99978 7C4.44778 7 3.99978 6.552 3.99978 6C3.99978 5.448 4.44778 5 4.99978 5Z"
                fill="white"
              />
            </svg>
          </div>

          <div className="box">
            <div className="text_wrapper">
              <span>Share a link</span>
              <p>lily.pads/f3h4fnk30</p>
            </div>
            <PrimaryButton name="Copy Link" />
          </div>
        </div>
      </div>
    </div>
  );
}
export function InvestorFeedPanel() {
  return (
    <div className="tab_panel_wrapper">
      <div className="investor_feed">
        <div className="header">
          <p>Investor Feed</p> <span>4 Potential investors</span>
        </div>
        <div className="bottom_wrapper">
          <div className="row">
            <img
              src={ProfilePill}
              alt=""
            />
          </div>
          <div className="row">
            <img
              src={ProfilePill}
              alt=""
            />
          </div>
          <div className="row">
            <img
              src={ProfilePill}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
