import React, {useEffect, useRef, useState} from "react";
import Propcard1 from "../../../assets/Image/assetDiscovery/propcard1.png";
import moment from "moment";
import {FormatUSD, daysAgo, formatUSD} from "../../../utils/helpers";
import {PropertyInfoTab} from './PropertyInfoTab'



export function PropertyCardMini({collapsed, onClick, card, assetIdList}) {
    const [value, setValue] = useState(collapsed);
    // useEffect(() => {
    //   setValue(collapsed);
    // }, [value]);
    return (
      <div className="property_card_mini_wrapper">
        <div className="property_card_mini">
          <div className="property_card_mini-T">
            <div className="photo_wrapper">
              <img
                src={Propcard1}
                alt=""
              />
            </div>
            <div className="text_wrapper">
              <h3>{card?.property?.name}</h3>
              <div style={{display: "flex"}}>
                <p>{card?.property?.address}</p>
              </div>
              <div className="price_wrapper">
                <h2>$1,290,000</h2> <span>{card?.property?.status}</span>
              </div>
            </div>
          </div>
          <div className="property_card_mini-M">
            <div className="stats">
              <div className="date">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.4 8C14.4 4.4712 11.5288 1.6 8 1.6C4.4712 1.6 1.6 4.4712 1.6 8C1.6 11.5288 4.4712 14.4 8 14.4C11.5288 14.4 14.4 11.5288 14.4 8ZM16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8ZM12.8 8.8C12.8 9.2416 12.4416 9.6 12 9.6H8.8C7.916 9.6 7.2 8.884 7.2 8V4C7.2 3.5584 7.5584 3.2 8 3.2C8.4416 3.2 8.8 3.5584 8.8 4V7.2C8.8 7.64 9.16 8 9.6 8H12C12.4416 8 12.8 8.3584 12.8 8.8Z"
                    fill="white"
                    fill-opacity="0.01"
                  />
                  <mask
                    id="mask0_13625_124448"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="16"
                    height="16"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.4 8C14.4 4.4712 11.5288 1.6 8 1.6C4.4712 1.6 1.6 4.4712 1.6 8C1.6 11.5288 4.4712 14.4 8 14.4C11.5288 14.4 14.4 11.5288 14.4 8ZM16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8ZM12.8 8.8C12.8 9.2416 12.4416 9.6 12 9.6H8.8C7.916 9.6 7.2 8.884 7.2 8V4C7.2 3.5584 7.5584 3.2 8 3.2C8.4416 3.2 8.8 3.5584 8.8 4V7.2C8.8 7.64 9.16 8 9.6 8H12C12.4416 8 12.8 8.3584 12.8 8.8Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_13625_124448)">
                    <rect
                      x="-0.800003"
                      y="-0.800781"
                      width="17.6"
                      height="17.6"
                      fill="#9EA0A5"
                    />
                  </g>
                </svg>
                {moment(card?.createdAt).format("MMM. DD, YYYY")}
              </div>
              <div className="views">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.5 8.43333H11.85L14.1333 6.08333C11.8583 3.83333 8.17496 3.75 5.89996 6C3.62496 8.25833 3.62496 11.9 5.89996 14.1583C8.17496 16.4167 11.8583 16.4167 14.1333 14.1583C15.2666 13.0417 15.8333 11.7333 15.8333 10.0833H17.5C17.5 11.7333 16.7666 13.875 15.3 15.325C12.375 18.225 7.62496 18.225 4.69996 15.325C1.7833 12.4333 1.7583 7.73333 4.6833 4.84167C7.6083 1.95 12.3 1.95 15.225 4.84167L17.5 2.5V8.43333ZM10.4166 6.66667V10.2083L13.3333 11.9417L12.7333 12.95L9.16663 10.8333V6.66667H10.4166Z"
                    fill="#9EA0A5"
                  />
                </svg>
                {daysAgo(card?.createdAt)}
              </div>
              <div className="active">
                <svg
                  width="18"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="path-1-outside-1_13699_64738"
                    maskUnits="userSpaceOnUse"
                    x="-0.333496"
                    y="1"
                    width="19"
                    height="14"
                    fill="black"
                  >
                    <rect
                      fill="white"
                      x="-0.333496"
                      y="1"
                      width="19"
                      height="14"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.99984 3C5.6665 3 2.81984 5.07333 1.6665 8C2.81984 10.9267 5.6665 13 8.99984 13C12.3332 13 15.1798 10.9267 16.3332 8C15.1798 5.07333 12.3332 3 8.99984 3ZM8.99984 11.3333C7.15984 11.3333 5.6665 9.84 5.6665 8C5.6665 6.16 7.15984 4.66667 8.99984 4.66667C10.8398 4.66667 12.3332 6.16 12.3332 8C12.3332 9.84 10.8398 11.3333 8.99984 11.3333ZM8.99984 6C7.89317 6 6.99984 6.89333 6.99984 8C6.99984 9.10667 7.89317 10 8.99984 10C10.1065 10 10.9998 9.10667 10.9998 8C10.9998 6.89333 10.1065 6 8.99984 6Z"
                    />
                  </mask>
                  <path
                    d="M1.6665 8L0.270957 7.45005L0.0542327 8L0.270957 8.54995L1.6665 8ZM16.3332 8L17.7287 8.54995L17.9454 8L17.7287 7.45005L16.3332 8ZM8.99984 1.5C5.02742 1.5 1.64167 3.97176 0.270957 7.45005L3.06205 8.54995C3.998 6.17491 6.30559 4.5 8.99984 4.5V1.5ZM0.270957 8.54995C1.64167 12.0282 5.02742 14.5 8.99984 14.5V11.5C6.30559 11.5 3.998 9.82509 3.06205 7.45005L0.270957 8.54995ZM8.99984 14.5C12.9723 14.5 16.358 12.0282 17.7287 8.54995L14.9376 7.45005C14.0017 9.82509 11.6941 11.5 8.99984 11.5V14.5ZM17.7287 7.45005C16.358 3.97176 12.9723 1.5 8.99984 1.5V4.5C11.6941 4.5 14.0017 6.17491 14.9376 8.54995L17.7287 7.45005ZM8.99984 9.83333C7.98826 9.83333 7.1665 9.01157 7.1665 8H4.1665C4.1665 10.6684 6.33141 12.8333 8.99984 12.8333V9.83333ZM7.1665 8C7.1665 6.98843 7.98826 6.16667 8.99984 6.16667V3.16667C6.33141 3.16667 4.1665 5.33157 4.1665 8H7.1665ZM8.99984 6.16667C10.0114 6.16667 10.8332 6.98843 10.8332 8H13.8332C13.8332 5.33157 11.6683 3.16667 8.99984 3.16667V6.16667ZM10.8332 8C10.8332 9.01157 10.0114 9.83333 8.99984 9.83333V12.8333C11.6683 12.8333 13.8332 10.6684 13.8332 8H10.8332ZM8.99984 4.5C7.06474 4.5 5.49984 6.06491 5.49984 8H8.49984C8.49984 7.72176 8.7216 7.5 8.99984 7.5V4.5ZM5.49984 8C5.49984 9.93509 7.06474 11.5 8.99984 11.5V8.5C8.7216 8.5 8.49984 8.27824 8.49984 8H5.49984ZM8.99984 11.5C10.9349 11.5 12.4998 9.93509 12.4998 8H9.49984C9.49984 8.27824 9.27808 8.5 8.99984 8.5V11.5ZM12.4998 8C12.4998 6.06491 10.9349 4.5 8.99984 4.5V7.5C9.27808 7.5 9.49984 7.72176 9.49984 8H12.4998Z"
                    fill="#9EA0A5"
                    mask="url(#path-1-outside-1_13699_64738)"
                  />
                </svg>
                {card?.property?.total_views?.total}/15 today
              </div>
            </div>
            <div className="boxes">
              <div className="top">
                <div className="box">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.2 6.4H8.8V4.8H7.2V6.4ZM7.2 9.6H8.8V8H7.2V9.6ZM8.8 14.4V11.2H7.2V14.4H4.8V4H7.2H8.8H11.2V14.4H8.8ZM14.4 12.8V14.4H12.8V2.4H11.2H8.8V0H7.2V2.4H4.8H3.2V14.4H1.6V12.8H0V14.4V16H3.2H4.8H11.2H12.8H16V14.4V12.8H14.4Z"
                      fill="white"
                      fill-opacity="0.01"
                    />
                    <mask
                      id="mask0_13699_64758"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="16"
                      height="16"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.2 6.4H8.8V4.8H7.2V6.4ZM7.2 9.6H8.8V8H7.2V9.6ZM8.8 14.4V11.2H7.2V14.4H4.8V4H7.2H8.8H11.2V14.4H8.8ZM14.4 12.8V14.4H12.8V2.4H11.2H8.8V0H7.2V2.4H4.8H3.2V14.4H1.6V12.8H0V14.4V16H3.2H4.8H11.2H12.8H16V14.4V12.8H14.4Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_13699_64758)">
                      <rect
                        x="-0.799805"
                        y="-0.800781"
                        width="17.6"
                        height="17.6"
                        fill="#6B6C6F"
                      />
                    </g>
                  </svg>
                  {card?.property?.property_type}
                </div>
                <div className="box">
                  <span>{card?.property?.area_sf}</span>
                  Area, SF
                </div>
                <div className="box">
                  <span>{card?.property?.Units_Duplex}</span>
                  Units Duplex
                </div>
              </div>
              <div className="bottom">
                <div>
                  <span>Sold: </span>
                  {moment(card?.property?.sold?.date).format("YYYY/MM")} | {formatUSD(card?.property?.sold?.price)}
                </div>
                <div>
                  <span>Debt: </span>
                  {moment(card?.property?.debt?.date).format("YYYY/MM")} | {formatUSD(card?.property?.debt?.amount)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="property_card_mini-B">
          <PropertyInfoTab
            tabNames={true}
            collapsed={collapsed}
            card={card}
            assetIdList={assetIdList}
          />
        </div>
  
        <div
          className="view_details_button"
          onClick={() => onClick()}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_13628_124239)">
              <path
                d="M17.2507 7.28585C13.2341 3.2693 6.69932 3.2693 2.68277 7.28585L0 9.96829L2.74932 12.7176C4.75743 14.7257 7.39527 15.7301 10.0334 15.7301C12.6716 15.7301 15.3091 14.7261 17.3176 12.7176L20.0003 10.0348L17.2507 7.28585ZM16.8395 12.2399C13.0865 15.9926 6.98007 15.9926 3.22703 12.2399L0.955405 9.96829L3.16047 7.76322C6.91351 4.01052 13.0199 4.01052 16.773 7.76322L19.0446 10.0348L16.8395 12.2399Z"
                fill="white"
              />
              <path
                d="M9.6527 7.28906C8.34864 7.28906 7.28783 8.34987 7.28783 9.65393C7.28783 9.84041 7.43919 9.99177 7.62567 9.99177C7.81216 9.99177 7.96351 9.84041 7.96351 9.65393C7.96351 8.72251 8.72128 7.96474 9.6527 7.96474C9.83919 7.96474 9.99054 7.81339 9.99054 7.6269C9.99054 7.44041 9.83952 7.28906 9.6527 7.28906Z"
                fill="white"
              />
              <path
                d="M9.99055 5.60156C7.56893 5.60156 5.59866 7.57183 5.59866 9.99345C5.59866 12.4151 7.56893 14.3853 9.99055 14.3853C12.4122 14.3853 14.3824 12.4151 14.3824 9.99345C14.3824 7.57183 12.4125 5.60156 9.99055 5.60156ZM9.99055 13.7097C7.94156 13.7097 6.27433 12.0424 6.27433 9.99345C6.27433 7.94447 7.94156 6.27724 9.99055 6.27724C12.0395 6.27724 13.7068 7.94447 13.7068 9.99345C13.7068 12.0424 12.0399 13.7097 9.99055 13.7097Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_13628_124239">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
          <p>View details</p>
        </div>
      </div>
    );
  }