import React, {useState} from "react";
import clock from "../../../../assets/svg/clock.svg";
import Delete from "../../../../assets/svg/delete.svg";
import {BookmarkSvg, CompareSvg, ShareSvg} from "../svg/svgimges";
import {deleteUserSearchHistory} from "../../../../utils/Axios";
import {Modal, Button} from "antd";

export const AssetHistoryBox = ({asset, idToken, userId, onDelete}) => {
  const {_id: assetId, assetName, assetsAddress, assetValue, assetsImages, createdAt} = asset || {};
  const [isModalVisible, setModalVisible] = useState(false);

  const handleDelete = async () => {
    const result = await deleteUserSearchHistory(idToken, userId, assetId);
    if (result?.success) {
      setModalVisible(false);
      if (onDelete) onDelete(assetId);
    } else {
      alert(result?.message || "Failed to delete asset.");
    }
  };

  return (
    <div className="asset_history_box">
      <div className="box_upper">
        <div className="property_card-T">
          <div className="photo_wrapper">
            <img
              style={{width: "100px", height: "94px", borderRadius: "4px"}}
              src={assetsImages?.[0] || "default-image.png"}
              alt={assetName}
            />
          </div>
          <div className="text_wrapper">
            <h3>{assetName || "Unknown Asset"}</h3>
            <div style={{display: "flex", gap: "12px"}}>
              <p>{assetsAddress || "No Address Available"}</p>
            </div>
            <div className="amount_assect">
              <h3>${assetValue?.toLocaleString() || "N/A"}</h3>
              <div className="bookmarkimages">
                <BookmarkSvg />
                <CompareSvg />
                <ShareSvg />
              </div>
            </div>
          </div>
        </div>
        <img
          src={Delete}
          alt="Delete"
          onClick={() => setModalVisible(true)}
          style={{cursor: "pointer"}}
        />
      </div>
      <div className="box_lower">
        <span>{createdAt}</span>
      </div>

      {/* Ant Design Modal */}
      <Modal
        title="Confirm Deletion"
        visible={isModalVisible}
        onOk={handleDelete}
        onCancel={() => setModalVisible(false)}
        okText="Delete"
        cancelText="Cancel"
      >
        <p>
          Are you sure you want to delete <strong>{assetName}</strong>?
        </p>
      </Modal>
    </div>
  );
};
