import cover from "../../../assets/Image/assetDiscovery/general-bg.png";
import "./recom.scss";
import SavedHeader from "../saveditems/SavedHeader";
import decline from "../../../assets/svg/cross-error.svg";
import { LenderTable } from "./LenderTable";

import React, {useState} from "react";


const Recom = () => {
  return (
    <div style={{maxWidth: "1111px"}}>
      <SavedHeader
        title={"Recommendation History"}
        dd1={false}
      />
      <div
        style={{padding: "0px", width: "maxContent"}}
        className="deals-room-cente asset-center-con lender_wrapper recom"
      >
        <LenderTable />
      </div>
    </div>
  );
};

export default Recom;


