import React, {useState, useEffect} from "react";
import "./NewBudgetTable.scss";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Table, Progress} from "antd";
import axios from "axios";
import {useSelector} from "react-redux";
import dayjs from "dayjs";
import {PrimaryButtonloading} from "../../components/reusableComponents/Buttons/Buttons";
import {Select, MenuItem, InputLabel, FormControl, TextField} from "@mui/material";
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, CircularProgress} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {styled} from "@mui/material/styles";
import Endpoint from "../../utils/Endpoint";

const NewBudgetTable = () => {
  const [budgetData, setBudgetData] = useState({data: [], totalItems: 0, totalPages: 0});
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const {selectedProperty} = useSelector((state) => state.portfolio);
  const property_code = selectedProperty?.Property_Code;
  const property_name = selectedProperty?.assetName;
  const {idToken} = useSelector((state) => state.auth);
  const [exportData, setExportData] = useState([]);
  const [file, setFile] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [fetchOrGenerate, setFetchOrGenerate] = useState("fetch");
  const [accountNumberFilter, setAccountNumberFilter] = useState("");
  const [accountNumbers, setAccountNumbers] = useState([]);
  const [BookTypes, setBookTypes] = useState([]);
  const [open, setOpen] = useState(false);
  const pageSize = 10;
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel")) {
      setFile(file);
    } else {
      alert("Please upload a valid Excel file (.xlsx or .xls).");
    }
  };

  const fetchBudgetData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(Endpoint.getBudgetData, {
        headers: {
          "auth-token": idToken,
        },
        params: {
          property_code,
          page: currentPage,
          limit: pageSize,
          year: selectedYear || undefined,
          month: selectedMonth,
          accountNo: accountNumberFilter,
        },
      });

      if (response.data) {
        setBudgetData({
          data: response.data.data || [],
          totalItems: response.data.totalItems || 0,
          totalPages: response.data.totalPages || 0,
        });
        setExportData(response.data.data || []);
        setAccountNumbers(response.data.distinctAccountNumbers || []);
        setBookTypes(response.data.lastBookTimes || []);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateBudget = async () => {
    setLoading(true);
    try {
      const response = await axios.get(Endpoint.generate_property, {
        headers: {
          "auth-token": idToken,
        },
        params: {
          property: property_code,
          page: 1,
          limit: pageSize,
        },
      });

      if (response.data) {
        setBudgetData({
          data: response.data.data || [],
          totalItems: response.data.totalItems || 0,
          totalPages: response.data.totalPages || 0,
        });
        setCurrentPage(1);
        setExportData(response.data.data || []);
        setFetchOrGenerate("generate");
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadExcel = async () => {
    try {
      const config = {
        headers: {
          "auth-token": idToken,
        },
        responseType: "blob",
      };

      const years = fetchOrGenerate === "fetch" ? [2025, 2026] : [];

      const response = await axios.post(
        `${Endpoint.ExportAsExcelData}?property_code=${property_code}`,
        {
          years,
        },
        config,
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${property_code}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {}
  };

  useEffect(() => {
    if (fetchOrGenerate === "fetch") {
      setSelectedYear(null);
      fetchBudgetData();
    }
  }, [fetchOrGenerate, currentPage, selectedMonth]);

  useEffect(() => {
    if (fetchOrGenerate === "generate") {
      handleGenerateBudget();
    }
  }, [fetchOrGenerate]);

  const processedBudgetItems = (budgetData.data || []).map((item, index) => ({
    ...item,
    serialNumber: (currentPage - 1) * pageSize + index + 1,
  }));

  const columns = [
    {title: "SrNo", dataIndex: "serialNumber", key: "serialNumber"},
    {title: "Account No", dataIndex: "accountNumber", key: "accountNumber"},
    {title: "Year", dataIndex: "Year", key: "Year"},
    // {title: "Book Type", dataIndex: "BookType", key: "BookType"},
    ...Array.from({length: 12}, (_, i) => ({
      title: new Date(0, i).toLocaleString("en", {month: "short"}),
      dataIndex: `MtdBudget${i + 1}`,
      key: `MtdBudget${i + 1}`,
    })),
  ];

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  // Upload Excel File  -
  const handleUploadExcel = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("property_code", property_code);

    try {
      setLoading(true);
      //  const response = await axios.post("http://3.143.248.19:8000/upload-excel", formData, {
      const response = await axios.post("/upload-excel", formData, {
        headers: {
          "x-api-key": "lilypads_khfikacvenvoedkjnvnwo_&^%$&jfowjf&^@%*^#*wbfhwrbfrskjnvs",
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
      });

      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${property_code}_uploaded.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        fetchBudgetData();
      }
    } catch (error) {
      alert(error.response?.data?.error || "File upload failed.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="newBudgetTable_container">
      <div className="generate_data">
        <h1>{loading ? "" : fetchOrGenerate === "fetch" ? `Budget Data for - ${property_name || "Property"}` : `Predicted Data for ${property_name}, ${selectedYear || "Year"}`}</h1>
        <div className="button_Budget_Table">
          <PrimaryButtonloading
            name="Download Excel"
            onClick={handleDownloadExcel}
            classNames="NewBudgetTable_Button"
            disabled={loading}
          />
          <PrimaryButtonloading
            name="Upload Excel"
            onClick={handleOpenDialog}
            classNames="NewBudgetTable_Button"
            disabled={loading}
          />
          <PrimaryButtonloading
            name={loading ? "Generating..." : "Generate Budget"}
            onClick={() => {
              setFetchOrGenerate("generate");
            }}
            classNames="NewBudgetTable_Button"
            disabled={loading}
          />
        </div>
      </div>

      <div className="newBudgetTable_wrapper">
        <div className="table_filters_grp">
          <FormControl
            variant="outlined"
            style={{minWidth: 200, marginRight: 16}}
            disabled={loading}
          >
            <InputLabel id="account-number-label">Account Number</InputLabel>
            <Select
              labelId="account-number-label"
              value={accountNumberFilter}
              onChange={(e) => setAccountNumberFilter(e.target.value)}
              label="Account Number"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {accountNumbers.map((account) => (
                <MenuItem
                  key={account}
                  value={account}
                >
                  {account}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <FormControl
            variant="outlined"
            style={{minWidth: 200, marginRight: 16}}
            disabled={loading}
          >
            <InputLabel id="book-type-label">Book Type</InputLabel>
            <Select
              labelId="book-type-label"
              value={accountNumberFilter}
              onChange={(e) => setBookTypes(e.target.value)}
              label="Book Type"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {(Array.isArray(BookTypes) ? BookTypes : []).map((BookType) => (
                <MenuItem
                  key={BookType}
                  value={BookType}
                >
                  {BookType}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={["month"]}
              label="Month"
              value={selectedMonth ? dayjs().month(selectedMonth - 1) : null}
              onChange={(newValue) => setSelectedMonth(newValue ? newValue.month() + 1 : null)}
              renderInput={(params) => <TextField {...params} />}
              disabled={loading}
            />
          </LocalizationProvider>

          <FormControl
            variant="outlined"
            style={{minWidth: 120, marginRight: 16}}
            disabled={loading}
          >
            <InputLabel id="year-label">Year</InputLabel>
            <Select
              labelId="year-label"
              value={selectedYear || ""}
              onChange={(e) => setSelectedYear(e.target.value)}
              label="Year"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {Array.from({length: 10}, (_, i) => new Date().getFullYear() - i).map((year) => (
                <MenuItem
                  key={year}
                  value={year}
                >
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <Table
          columns={columns}
          dataSource={processedBudgetItems}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: budgetData.totalItems,
            onChange: (page) => setCurrentPage(page),
          }}
          loading={loading}
        />
      </div>

      <Dialog
        open={open}
        onClose={handleCloseDialog}
      >
        <DialogContent
          style={{
            padding: "20px",
            textAlign: "center",
            width: "540px",
          }}
        >
          {!uploadProgress && (
            <Typography
              variant="h6"
              style={{
                marginBottom: "16px",
              }}
            >
              Upload Excel File
            </Typography>
          )}
          <div
            className="NewBudgetTable-drop_box"
            style={{
              border: "1px dashed #ccc",
              padding: "20px",
              borderRadius: "8px",
              backgroundColor: "#f9f9f9",
              marginBottom: "16px",
            }}
          >
            <header>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{marginBottom: "12px"}}
              >
                {file ? `Selected file: ${file.name}` : "No file selected"}
              </Typography>
            </header>
            <Typography
              variant="body2"
              style={{
                marginBottom: "12px",
              }}
            >
              Files Supported: XLSX
            </Typography>
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              disabled={loading}
              style={{
                marginBottom: "16px",
                backgroundColor: "#3f51b5",
                color: "#fff",
              }}
            >
              Select File
              <input
                type="file"
                onChange={handleFileChange}
                accept=".xlsx, .xls"
                style={{display: "none"}} // hide input visually
              />
            </Button>
            {uploadProgress > 0 ? (
              <div style={{marginTop: "16px"}}>
                <CircularProgress
                  variant="determinate"
                  value={uploadProgress}
                />
              </div>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions style={{justifyContent: "center", padding: "12px"}}>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            disabled={loading}
            style={{
              marginRight: "8px",
              backgroundColor: "#f1f1f1",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleUploadExcel}
            color="primary"
            variant="contained"
            disabled={loading || !file}
            style={{
              backgroundColor: "#3f51b5",
              color: "#fff",
            }}
          >
            {loading ? (
              <CircularProgress
                size={24}
                color="inherit"
              />
            ) : (
              "Upload"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewBudgetTable;
