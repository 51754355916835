import React, {useEffect, useRef, useState} from "react";
import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import "./AssetDiscovery.scss";
import {IOSSwitch, InputBox} from "../../components/reusableComponents/FormFields/FormFields";
import searchWhite from "../../assets/svg/searchWhite.svg";
import commercialImg from "../../assets/Image/assetDiscovery/commercial.png";
import clockImg from "../../assets/Image/assetDiscovery/clock.png";
import eyelImg from "../../assets/Image/assetDiscovery/eye.png";
import rewindImg from "../../assets/Image/assetDiscovery/rewind.png";
import DropDown from "../../components/reusableComponents/Dropdown/Dropdown";
import SFICOn from "../../assets/svg/SF.svg";
import {FormControlLabel, Input, Modal, Slider} from "@mui/material";
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import AiSearch, {AiSearch1, AiSearch2} from "./AiSearch";
import AssetInformation2 from "../DealsRoom/AssetInformation2";
import {useSelector} from "react-redux";
import axios from "axios";
import moment from "moment";
import {useDispatch} from "react-redux";
import {fetchCrownFunding, getAsset} from "../../utils/Axios";
import {setAssetId, setAssetName, setChatId, setUserAssetData} from "../../store/reducers/userDetailsSlice";
import LoaderOverlay from "../../components/reusableComponents/Loader/index";
import MiniMap from "./Map/MiniMap";
import {GetSyndicates} from "../../utils/Axios";
import {setCrowdfundingList, selectCrowdfunding} from "../../store/reducers/crowdfundingSlice";
import useAuthConfig from "../../utils/Config";
import Endpoint from "../../utils/Endpoint";
import Timeout from "../../components/Autologout/Timeout";
import {handleSearch} from "./search/SeacrhFunction";
import {PropertyCard, PropertyInfoTab, ContactPanel} from "./PropertyCard/PropertyCard";
import {Accectdata} from "./PropertyCard/Accectdata";
import {AssetDiscoverySearch} from "./PropertyCard/AssetDiscoverySearch";

const AssetDiscovery = () => {
  const [acquisitionActive, setAcquisitionActive] = useState(true);
  const [isAdvFilters, setIsAdvFilters] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAiSearch, setIsAiSearch] = useState(false);
  const selectedData = useSelector((state) => state.userInfo.userAssetData);
  const config = useAuthConfig();
  const dispatch = useDispatch();
  const {isAuthenticated, userInformation} = useSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    userInformation: state.auth.userInformation,
  }));

  let datacrowdfundingSlice = useSelector((state) => state.crowdfundingSlice.crowdfundingList);
  const {idToken} = useSelector((state) => state.auth);
  const [assetsList, setAssetsList] = useState([]);
  const [crownFundingList, setCrownFundingList] = useState();
  const [searchParam, setSearchParam] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [syndicateData, setSyndicateData] = useState([]);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  let [list, setList] = useState([]);
  Timeout(idToken);

  const handleClose = () => {
    setIsModalOpen(false);
  };
  // const handleViewCount = async () => {
  //   await axios.patch(`/api/assets/views/${selectedData?.assetId}`, null, config);
  // };

  const handleAiSearch = () => {
    setIsAiSearch(!isAiSearch);
    setIsAdvFilters(false);
  };

  // ---------------------------------FetchAssets Funding---------------------------//

  const fetchAssets = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(Endpoint.onMarketDispositions, config);
      setAssetsList(response.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAssets();
  }, [idToken, searchParam?.length === 0 && searchParam]);

  useEffect(() => {
    (async () => {
      await fetchCrownFunding(idToken).then((res) => {
        setCrownFundingList(res?.data);
      });
    })();
  }, [dispatch]);

  const handleIncrement = async (preferredProperty) => {
    try {
      const response = await axios.patch(Endpoint.increment_preference, {preferred_property: preferredProperty}, config);

      if (response.data.success) {
      }
    } catch (error) {}
  };

  useEffect(() => {
    setLoading(true);
    GetSyndicates(idToken)
      .then((res) => {
        setLoading(false);
        const {success, data} = res;
        if (!success) {
          if (data && data.message === "Token expired") {
            setTokenExpired(true);
          }
        } else {
          setSyndicateData(data || []);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [idToken]);

  // ---------------------------------Croud Funding---------------------------//

  const getCroudFunding = async () => {
    try {
      const response = await axios.get(Endpoint.crowdfunding, config);
      const newSyndicateData = response.data.data;
      dispatch(setCrowdfundingList(newSyndicateData));
    } catch (error) {}
  };

  useEffect(() => {
    getCroudFunding();
  }, [idToken]);

  const handleSelectCrowdfunding = (selectedItem) => {
    dispatch(selectCrowdfunding(selectedItem));
  };

  // Search data
  const handleSearchClick = async () => {
    try {
      const response = await axios.get(`${Endpoint.search_assets}?assetName=${searchTerm}`, config);

      if (response.data && response.data.length) {
        setList(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleSearchClick();
  }, []);

  // ----------------------------------------------------------------------------------------------------

  const handleViewCount = async () => {
    try {
      await axios.patch(`${Endpoint.views_Count}/${selectedData?.assetId}`, null, config);
    } catch (err) {}
  };
  return (
    <div className={"AssetDiscovery " + (!acquisitionActive && " Crowdfunding")}>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
      >
        <AssetInformation2 selectedData={selectedData} />
      </Modal>
      <div className="AssetDiscovery-T">
        <AssetDiscoverySearch
          acquisitionActive={acquisitionActive}
          setAcquisitionActive={setAcquisitionActive}
          isAdvFilters={isAdvFilters}
          setIsAdvFilters={setIsAdvFilters}
          isAiSearch={isAiSearch}
          setIsAiSearch={setIsAiSearch}
          variant={"large"}
          handleAiSearch={handleAiSearch}
          setSearchParam={setSearchParam}
          searchParam={searchParam}
          userInformation={userInformation}
          handleSearchClick={handleSearchClick}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          syndicateData={syndicateData}
          list={list}
          userInformationrole={userInformation?.roleTypes}
        />
      </div>
      {acquisitionActive && (
        <div className="AssetDiscovery-B">
          <div className="AssetDiscovery-B-header">
            <h3>Recommended</h3>
          </div>

          <div className="AssetDiscovery">
            {isLoading && <LoaderOverlay desc="Please Wait Untill Map Load Or Refresh Page" />}
            <div className="AssetDiscovery-propertiesCon">
              {list?.map((item, idx) => (
                <div
                  className="AssetDiscovery-propertiesITEM"
                  key={idx}
                  onClick={() => {
                    dispatch(setUserAssetData(item));
                  }}
                >
                  <div
                    className="AssetDiscovery-propertiesITEM-L"
                    style={{display: "flex", gap: "30px", height: "270px"}}
                  >
                    <img
                      src={item?.assetsImages[0]}
                      alt=""
                      className="bulding_img"
                      loading="lazy"
                    />
                    <div
                      className="AssetDiscovery-propertiesITEM-M"
                      onClick={() => {
                        handleViewCount(item.id);
                        handleIncrement(item?.assetType);
                        setIsModalOpen(true);
                      }}
                    >
                      <h1>{item?.assetName}</h1>
                      <h2>{item?.assetType}</h2>
                      <div className="fl">
                        <div className="sellChancesCon">
                          {item?.level || "HIGH LEVEL"}
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M8.25 12.75H9.75V8.25H8.25V12.75ZM9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15ZM8.25 6.75H9.75V5.25H8.25V6.75Z"
                              fill="#9EA0A5"
                            />
                          </svg>
                        </div>
                        {item?.sponsored || ("SPONSORED" && <div className="sponsoredBlock">Sponsored</div>)}
                      </div>
                      <div className="onSaleCon">
                        <svg
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="4"
                            cy="4"
                            r="4"
                            fill="#009639"
                          />
                        </svg>
                        {item?.status || "On sale"}
                      </div>
                      <div className="onSaleCon">{item?.status}</div>
                      <div className="propertiesDetailLine">
                        <div className="blockOne">
                          <h1>${item?.assetValue?.toLocaleString("en-US")}</h1>
                          <h2>
                            $
                            {item?.propertyDetails?.totalUnits?.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}{" "}
                            100 per Unit
                          </h2>
                        </div>
                        <div className="allBlocks">
                          <div className="blockTwo first">
                            <img
                              src={commercialImg}
                              alt=""
                            />
                            <h2>{item?.assetType}</h2>
                          </div>
                          <div className="blockTwo">
                            <h1>{item?.assetSquareFeet || 2000}</h1>
                            <h2>Area, SF</h2>
                          </div>
                          <div className="blockTwo">
                            <h1>{item?.unitDuplex || 1}</h1>
                            <h2>Units Duplex</h2>
                          </div>
                        </div>
                      </div>

                      <div className="fl">
                        {item?.sold && (
                          <span>
                            Sold: <p>{item.sold}</p>
                          </span>
                        )}
                        {item?.debt && (
                          <span>
                            Debt: <p>{item.debt}</p>
                          </span>
                        )}
                      </div>
                      <Link
                        to={`/asset-discovery/${item?.assetId}/dealsroom`}
                        style={{textDecoration: "none"}}
                      >
                        <h3
                          style={{display: "inline-block", cursor: "pointer"}}
                          onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
                          onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
                        >
                          {item?.assetName}
                          <a
                            href="#"
                            style={{textDecoration: "underline"}}
                          >
                            {" "}
                            More details.
                          </a>
                        </h3>
                      </Link>

                      <div className="fl">
                        <div className="viewCounter">
                          <img
                            src={clockImg}
                            alt="image"
                          />
                          {moment(item?.date).format("DD-MM-YYYY")}
                        </div>
                        <div className="viewCounter">
                          <img
                            src={rewindImg}
                            alt="image"
                          />
                          3 days ago
                        </div>
                        <div className="viewCounter">
                          <img
                            src={eyelImg}
                            alt="image"
                          />
                          {item?.views}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="AssetDiscovery-propertiesITEM-R">
                    <div className="AssetDiscovery-propertiesITEM-R-L">
                      <Accectdata
                        data={item}
                        assetIdList={item}
                      />
                    </div>
                    <div
                      className="AssetDiscovery-propertiesITEM-R-R"
                      // onClick={() => showInMapClicked(item?.lat_and_long)}
                    >
                      {/* <Link>
                        <img
                          src={mapImg}
                          className="map_image"
                          alt=""
                        />
                      </Link> */}

                      {Array.isArray(item?.lat_and_long) && item.lat_and_long.length > 0 && Array.isArray(item.lat_and_long[0]) && item.lat_and_long[0].length >= 2 && (
                        <MiniMap
                          key={idx}
                          lat={item.lat_and_long[0][0] || 0}
                          long={item.lat_and_long[0][1] || 0}
                          style={{width: "207px", height: "270px"}}
                          className="Main-map-small"
                          mapOptions={{zoom: 14, scrollWheelZoom: true}}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {!acquisitionActive && (
        <div className="AssetDiscovery-B">
          <div className="AssetDiscovery-crowdfundingCon">
            {datacrowdfundingSlice?.length > 0 &&
              datacrowdfundingSlice?.map((syndicateData, index) => (
                <PropertyCard
                  syndicateData={syndicateData}
                  dispatch={dispatch}
                  handleSelectCrowdfunding={handleSelectCrowdfunding}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AssetDiscovery;
