import React from "react";
import { Row2 } from "./Row2";

export const TeamTable = ({ handlePersonClick, data }) => {
  return (
    <div className="lender_table">
      <div className="documents_box">
        <div className="documents_box-B table">
          <div className="uploaded_docs">
            <div className="header">
              <div className="left">
                <p style={{ width: "250px" }}>Person</p>
                <p className="second">Department</p>
                <p>Assets</p>
                <p>Phone</p>
                <p>Status</p>
                <p>
                  Active
                  <br />
                  Deals
                </p>
                <p>
                  Closed
                  <br />
                  Deals
                </p>
                <p>
                  Active
                  <br />
                  Checklist
                </p>
              </div>
            </div>
            {data?.data?.map((personData, index) => (
              <Row2
                key={personData._id}  
                handlePersonClick={handlePersonClick}
                person={personData}   
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
