import React, {useEffect, useState} from "react";
import "./invoicing.scss";
import DropDown from "../../../components/reusableComponents/Dropdown/Dropdown";
import calendar from "../../../assets/svg/calender.svg";
import chart from "../../../assets/Image/invoice-graph.png";
import {InputBox} from "../../../components/reusableComponents/FormFields/FormFields";
import profileIcon from "../../../assets/svg/commentProfile.png";
import invoice from "../../../assets/svg/download.svg";
import dot from "../../../assets/svg/dot-gray.svg";
import {DownloadInvoicePDF, GetPaidPayments} from "../../../utils/Axios";
import {useSelector} from "react-redux";
import {CardSvg} from "./CardSvg";
import axios from "axios";
import {CircularProgress} from "@mui/material";
import PaymentMethod from "./PaymentMethod";
import {Row2} from "./Row2";

export const InvoiceTable = ({payments, idToken}) => (
  <div className="lender_table">
    <div className="documents_box">
      <div className="documents_box-T">
        <div className="left">
          <h2>Invoice History</h2>
        </div>
      </div>
      <div className="documents_box-B table">
        <div className="uploaded_docs">
          <div className="header">
            <div className="left">
              <p>Date</p>
              <p className="second">Asset</p>
              <p>Amount</p>
              <p>Payment Info</p>
              <p>Status</p>
              {/* <p>
                  <img
                    src={invoice}
                    alt="Download Icon"
                  />
                </p> */}
            </div>
          </div>
          {payments && payments.length > 0 ? (
            payments.map((payment, index) => (
              <Row2
                key={index}
                payment={payment}
                idToken={idToken}
              />
            ))
          ) : (
            <div className="No_payment">
              <p>No payment history available.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);
