import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";
import "./BulkDealsUpload.scss";
import { useSelector } from "react-redux";

const BulkDealsUploadPopup = ({ open, onClose }) => {
  const [excelFiles, setExcelFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("idle");
  const { userInformation } = useSelector((state) => state.auth);

  const handleFileChange = (event) => {
    setExcelFiles([...event.target.files]);
  };

  const handleSubmit = async () => {
    if (excelFiles.length === 0) {
      alert("Please upload at least one Excel file.");
      return;
    }

    const data = new FormData();
    for (const file of excelFiles) {
      data.append("excelfiles", file);
    }
    data.append("userId", userInformation?._id);

    setProgress(0);
    setUploadStatus("uploading");

    try {
      await axios.post("/api/Deals/uploadBulkExcel", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      });

      setUploadStatus("processing");
      setTimeout(() => {
        setUploadStatus("success");
        setExcelFiles([]);
        alert("Files uploaded and processed successfully.");
        onClose();
      }, 1000);
    } catch (error) {
      console.error("Error uploading files:", error);
      setUploadStatus("error");
      alert("Failed to upload files. Please try again.");
    }
  };

  const getProgressColor = () => {
    if (uploadStatus === "success") return "green";
    if (uploadStatus === "error") return "red";
    return "blue";
  };

  return (
    <Dialog open={open} onClose={onClose} className="BulkDealsUpload-dialog">
      <DialogTitle className="BulkDealsUpload-title">
        Bulk Upload Deals
      </DialogTitle>
      <DialogContent className="BulkDealsUpload-content">
        {uploadStatus === "uploading" || uploadStatus === "processing" ? (
          <div className="BulkDealsUpload-loader">
            <CircularProgress color="primary" />
            <span>
              {uploadStatus === "uploading"
                ? "Uploading files..."
                : "Processing files..."}
            </span>
          </div>
        ) : (
          <div className="BulkDealsUpload-file-input">
            <input
              accept=".xls,.xlsx"
              id="upload-excel-files"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
              multiple
            />
            <label htmlFor="upload-excel-files">
              <IconButton
                color="primary"
                component="span"
                className="BulkDealsUpload-upload-icon"
              >
                <AttachFileIcon />
              </IconButton>
            </label>
            {excelFiles.length > 0 && (
              <span className="BulkDealsUpload-file-name">
                {excelFiles.length} file(s) selected
              </span>
            )}
          </div>
        )}
        {uploadStatus === "uploading" && (
          <div className="BulkDealsUpload-progress-bar">
            <LinearProgress
              variant="determinate"
              value={progress}
              style={{ backgroundColor: "lightgray", color: getProgressColor() }}
              sx={{
                "& .MuiLinearProgress-bar": {
                  backgroundColor: getProgressColor(),
                },
              }}
            />
            <span>{progress}%</span>
          </div>
        )}
      </DialogContent>
      <DialogActions className="BulkDealsUpload-actions">
        <Button
          onClick={onClose}
          disabled={uploadStatus === "uploading" || uploadStatus === "processing"}
          className="BulkDealsUpload-cancel-button"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={uploadStatus === "uploading" || uploadStatus === "processing"}
          className="BulkDealsUpload-submit-button"
        >
          {uploadStatus === "idle" ? "Upload" : "Uploading..."}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkDealsUploadPopup;
