import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import moment from "moment";
import axios from "axios";
import profile from "../../../assets/acqusition/svg/profile.svg";
import mobile from "../../../assets/acqusition/svg/mobile.svg";
import mail from "../../../assets/acqusition/svg/mail.svg";
import linkIcon from "../../../assets/acqusition/svg/link.svg";
import redirect from "../../../assets/acqusition/svg/redirect.svg";
import blocher from "../../../assets/acqusition/svg/blocher.svg";
import calender from "../../../assets/acqusition/svg/calender.svg";
import {PrimaryButton} from "../../../components/reusableComponents/Buttons/Buttons";
import {selectProperty} from "../../../store/reducers/portfolioSlice";
import {returnMessages} from "../../../store/reducers/message";
import {returnErrors} from "../../../store/reducers/error";
import {useNavigate} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Endpoint from "../../../utils/Endpoint";
import useAuthConfig from "../../../utils/Config";

export function ContactPanel({requesttour, setRequestTour, setSelectedIndex, selectedData, id}) {
  const {idToken, userInformation} = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const config = useAuthConfig();

  const startDeal = async () => {
    setLoading(true);
    try {
      await axios.post(
        Endpoint.ContactPanelStartDeals,
        {
          assetIds: [selectedData?.assetId],
        },
        config,
      );
  
      dispatch(selectProperty(selectedData));
      dispatch(returnMessages("Deal started successfully!", 200));
      const encodedAssetId = btoa(selectedData?.assetId);
      window.location.href = `/dealsroom?data=${encodedAssetId}`;
    } catch (error) {
      dispatch(returnErrors("Failed to start deal. Please try again.", error.response?.status || 500));
    } finally {
      setLoading(false);
    }
  };
  
  

  return (
    <div style={{width:"100%"}}>
      <div className="flex profile-section">
        <img
          src={profile}
          alt="Profile"
        />
        <div>
          <span>Added by {selectedData?.addedBy}</span>
        </div>
      </div>

      {selectedData?.date && (
        <div className="flex calender-section">
          <img
            src={calender}
            alt="Calendar"
          />
          <div>
            <span>{moment(selectedData?.date).format("MMMM Do YYYY, h:mm:ss a")}</span>
          </div>
        </div>
      )}

      {selectedData?.contact?.length > 0 && (
        <div className="flex mob-section">
          <img
            src={mobile}
            alt="Mobile"
          />
          <div style={{display: "flex", flexDirection: "column", gap: "5px"}}>
            {selectedData?.contact?.map((number, index) => (
              <span key={index}>{number}</span>
            ))}
          </div>
        </div>
      )}

      {selectedData?.contactEmail && (
        <div className="flex contact-detail">
          <img
            src={mail}
            alt="Mail"
          />
          <span>{selectedData?.contactEmail}</span>
        </div>
      )}

      {selectedData?.contactWebsite && (
        <div className="flex contact-detail">
          <img
            src={linkIcon}
            alt="Link"
          />
          <span>{selectedData?.contactWebsite}</span>
          <img
            src={redirect}
            alt="Redirect"
          />
        </div>
      )}

      {selectedData?.contactBrochure && (
        <div className="flex contact-detail">
          <img
            src={blocher}
            alt="Brochure"
          />
          <span>{selectedData?.contactBrochure}</span>
          <img
            src={redirect}
            alt="Redirect"
          />
        </div>
      )}

      <div className="button_wrapper">
        <div className="request button_wrapper">
          <div className="buttons">
            <PrimaryButton
              name={
                loading ? (
                  <CircularProgress
                    size={24}
                    color="inherit"
                  />
                ) : (
                  "Start Deal"
                )
              }
              className="white_buttons"
              disabled={loading}
              onclick={(e) => {
                e.preventDefault();
                startDeal();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
